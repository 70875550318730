import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import { authState } from '../../../atoms';
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Tooltip,
  IconButton,
  Breadcrumbs,
  Link
} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import FullLoadingSpinner from '../../../components/FullLoadingSpinner'
import moment from 'moment';
import { getStockInFormAPI, submitStockInAPI } from '../../../apis';
import { routes } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  gridContainer: {
    border: '1px solid lightgrey',
    padding: '20px 30px',
    backgroundColor: 'white',
    marginBottom: 70,
    position: 'relative',
  },
  formGroup: {
    display: 'flex',
    '& .MuiTypography-subtitle1': {
      margin: '0 10px 0 0',
      padding: '5px 0 0 0',
      width: 165,
      textAlign: 'left',
      '&:last-child': {
        width: 'auto'
      }
    },
    '& .MuiFormControl-root': {
      width: 240
    }
  },
  rowTableContainer: {
    '& .MuiTableCell-head': {
      fontWeight: '600 !important',
      paddingTop: 4,
      paddingBottom: 4,
      border: 'none',
      fontSize: '0.9rem !important',
    },
    '& .MuiTableCell-root': {
      textAlign: 'center',
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: '1rem',
      fontWeight: 'normal'
    }
  },
  tableCheck: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  tableActions: {
    right: '40px',
    display: 'flex',
    alighItems: 'center',
    position: 'absolute',
    left: 0,
    bottom: '100%',
  },
  snackbar: {
    left: 250
  }
}));

const StockIn = () => {

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { stock_in_form_id } = useParams();
  const auth = useRecoilValue(authState);
  const history = useHistory();
  const recordTableHeaders = [
    {
      label: t('Stock Id'),
      key: 'row_num',
      width: i18n.language === 'zh' ? 45 : 65
    },
    {
      label: t('Name'),
      key: 'name',
      width: 'auto'
    },
    {
      label: t('Model'),
      key: 'model',
      width: '10%'
    },
    {
      label: t('Price'),
      key: 'price',
      width: '10%'
    },
    {
      label: t('Quantity'),
      key: 'quantity',
      width: '10%'
    },
    {
      label: t('Total Price'),
      key: 'total_price',
      width: '10%'
    },
    {
      label: t('Item Id'),
      key: 'item_id',
      width: 'auto'
    },
    {
      label: t('Notes'),
      key: 'notes',
      width: '10%'
    }
  ]

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    stock_form_id: '',
    contract_name: '',
    contract_id: '',
    contract_company: '',
    row_data: [],
    leader_name: '',
    leader_date: null,
    admin_name: '',
    admin_date: null
  })

  let componentMounted = true;

  useEffect(() => {
    return () => { componentMounted = false; }
  }, []);

  useEffect(() => {
    if (!auth) return;
    if (stock_in_form_id) {
      setLoading(true);
      getStockInFormAPI(stock_in_form_id).then(res => {
        if (componentMounted) {
          setLoading(false);
          const returnData = res.data;
          setFormData({
            stock_form_id: returnData.stock_form_id,
            submit: returnData.submit,
            contract_name: returnData.data.contract_name,
            contract_id: returnData.data.contract_id,
            contract_company: returnData.data.contract_company,
            row_data: returnData.data.row_data,
            leader_name: returnData.data.leader_name,
            leader_date: returnData.data.leader_date,
            admin_name: returnData.data.admin_name,
            admin_date: returnData.data.admin_date,
          })
        }
      }).catch(err => {
        if (componentMounted) {
          setLoading(false);
          setFormData({
            ...formData,
            stock_form_id: stock_in_form_id
          })
        }
      })
    }
  }, [auth])


  const formatFormData = () => {
    return {
      owner_id: auth.id,
      stock_form_id: formData.stock_form_id,
      data: {
        contract_name: formData.contract_name || null,
        contract_id: formData.contract_id || null,
        contract_company: formData.contract_company || null,
        row_data: [...formData.row_data],
        leader_name: formData.leader_name,
        leader_date: formData.leader_date ? moment(formData.leader_date).format('YYYY-MM-DD') : null,
        admin_name: formData.admin_name || null,
        admin_date: formData.admin_date ? moment(formData.admin_date).format('YYYY-MM-DD') : null
      }
    }
  }

  const exportCSV = () => {
    let recordBody = '';
    formData.row_data.forEach(item => {
      recordBody += `<tr>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.row_num}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 180px">${item.item_name}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.item_model}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.price}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.quantity}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.total_price}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 180x">${item.item_id}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.notes}</td>
      </tr>`
    });

    const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
    const worksheet = 'Sheet1'
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:x="urn:schemas-microsoft-com:office:excel"
    xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
    <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
    <x:Name>${worksheet}</x:Name>
    <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
    </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
    </head><body>
    <body link="#0563C1" vlink="#954F72">
      <table style="font-family: SimSun">
        <thead>
          <tr>
            <th colspan="8" style="font-size: 18pt; height: 25pt; text-align: center;  vertical-align: middle;">
              ${t('Godown Entry')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="8" style="font-size: 14pt;  text-align: right; height: 18pt; vertical-align: middle;">
              ${t('FormID')}:${formData.stock_form_id}
            </td>
          </tr>
          <tr>
            <td colspan="2" style="height: 30pt; font-size: 14pt; vertical-align: middle; border-right: none;">
              ${t('Contract Name')}:
            </td>
            <td colspan="3" style="height: 30pt; font-size: 14pt; vertical-align: middle; border-left: none; border-right: none;">
              ${formData.contract_name}
            </td>
            <td colspan="3" style="border-left: none"></td>
          </tr>
          <tr>
            <td colspan="2" style="height: 30pt; font-size: 14pt; vertical-align: middle; border-right: none;">
              ${t('Contract ID')}:
            </td>
            <td colspan="3" style="height: 30pt; font-size: 14pt; vertical-align: middle; border-right: none; border-left: none;">
              ${formData.contract_id}
            </td>
            <td colspan="3" style="border-left: none;"></td>
          </tr>
          <tr>
            <td colspan="2" style="height: 30pt; font-size: 14pt; vertical-align: middle; border-right: none;">
              ${t('Contract Company')}:
            </td>
            <td colspan="3" style="height: 30pt; font-size: 14pt; vertical-align: middle; border-right: none; border-left: none;">
              ${formData.contract_company}
            </td>
            <td colspan="3" style="border-left: none"></td>
          </tr>
          <tr>
            <td width="30pt" style="text-align: center; font-size: 14pt;  height: 20pt; border: 0.5pt solid black;">${t('Stock Id')}</td>
            <td style="text-align: center; font-size: 14pt;  height: 20pt; border: 0.5pt solid black;">${t('Name')}</td>
            <td style="text-align: center; font-size: 14pt;  height: 20pt; border: 0.5pt solid black;">${t('Model')}</td>
            <td style="text-align: center; font-size: 14pt;  height: 20pt; border: 0.5pt solid black;">${t('Price')}</td>
            <td style="text-align: center; font-size: 14pt;  height: 20pt; border: 0.5pt solid black;">${t('Quantity')}</td>
            <td style="text-align: center; font-size: 14pt;  height: 20pt; border: 0.5pt solid black;">${t('Total Price')}</td>
            <td style="text-align: center; font-size: 14pt;  height: 20pt; border: 0.5pt solid black;">${t('Item Id')}</td>
            <td width="100pt" style="text-align: center; font-size: 14pt;  height: 20pt; border: 0.5pt solid black;">${t('Notes')}</td>
          </tr>
          ${recordBody}
          <tr><td colspan="8"></td></tr>
          <tr>
            <td style="font-size: 14pt; vertical-align: middle;">${t('Leader Name')}</td>
            <td style="font-size: 14pt; vertical-align: middle;">${formData.leader_name}</td>
            <td colspan="4"></td>
            <td style="font-size: 14pt; vertical-align: middle;">${t('Admin Name')}</td>
            <td style="font-size: 14pt; vertical-align: middle;">${formData.admin_name}</td>
          </tr>
          <tr><td colspan="8"></td></tr>
          <tr>
            <td style="font-size: 14pt; vertical-align: middle;">${t('Date')}</td>
            <td style="font-size: 14pt; vertical-align: middle;">${moment(formData.leader_date).format('YYYY-MM-DD')}</td>
            <td colspan="4"></td>
            <td style="font-size: 14pt; vertical-align: middle;">${t('Date')}</td>
            <td style="font-size: 14pt; vertical-align: middle;">${moment(formData.admin_date).format('YYYY-MM-DD')}</td>
          </tr>
        </tbody>
      </table>
    </body></html>
    `;

    const link = document.createElement("a");
    link.href = uri + base64(template);
    link.target = "_blank";
    link.download = `${t('Godown Entry')}_${t('FormID')}${formData.stock_form_id}.xls`;
    link.click();
  }

  const submitStockIn = () => {
    setLoading(true);
    submitStockInAPI(stock_in_form_id).then(res => {
      setLoading(false);
      if (res.data.status === 'success') {
        setFormData({
          ...formData,
          submit: true
        })
        Swal.fire({
          title: t('Success'),
          text: t('StockIn has been submitted'),
          icon: 'success',
          confirmButtonText: t('Ok')
        })
      } else {
        Swal.fire({
          title: `${t('Error')}!`,
          text: t('StockIn submit has been failed'),
          icon: 'error',
          confirmButtonText: t('Ok')
        })
      }
    }).catch(err => {
      setLoading(false);
      Swal.fire({
        title: `${t('Error')}!`,
        text: t('StockIn submit has been failed'),
        icon: 'error',
        confirmButtonText: t('Ok')
      })
    })
  }

  return (
    <Box className={clsx(classes.root, "main-page-content")}>
      <Grid container>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Breadcrumbs className="darin-breadcrumbs">
            <Link onClick={() => { history.push(routes.STOCKIN_SHEETS) }} color="inherit">{t('StockIn Sheets')}</Link>
            <Typography>{t('FormID')}: {formData.stock_form_id}</Typography>
          </Breadcrumbs>

          {formData.submit && <span style={{ color: 'grey', fontSize: '0.9rem' }}>{t('Has been submitted')}</span>}
          {!formData.submit && <>
            <Tooltip title={t('Edit')}>
              <IconButton
                onClick={() => {
                  history.push(`/inventory/stockin/edit/${stock_in_form_id}`)
                }}
                disabled={formData.submit}
              >
                <CreateOutlinedIcon color={formData.submit ? '' : 'primary'} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('submit')}>
              <IconButton
                onClick={submitStockIn}
                disabled={formData.submit}
              >
                <SendOutlinedIcon color={formData.submit ? '' : 'primary'} />
              </IconButton>
            </Tooltip>
          </>
          }
          <Tooltip title={t('download')}>
            <IconButton onClick={exportCSV}>
              <GetAppOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">{t('Godown Entry')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ textAlign: 'right', lineHeight: 1, marginBottom: '1rem' }}>
            {t('FormID')}: &nbsp;{formData.stock_form_id}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className='darin-panel-one' spacing={1}>
        <Grid item xs={12}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1" >{t('Contract Name')}:</Typography>
            <Typography variant="subtitle1" >{formData.contract_name}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1">{t('Contract ID')}:</Typography>
            <Typography variant="subtitle1" >{formData.contract_id}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1">{t('Contract Company')}:</Typography>
            <Typography variant="subtitle1" >{formData.contract_company}</Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container className='darin-panel-one' spacing={1}>
        <Grid item xs={12}>
          <TableContainer className={classes.rowTableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  {recordTableHeaders.map((item) => {
                    return <TableCell
                      key={item.key}
                      style={{ width: item.width }}
                    >
                      {item.label}
                    </TableCell>
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.row_data.length === 0 ? <TableRow>
                  <TableCell colSpan={8}><span style={{ color: 'grey' }}>{t("There's no record")}</span></TableCell>
                </TableRow> : <>
                  {formData.row_data.map((itemRow, idx) => {
                    return <TableRow key={idx}>
                      <TableCell>{itemRow.row_num}</TableCell>
                      <TableCell>{itemRow.item_name}</TableCell>
                      <TableCell>{itemRow.item_model}</TableCell>
                      <TableCell>{itemRow.price}</TableCell>
                      <TableCell>{itemRow.quantity}</TableCell>
                      <TableCell>{itemRow.total_price}</TableCell>
                      <TableCell>{itemRow.item_id}</TableCell>
                      <TableCell>{itemRow.notes}</TableCell>
                    </TableRow>
                  })}</>}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container className='darin-panel-one' spacing={1}>
        <Grid item xs={6} sm={4}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1" style={{ width: 125 }}>{t('Leader Name')}:</Typography>
            <Typography variant="subtitle1">{formData.leader_name}</Typography>
          </Box>
        </Grid>
        <Grid item sm={4}></Grid>
        <Grid item xs={6} sm={4}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1" style={{ width: 125 }}>{t('Admin Name')}:</Typography>
            <Typography variant="subtitle1">{formData.admin_name}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item sm={4} xs={6}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1" style={{ width: 125 }}>{t('Date')}:</Typography>
            <Typography variant="subtitle1">{formData.leader_date}</Typography>
          </Box>
        </Grid>
        <Grid item sm={4}></Grid>
        <Grid item sm={4} xs={6}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1" style={{ width: 125 }}>{t('Date')}:</Typography>
            <Typography variant="subtitle1">{formData.admin_date}</Typography>
          </Box>
        </Grid>
      </Grid>
      {loading && <FullLoadingSpinner />}
    </Box >)
}

export default StockIn;
import React from 'react';
import {
  makeStyles,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingLeft: 15,
  },
  dotFlashing: {
    position: 'relative',
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    animation: '$dotFlashing 1s infinite linear alternate',
    animationDelay: '.5s',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: -9,
      width: 6,
      height: 6,
      borderRadius: 3,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      animation: '$dotFlashing 1s infinite alternate',
      animationDelay: '0s'
    },
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 9,
      width: 6,
      height: 6,
      borderRadius: 3,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      animation: '$dotFlashing 1s infinite alternate',
      animationDelay: '1s',
    }
  },
  '@keyframes dotFlashing': {
    '0%': {
      backgroundColor: theme.palette.primary.main,
    },
    '50%, 100%': {
      backgroundColor: '#ebe6ff',
    }
  },
}));

const TextLoadingIndicator = () => {

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.dotFlashing}></Box>
    </Box>
  )
}

export default TextLoadingIndicator;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Darin': 'Darin',
      'Accounts': 'Accounts',
      'Alerts': 'Alerts',
      'Monitor': 'Monitor',
      'Language': 'Language',
      'Manage Accounts': 'Manage Accounts',
      'No.': 'No.',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      'User Name': 'User Name',
      'Group': 'Group',
      'Status': 'Status',
      'Active': 'Active',
      'Activated': 'Activated',
      'Deactived': 'Deactived',
      "Please confirm": 'Please confirm',
      'Are you going to delete it?': 'Are you going to delete it?',
      'Create Account': 'Create Account',
      'Update Account': 'Update Account',
      'Create': 'Create',
      'Update': 'Update',
      'admin': 'Admin',
      'employee': 'Employee',
      'leader': 'Leader',
      'Cancel': 'Cancel',
      'Yes': 'Yes',
      'No': 'No',
      'My Account': 'My Account',
      'Sign out': 'Sign Out',
      'Check previous history...': 'Check previous history...',
      'There is no notification': 'There is no notification',
      'Notification': 'Notification',
      'You have a new application pending': 'You have a new application pending',
      'Inventory': 'Inventory',
      'StockIn': 'StockIn',
      'StockIn Sheets': 'StockIn Sheets',
      'Check Inventory': 'Check Inventory',
      'Godown Entry': 'Godown Entry',
      'Contract Name': 'Contract Name',
      'Contract ID': 'Contract ID',
      'FormID': 'FormID',
      'Contract Company': 'Contract Company',
      'Leader Name': 'Leader Name',
      'Admin Name': 'Admin Name',
      'Date': 'Date',
      'Name': 'Name',
      'Model': 'Model',
      'Price': 'Price',
      'Quantity': 'Quantity',
      'Total Price': 'Total Price',
      'Item Id': 'Item Id',
      'Notes': 'Notes',
      'Applications': 'Applications',
      'Employee Name': 'Employee Name',
      'Item Name': 'Item Name',
      'Item Names': 'Item Names',
      'CreatedOn': 'CreatedOn',
      'Hello, I am your assistant Xiaoyin, what can I do for you?': 'Hello, I am your assistant Xiaoyin, what can I do for you?',
      "There's no record": "There's no record",
      'Message Id': 'Message Id',
      'Manager Id': 'Manager Id',
      'State': 'State',
      'Notify': 'Notify',
      'No Notification': 'No Notification',
      'Currently there is no notification~': 'Currently there is no notification~',
      'just now': 'just now',
      'min': 'min',
      'mins': 'mins',
      'h': 'h',
      'day': 'day',
      'days': 'days',
      'week': 'week',
      'weeks': 'weeks',
      'month': 'month',
      'months': 'months',
      'ago': ' ago',
      'one day ago': 'one day ago',
      'Stock FormID': 'Stock FormID',
      'Supplier Name': 'Supplier Name',
      'Verification Date': 'Verification Date',
      'Verification Sheet': 'Verification Sheet',
      'Verification Sheets': 'Verification Sheets',
      'form_id': 'Form ID',
      'contract name and id': 'contract name and id',
      'contract name': 'contract name',
      'contract id': 'contract id',
      'supplier name': 'supplier name',
      'Order id': 'Order id',
      'on-site contact/phone': 'on-site contact/phone',
      'supplier contact/phone': 'supplier contact/phone',
      'contract date': 'contract date',
      'expected delivery date': 'expected delivery date',
      'num': 'num',
      'name': 'name',
      'item_model': 'item_model',
      'quantity': 'quantity',
      'unit': 'unit',
      'item_id': 'item_id',
      'Verification Comments': 'Verification Comments',
      'project': 'project',
      'result': 'result',
      'notes': 'notes',
      'quantity verified': 'quantity verified',
      'yes': 'yes',
      'no': 'no',
      'document verified': 'document verified',
      'appearance verified': 'appearance verified',
      'performance verified': 'performance verified',
      'on time': 'on time',
      'representative present': 'representative present',
      'check verified': 'check verified',
      '(Please clarify if no performance tests have been conducted)': '(Please clarify if no performance tests have been conducted)',
      'accepter name': 'accepter name',
      'accepter': 'accepter',
      'acceptance time': 'acceptance time',
      'The form has been automatically saved': 'The form has been automatically saved',
      'Old password': 'Old password',
      'Old password required': 'Old password required',
      'New password': 'New password',
      'New password required': 'New password required',
      'has submitted applications regarding items': ' has submitted  applications regarding items ',
      'Notification Table Name': 'Name',
      'Verify': 'Verify',
      'None': 'None',
      'My Account Page': 'My Account Page',
      'Phone': 'Phone',
      'Email': 'Email',
      'Personal Info': 'Personal Info',
      'Authentication': 'Authentication',
      'Leader Date': 'Leader Date',
      'Employee Date': 'Employee Date',
      'Your account has been updated': 'Your account has been updated',
      'Account update has been failed': 'Account update has been failed',
      'Your password has been changed': 'Your password has been changed',
      "Change password has been failed": "Change password has been failed",
      'Success': 'Success',
      'Ok': 'Ok',
      'Save': 'Save',
      'Change Password': 'Change Password',
      'Error': 'Error',
      'Has been submitted': 'Has been submitted',
      'Account has been created': 'Account has been created',
      'Create account has been failed': 'Create account has been failed',
      'Account has been updated': 'Account has been updated',
      'Update account has been failed': 'Update account has been failed',
      'Selected accounts has been deleted': 'Selected accounts has been deleted',
      'Delete accounts has been failed': 'Delete accounts has been failed',
      'Verification FormID': 'Verification FormID',
      'Submission': 'Submission',
      'Draft': 'Draft',
      'StockIn has been submitted': 'StockIn has been submitted',
      'StockIn submit has been failed': 'StockIn submit has been failed',
      'Edit': 'Edit',
      'Do you want to save the changes?': 'Do you want to save the changes?',
      'Application FormId': 'FormID',
      'Rows per page': 'Rows per page',
      'Application': 'Application',
      'Item Model': 'Model',
      'Item ID': 'ID',
      'Count': 'Count',
      'Net Price': 'Net Price',
      'net unit price': 'Net Unit Price',
      'Wen': '元',
      'usage': 'Usage',
      "Instructions: please clarify the usage, such as XX# fan": `Instructions: please clarify the usage, such as XX# fan`,
      'Application Tab': 'Applications',
      'Application Detail Title': 'Application',
      'Borrow': 'Borrow',
      'New Borrow Sheet': 'Borrow',
      'Borrow Sheet': 'Borrow Sheet',
      'Borrow Sheets': 'Borrow Sheets',
      'Borrow FormID': 'Borrow FormID',
      'Borrow Time': 'Borrow Time',
      'Inventory Time': 'Inventory Time',
      'Claim Sheets': 'Claim Sheets',
      'inventory date': 'Inventory Date',
      'quantity available': 'Quantity Available',
      'storage location': 'Storage Location',
      'consumption quantity': 'consumption quantity',
      'consumption amount': 'consumption amount',
      'out time': 'out time',
      'app num': 'app num',
      'claim item model': 'Item Model',
      'total amount': 'Total Amount',
      'accountant name': 'Accountant Name',
      'time': 'Time',
      'current total price': 'current total price',
      '排': '排',
      '列': '列',
      '层': '层',
      'null': '无',
      'Inventory Sheet has been submitted': 'Inventory Sheet has been submitted',
      'Inventory Sheet submit has been failed': 'Inventory Sheet submit has been failed',
      'chatbot': 'ChatBot',
      "Let's Get You Started": "Let's Get You Started",
      'username': 'Username',
      'password': 'Password',
      'login': 'Login',
      'submit': 'Submit',
      'download': 'Download',
      'home': 'Home',
      'Add Record': 'Add Record',
      'Edit Record': 'Edit Record',
      'title-text': 'Title',
      'create account': 'Create Account',
      'update account': 'Update Account',
      'unofficial': 'Unofficial',
      'enter your message': 'Enter your message',
      'unit price': 'Unit Price',
      'borrow quantity': 'Borrow Quantity',
      'location': 'Location',
      'borrow company': 'Borrow Company',
      'borrow name': 'Borrow Name',
      'working id': 'Working ID',
      'employee signature': 'Employee Signature',
      'admin signature': 'Admin Signature',
      'borrow company signature': 'Borrow Company Signature',
      'leader signature': 'Leader Signature',
    }
  },
  zh: {
    translation: {
      'Darin': '大音',
      'Accounts': '账户',
      'Alerts': '警报',
      'Monitor': '监听器',
      'Language': '语言',
      'Manage Accounts': '管理帐户',
      'No.': '序号',
      'First Name': '名',
      'Last Name': '姓',
      'User Name': '用户名',
      'Group': '组别',
      'Status': '状态',
      'Active': '可用',
      'Activated': '有效',
      'Deactivated': '不可用',
      "Please confirm": '请确认',
      'Are you going to delete it?': '您是否确认删除？',
      'Create Account': '创建帐户',
      'Update Account': '更新帐户',
      'Create': '创建',
      'Update': '更新',
      'admin': '管理员',
      'employee': '员工',
      'leader': '领导',
      'Cancel': '取消',
      'Yes': '是',
      'No': '否',
      'My Account': '我的帐户',
      'Sign out': '切换用户',
      'Check previous history...': '查看更多',
      'There is no notification': '当前没有通知',
      'Notification': '通知',
      'You have a new application pending': '待审批]您有一个新的申请待审批',
      'Inventory': '库存',
      'StockIn': '新入库',
      'StockIn Sheets': '库存单',
      'Check Inventory': '现库存',
      'Godown Entry': '入库单',
      'Contract Name': '合同名称',
      'Contract ID': '合同编号',
      'FormID': '表单ID',
      'Contract Company': '合同单位',
      'Leader Name': '负责人',
      'Admin Name': '库管员',
      'Date': '日期',
      'Name': '名称',
      'Model': '型号',
      'Price': '净价单',
      'Quantity': '数量',
      'Total Price': '总价',
      'Item Id': '出厂编号',
      'Notes': '备注',
      'Applications': '出库单',
      'Employee Name': '领用人',
      'Item Name': '物资名称',
      'Item Names': '物品名称',
      'CreatedOn': '创建于',
      'Stock Id': '序号',
      "Hello, I am your assistant Xiaoyin, what can I do for you?": "您好，我是您的助手小音，请问有什么我可以帮您呢？",
      'Items Names': '申请物资',
      'Rows per page': '每页行数',
      'Inventory Sheets': '历史台账',
      "There's no record": '还没有记录哦，快来添加一条吧',
      'Chinese': '中文',
      'Verification': '验收',
      'Form ID': '表格ID',
      'Items': '申请物资',
      'Showed On': '提醒于',
      'State': '状态',
      'No Notification': '暂无通知',
      'Currently there is no notification~': '当前还没有任何通知哦~',
      'just now': '刚刚',
      'min': '分钟',
      'mins': '分钟',
      'h': '小时',
      'day': '天',
      'days': '天',
      'week': '周',
      'weeks': '周',
      'month': '月',
      'months': '月',
      'ago': '前',
      'one day ago': '一天前',
      'Stock FormID': '入库单编号',
      'Supplier Name': '合同单位',
      'Verification Date': '入库日期',
      'Verification Sheet': '验收单',
      'Verification Sheets': '验收单',
      'form_id': '编号',
      'contract name and id': '合同名称及编号',
      'contract name': '合同名称',
      'contract id': '合同编号',
      'supplier name': '供货厂家名称',
      'Order id': '批次',
      'on-site contact/phone': '现场联系人/电话',
      'supplier contact/phone': '厂家联系人/电话',
      'contract date': '合同签署日期',
      'expected delivery date': '预计到货日期',
      'num': '序号',
      'name': '名称',
      'item_model': '型号或规格',
      'quantity': '数量',
      'unit': '单位',
      'item_id': '出厂编号',
      'Verification Comments': '验收意见',
      'project': '项目',
      'result': '情况',
      'notes': '备注',
      'quantity verified': '主件及附件数量是否属实',
      'yes': '是',
      'no': '否',
      'document verified': '资料是否齐全',
      'appearance verified': '外观是否完好',
      'performance verified': '性能实验是否合格',
      'on time': '是否按时到达',
      'representative present': '厂家代表是否跟随到场',
      'check verified': '开箱检验是否完好',
      '(Please clarify if no performance tests have been conducted)': '（如未能进行性能试验，须明确写明）',
      'accepter name': '验收负责人',
      'accepter': '验收人',
      'acceptance time': '验收时间',
      'The form has been automatically saved': '表格已自动保存',
      'Old password': '旧密码',
      'Old password required': '请输入旧密码',
      'New password': '新密码',
      'New password required': '请输入新密码',
      'has submitted applications regarding items': '提交了新申请:',
      'Notification Table Name': '领用人',
      'Verify': '新验收',
      'None': '无',
      'My Account Page': '我的账号',
      'Phone': '手机',
      'Email': '邮箱',
      'Personal Info': '个人信息',
      'Authentication': '密码安全',
      'Leader Date': '日期',
      'Employee Date': '日期',
      'Your account has been updated': '您的账号信息已被更新',
      'Account update has been failed': '账户更新失败',
      'Your password has been changed': '您的密码已更新',
      "Change password has been failed": "更改密码失败",
      'Success': '成功',
      'Ok': '关闭',
      'Save': '保存',
      'Change Password': '更新密码',
      'Error': '出错了',
      'Has been submitted': '已提交',
      'Account has been created': '已创建账号',
      'Create account has been failed': '创建账号失败',
      'Account has been updated': '已更新账号信息',
      'Update account has been failed': '更新账号失败',
      'Selected accounts has been deleted': '已删除所选账号',
      'Delete accounts has been failed': '删除账号失败',
      'Verification FormID': '验收单编号',
      'Submission': '提交',
      'Draft': '草稿',
      'StockIn has been submitted': '入库单已成功提交',
      'StockIn submit has been failed': '入库单提交失败',
      'Edit': '编辑',
      'Do you want to save the changes?': '您是否想保存当前表格？',
      'Application FormId': '表格编号',
      'Application': '出库',
      'Item Model': '型号',
      'Item ID': '出厂编号',
      'Count': '数量',
      'Net Price': '净单价',
      'net unit price': '净单价(元)',
      'Wen': '元',
      'usage': '用途',
      "Instructions: please clarify the usage, such as XX# fan": `填写说明：出库物资应明确用途，如XX#风机`,
      'Application Tab': '出库',
      'Application Detail Title': '出库单',
      'Borrow': '借用',
      'New Borrow Sheet': '新借用',
      'Borrow Sheet': '风光电站备件及耗材借用单',
      'Borrow Sheets': '借用单',
      'Borrow FormID': '表格编号',
      'Borrow Time': '借用时间',
      'Inventory Time': '入库时间',
      'Claim Sheets': '出库统计表',
      'contract total price': '合同总价(元)',
      'inventory date': '入库时间',
      'quantity available': '剩余库存',
      'storage location': '存放地点',
      'consumption quantity': '出库数量',
      'consumption amount': '出库金额(元)',
      'out time': '出库时间',
      'app num': '出库单编号',
      'claim item model': '型号',
      'total amount': '合计金额(元)',
      'accountant name': '会计',
      'time': '时间',
      'current total price': '合同总价(元)',
      '排': '排',
      '列': '列',
      '层': '层',
      'null': '无',
      'Inventory Sheet has been submitted': '台账已提交成功',
      'Inventory Sheet submit has been failed': '台账提交失败',
      'chatbot': '智能助手',
      "Let's Get You Started": "茂明风电智能仓储系统",
      'username': '用户名',
      'password': '密码',
      'login': '登陆',
      'submit': '提交',
      'download': '下载',
      'home': '监控台',
      'Add Record': '添加记录',
      'Edit Record': '编辑记录',
      'title-text': '名称',
      'create account': '创建账号',
      'update account': '更新账号',
      'unofficial': '非正式员工',
      'enter your message': '请输入',
      'unit price': '单价（元）',
      'borrow quantity': '借用数量',
      'location': '备件所在库房',
      'borrow company': '借用单位',
      'borrow name': '借用人签字',
      'working id': '工作票号',
      'employee signature': '领用人 签字',
      'admin signature': '库管员 签字',
      'borrow company signature': '借用单位负责人 签字',
      'leader signature': '风光电站场站长 签字',
    }
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'zh',
  fallbackLng: 'zh',
  interpolation: {
    escapeValue: false
  }
});

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authState } from '../../../atoms';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import TableFilterDropdown from '../../../components/TableFilterDropdown';
import FullLoadingSpinner from '../../../components/FullLoadingSpinner';
import { showTotalInventoryAPI, submitInventorySheetAPI } from '../../../apis';
import { routes } from '../../../constants'
import { getColumnValue, getCurChinaTime } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .page-title-wrapper': {
      marginBottom: 0,
    },
    '& .inventory-table-wrapper': {
      // marginTop: "1rem",
      padding: '16px 20px',
      backgroundColor: 'white',
      '& .darin-table-container': {
        padding: 0
      }
    }
  },
  filterWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& .filter-item': {
      margin: '0.5rem 1rem 0.5rem 0',
      minWidth: 200,
      '& .MuiInputLabel-outlined': {
        textTransform: 'capitalize'
      }
    }
  }
}));

const CheckInventory = () => {

  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const auth = useRecoilValue(authState);
  const [loading, setLoading] = useState(true);
  const [inventoryList, setInventoryList] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterColumn, setFilterColumn] = useState([
    {
      id: 'contract_name',
      name: 'contract name',
      checked: true,
      value: ''
    },
    {
      id: 'contract_id',
      name: 'contract id',
      checked: true,
      value: ''
    },
    {
      id: 'item_name',
      name: 'Item Name',
      checked: true,
      value: ''
    },
    {
      id: 'item_model',
      name: 'Item Model',
      checked: true,
      value: ''
    },
    {
      id: 'item_id',
      name: 'Item Id',
      checked: true,
      value: ''
    },
    {
      id: 'unit',
      name: 'unit',
      checked: true,
      value: ''
    },
    {
      id: 'quantity',
      name: 'quantity',
      checked: true,
      value: ''
    },
    {
      id: 'net_unit_price',
      name: 'net unit price',
      checked: true,
      value: ''
    },
    {

      id: 'inventory_time',
      name: 'Inventory Time',
      checked: true,
      value: null
    }
  ])

  let componentMounted = true;
  useEffect(() => {
    if (auth && auth.id) {
      showTotalInventoryAPI().then(res => {
        if (componentMounted) {
          setInventoryList([...res.data.data]);
          setSubmitted(res.data.submit)
          setLoading(false);
        }
      }).catch(err => {
        if (componentMounted) {
          setInventoryList([]);
          setLoading(false);
        }
      })
    }
    return () => { componentMounted = false; }
  }, [auth])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [orderBy, setOrderBy] = useState({
    name: '',
    value: 'asc'
  })

  const getStoredData = () => {
    if (!orderBy.name) return inventoryList;
    if (orderBy.name === 'inventory_time' && orderBy.value) {
      return inventoryList.sort((a, b) => {
        const orderValue = orderBy.value === 'desc' ? -1 : 1;
        if (!a.inventory_time) return -1 * orderValue;
        if (!b.inventory_time) return 1 * orderValue;

        const aDateValue = new Date(a.inventory_time).valueOf();
        const bDateValue = new Date(b.inventory_time).valueOf();

        if (bDateValue > aDateValue) return -1 * orderValue;
        else if (bDateValue < aDateValue) return 1 * orderValue;
        else return 0;
      })
    } else if (orderBy.name && orderBy.value) {
      return inventoryList.sort((a, b) => {
        const orderValue = orderBy.value === 'desc' ? -1 : 1;
        if (b[orderBy.name] > a[orderBy.name]) return -1 * orderValue;
        if (b[orderBy.name] < a[orderBy.name]) return 1 * orderValue;
        else return 0;
      })
    }
    return inventoryList;
  }

  const submitCheckInInventory = () => {
    setLoading(true);
    const curChinaTime = getCurChinaTime().subtract(1, 'months');
    submitInventorySheetAPI(curChinaTime.format('YYYYMM')).then(res => {
      setLoading(false);
      if (res.data.status === 'success') {
        Swal.fire({
          title: t('Success'),
          text: t('Inventory Sheet has been submitted'),
          icon: 'success',
          confirmButtonText: t('Ok')
        })
      } else {
        Swal.fire({
          title: `${t('Error')}!`,
          text: t('Inventory Sheet submit has been failed'),
          icon: 'error',
          confirmButtonText: t('Ok')
        })
      }
      setSubmitted(true);
    }).catch(err => {
      setLoading(false);
      Swal.fire({
        title: `${t('Error')}!`,
        text: t('Inventory Sheet submit has been failed'),
        icon: 'error',
        confirmButtonText: t('Ok')
      })
    })
  }


  const exportCSV = () => {
    let recordBody = '';
    inventoryList.forEach((item, idx) => {
      recordBody += `<tr>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 70px">${idx + 1}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'contract_name')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'contract_id')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'item_name')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 110px">${getColumnValue(item, 'item_model')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'item_id')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 70px">${getColumnValue(item, 'unit')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 70x">${getColumnValue(item, 'quantity')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${getColumnValue(item, 'net_unit_price')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'inventory_time')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'storage_location')}</td>        
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 70px">${getColumnValue(item, 'notes')}</td>
      </tr>`
    });

    const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
    const worksheet = 'Sheet1'
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:x="urn:schemas-microsoft-com:office:excel"
    xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
    <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
    <x:Name>${worksheet}</x:Name>
    <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
    </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
    </head><body>
    <body link="#0563C1" vlink="#954F72">
      <table style="font-family: SimSun">
        <thead>
          <tr>
            <th colspan="12" style="font-size: 18pt; height: 25pt; text-align: center;  vertical-align: middle; border: 0.5pt solid black;">
              ${t('Check Inventory')}
            </th>
          </tr>
        </thead>
        <tbody>          
          <tr>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('num')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('contract name')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('contract id')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Item Name')}</td>            
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Item Model')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('item_id')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('unit')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('quantity')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('net unit price')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Inventory Time')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('storage location')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('notes')}</td>            
          </tr>
          ${recordBody}
        </tbody>
      </table>
    </body></html>
    `;

    const link = document.createElement("a");
    link.href = uri + base64(template);
    link.target = "_blank";
    link.download = `${t('Check Inventory')}.xls`;
    link.click();
  }

  return (
    <Box className={clsx(classes.root, 'main-page-content')}>

      <Box className="page-title-wrapper">
        <Typography variant="h3" className="page-title">{t('Check Inventory')}</Typography>
        <Tooltip title={t('Edit')}>
          <IconButton
            onClick={() => {
              history.push(routes.CHECKINVENTORY_EDIT)
            }}
            disabled={submitted}
            style={{ marginLeft: 'auto' }}
          >
            <CreateOutlinedIcon color={submitted ? '' : 'primary'} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('submit')}>
          <IconButton
            onClick={submitCheckInInventory}
            disabled={submitted}
          >
            <SendOutlinedIcon color={submitted ? '' : 'primary'} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('download')}>
          <IconButton
            onClick={exportCSV}
          >
            <GetAppOutlinedIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box className="darin-table-panel">
        <Box className="table-action-wrapper">
          {/* <TableFilterDropdown filterList={filterColumn} setFilterColumn={(updatedData) => {
            setFilterColumn([...filterColumn.map(item => {
              if (item.id === updatedData.id) return updatedData;
              return item;
            })])
          }} /> */}
          <TablePagination
            className="table-pagination-wrapper"
            rowsPerPageOptions={[5, 10, 25]}
            count={inventoryList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            component="div"
          />
        </Box>
        {/* <Box className={classes.filterWrapper}>
          {filterColumn.map(item => {
            if (!item.checked) return null;
            if (item.id === 'inventory_time') {
              return <DatePicker className="filter-item" label={t(item.name)} value={item.value} onChange={(newValue) => {
                setFilterColumn([...filterColumn.map(itemOne => {
                  if (itemOne.id === item.id) return { ...itemOne, value: newValue }
                  else return itemOne
                })])
              }}
                format="yyyy-MM-dd"
                variant="inline"
                inputVariant="outlined"
                size="small"
                required
              />
            } else {
              return <TextField
                className="filter-item"
                type="text"
                name={item.name}
                label={t(item.name)}
                value={item.value}
                onChange={(event) => setFilterColumn([...filterColumn.map(itemOne => {
                  if (itemOne.id === item.id) return { ...itemOne, value: event.target.value }
                  else return itemOne
                })])}
                size="small"
                variant="outlined"
              />
            }
          })}
        </Box> */}
        <Box className="inventory-table-wrapper">
          <TableContainer className="darin-table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: 'left', minWidth: '70px', width: '70px' }} >{t('num')}</TableCell>
                  <TableCell style={{ minWidth: '192px', width: '192px' }} >{t('contract name')}</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{t('contract id')}</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{t('Item Name')}</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{t('Item Model')}</TableCell>
                  <TableCell style={{ minWidth: '92px', width: '92px', maxWidth: '92px' }} >{t('item_id')}</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{t('unit')}</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{t('quantity')}</TableCell>
                  <TableCell
                    style={{
                      width: i18n.language === 'zh' ? '103px' : '100px',
                      minWidth: i18n.language === 'zh' ? '103px' : '100px'
                    }}
                  >
                    {t('net unit price')}
                  </TableCell>
                  <TableCell
                    sortDirection={orderBy.name === 'inventory_time' ? orderBy.value : false}
                    style={{ width: i18n.language === 'zh' ? '140px' : '180px', minWidth: i18n.language === 'zh' ? '140px' : '180px', textAlign: 'right' }}
                  >
                    <TableSortLabel
                      active={orderBy.name === 'inventory_time'}
                      direction={orderBy.name === 'inventory_time' ? orderBy.value : 'asc'}
                      onClick={() => {
                        if (orderBy.name === 'inventory_time') {
                          if (orderBy.value === 'desc')
                            setOrderBy({
                              name: 'inventory_time',
                              value: 'asc'
                            })
                          else
                            setOrderBy({
                              name: 'inventory_time',
                              value: 'desc'
                            })
                        } else {
                          setOrderBy({
                            name: 'inventory_time',
                            value: 'asc'
                          })
                        }
                      }}
                    >
                      {t('Inventory Time')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ minWidth: '190px', width: '190px' }}>
                    <TableSortLabel
                      active={orderBy.name === 'storage_location'}
                      direction={orderBy.name === 'storage_location' ? orderBy.value : 'asc'}
                      onClick={() => {
                        if (orderBy.name === 'storage_location') {
                          if (orderBy.value === 'desc')
                            setOrderBy({
                              name: 'storage_location',
                              value: 'asc'
                            })
                          else
                            setOrderBy({
                              name: 'storage_location',
                              value: 'desc'
                            })
                        } else {
                          setOrderBy({
                            name: 'storage_location',
                            value: 'asc'
                          })
                        }
                      }}
                    >
                      {t('storage location')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell rowSpan={2} style={{ minWidth: '70px', width: '70px' }} >{t('notes')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getStoredData().map((item, idx) => {
                  if (idx >= (page + 1) * rowsPerPage || idx < page * rowsPerPage)
                    return null;
                  return <TableRow key={idx}>
                    <TableCell style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{idx + 1}</TableCell>
                    <TableCell>{item.contract_name || ''}</TableCell>
                    <TableCell>{item.contract_id || ''}</TableCell>
                    <TableCell>{item.item_name || ''}</TableCell>
                    <TableCell>{item.item_model || ''}</TableCell>
                    <TableCell>{item.item_id || ''}</TableCell>
                    <TableCell>{item.unit || ''}</TableCell>
                    <TableCell>{item.quantity || ''}</TableCell>
                    <TableCell>{item.net_unit_price || ''}</TableCell>
                    <TableCell
                      style={{ paddingRight: 40 }}>
                      {item.inventory_time || ''}
                    </TableCell>
                    <TableCell style={{ paddingRight: 40 }}>{getColumnValue(item, 'storage_location')}</TableCell>
                    <TableCell>{getColumnValue(item, 'notes')}</TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {loading && <FullLoadingSpinner />}
    </Box>
  )
}

export default CheckInventory;
import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#19336a',
    },
  },
  typography: {
    h1: {
      fontSize: '40px',
      fontFamily: "Microsoft YaHei, Arial,Helvetica,sans-serif !important",
      fontWeight: 300,
      lineHeight: '59px',
    },
    h3: {
      fontFamily: "Microsoft YaHei, Arial,Helvetica,sans-serif !important",
      fontSize: '2rem',
    },
    h4: {
      fontFamily: "Microsoft YaHei, Arial,Helvetica,sans-serif !important",
      fontSize: '1.8rem',
    },
    h5: {
      fontFamily: "Microsoft YaHei,Arial,Helvetica,sans-serif !important",
      fontSize: '1.5rem',
    },
    h6: {
      fontFamily: "Microsoft YaHei,Arial,Helvetica,sans-serif !important",
      fontSize: '1.2rem',
    },
    subtitle1: {
      fontFamily: "Microsoft YaHei,Arial,Helvetica,sans-serif !important",
      fontSize: '1rem',
    },
    overline: {
      fontFamily: "Microsoft YaHei,Arial,Helvetica,sans-serif !important",
      fontSize: 12,
    },
  },
  overrides: {
    MuiButton: {
      root: {},
    },

    MuiInputBase: {},

    MuiInputLabel: {},
  },
});

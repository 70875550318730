import { QUESTION_TYPE, TABLE_TYPE } from '../constants';

export const formatChat = (response) => {
  if (response.type === QUESTION_TYPE.TEXT) {
    return {
      type: QUESTION_TYPE.TEXT,
      content: response.response,
    }
  } else if (response.type === QUESTION_TYPE.BUTTON || response.type === QUESTION_TYPE.TABLE) {
    const jsonRes = JSON.parse(response.response);
    return {
      type: response.type,
      content: jsonRes
    }
  } else if (response.type === QUESTION_TYPE.LIST) {
    const jsonRes = JSON.parse(response.response);
    return {
      type: response.type,
      content: jsonRes.list
    }
  }
}

export const getTableType = (tableData) => {
  if (typeof tableData.content.t1.title === 'string')
    return TABLE_TYPE.FORM_TABLE;
  else if (typeof tableData.content.t1.attributes === 'string')
    return TABLE_TYPE.ATTRIBUTE_TABLE;
  else return TABLE_TYPE.ATTRIBUTE_TABLE;
}
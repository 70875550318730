import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  makeStyles,
  Box,
  IconButton,
  Badge,
  List,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import { getMessageAPI, getNewNotificationAPI, readNotificationAPI, notifiedNotificationAPI } from '../../apis'
import { getPassedDate } from '../../utils';
import { routes, USER_ROLE } from '../../constants';
import { authState, notificationState } from '../../atoms';
import { useInterval, useEventListener } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  dropdownWrapper: {
    display: 'none',
    opacity: 0,
    transition: 'opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    width: 300,
    position: 'absolute',
    top: '100%',
    left: '-150px',
    backgroundColor: 'white',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 4,
    '& .MuiListItem-root': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      '& .MuiTypography-subtitle1': {
        fontSize: 14,
        lineHeight: '16px',
        fontStyle: 'normal',
        color: theme.palette.primary.main,
        fontWeight: 600,
        cursor: 'pointer',
      },
      '& .MuiListItemIcon-root': {
        minWidth: 30,
        marginRight: 4,
      },
      '& .MuiListItemText-root': {
        '& .MuiTypography-body1': {
          color: '#333',
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 600,
        }
      },
      '& .notification-content': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 100%',
        '& .MuiTypography-subtitle2': {
          fontSize: 14,
          fontStyle: 'normal',
          color: '#333'
        },
        '& .date': {
          fontSize: 12,
          lineHeight: '14px',
          color: 'grey',
          fontStyle: 'normal',
          fontWeight: 400,
          textAlign: 'left',
          flex: '1 0 auto',
          margin: '4px 0 0 0',
        }
      },
    },
    '& .load-more': {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 600,
      cursor: 'pointer',
      color: '#333',
      textAlign: 'center',
      justifyContent: 'center'
    }
  },
  dropdownWrapperOpen: {
    display: 'block !important',
    opacity: '1 !important'
  },
  iconButton: {
    borderRadius: 0,
    height: '100%',
    '& .MuiSvgIcon-root': {
      width: 30,
      height: 30,
    },
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      top: 2.5
    }
  },

  notificationContent: {
    color: theme.palette.primary.main,
    fontSize: '13px',
    lineHeight: '15px',
    margin: 0,
    overflow: 'hidden',
    fontWeight: 500
  }
}));

const NotificationDropdown = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const auth = useRecoilValue(authState);
  const [notification, setNotification] = useRecoilState(notificationState);

  const [open, setOpen] = useState(null);

  let componentMounted = true;

  const getFirstMessage = useCallback(async () => {
    if (auth) {
      try {
        const res = await getMessageAPI({ userRole: auth.role, userId: auth.id });
        if (componentMounted) {
          const sortNotification = res.sort((a, b) => {
            const aDateValue = new Date(a.ShowedOn).valueOf();
            const bDateValue = new Date(b.ShowedOn).valueOf();
            if (bDateValue < aDateValue) return -1;
            else if (bDateValue > aDateValue) return 1;
            else return 0;
          })
          setNotification([...sortNotification])

          const notificationIds = sortNotification.filter(item => item.notify === 0).map(item => item.msg_id);
          notifiedNotificationAPI(notificationIds).then(resNotifi => {
            setNotification([...sortNotification.map(item => {
              if (notificationIds.indexOf(item.msg_id) > 0)
                return { ...item, notify: 1 }
              else return item;
            })])
          }).catch(err => {
            console.log(err);
          })
        }
      } catch (err) {
        console.log("Notification DropDown, get first message api:");
        console.log(err);
      }
    }
  }, [auth]);

  useEffect(() => {
    getFirstMessage();
  }, [getFirstMessage])

  const checkMousePos = (e) => {
    if (!open) return;

    const menu = document.getElementById("notification-dropdown-menu");
    const menuClientRects = menu.getClientRects();
    if (menuClientRects.length > 0) {
      const menuBoundary = {
        left: menuClientRects[0].x,
        top: menuClientRects[0].y,
        right: menuClientRects[0].x + menuClientRects[0].width,
        bottom: menuClientRects[0].y + menuClientRects[0].height
      };
      if (
        e.clientX >= menuBoundary.left &&
        e.clientX <= menuBoundary.right &&
        e.clientY <= menuBoundary.bottom &&
        e.clientY >= menuBoundary.top
      ) {
        return;
      }
    }

    const dropdownBtn = document.getElementById('notification-dropdown-btn');
    const dropdownBtnClientRects = dropdownBtn.getClientRects();
    if (dropdownBtnClientRects.length > 0) {
      const btnBoundary = {
        left: dropdownBtnClientRects[0].x,
        top: dropdownBtnClientRects[0].y,
        right: dropdownBtnClientRects[0].x + dropdownBtnClientRects[0].width,
        bottom: dropdownBtnClientRects[0].y + dropdownBtnClientRects[0].height
      }
      if (
        e.clientX >= btnBoundary.left &&
        e.clientX <= btnBoundary.right &&
        e.clientY <= btnBoundary.bottom &&
        e.clientY >= btnBoundary.top
      ) {
        return;
      }
    }
    setOpen(false)
  }

  useEventListener('mousemove', checkMousePos);

  useInterval(
    async () => {
      try {
        if (auth && auth.id) {
          const res = await getNewNotificationAPI({ userId: auth.id, userType: auth.role });
          const newNotifications = [];
          res.forEach(item => {
            const findOne = notification.find(itemOne => itemOne.msg_id === item.msg_id)
            if (!findOne)
              newNotifications.push(item)
          })

          if (componentMounted) {
            setNotification([
              ...newNotifications.sort((a, b) => {
                const aDateValue = new Date(a.ShowedOn).valueOf();
                const bDateValue = new Date(b.ShowedOn).valueOf();
                if (bDateValue < aDateValue) return -1;
                else if (bDateValue > aDateValue) return 1;
                else return 0;
              }),
              ...notification,
            ])

            const newNotificationIds = newNotifications.map(item => item.msg_id);
            const resNotify = await notifiedNotificationAPI(newNotificationIds.filter((item, pos) => { return newNotificationIds.indexOf(item) === pos }));
            if (resNotify) {
              setNotification([
                ...newNotifications.sort((a, b) => {
                  const aDateValue = new Date(a.ShowedOn).valueOf();
                  const bDateValue = new Date(b.ShowedOn).valueOf();
                  if (bDateValue < aDateValue) return -1;
                  else if (bDateValue > aDateValue) return 1;
                  else return 0;
                }).map(item => { return { ...item, notify: 1 } }),
                ...notification,
              ])
            }
          }
        }
      } catch (err) {
        console.log("Get New Notification API:");
        console.log(err);
      }
    }, 60000
  )
  const getNotifiExplainText = (item) => {
    if (auth) {
      if (auth.role === USER_ROLE.LEADER) {
        if (item.type === '申请表' && item.action === '待审批') {
          return <p className={classes.notificationContent}>{`【${item.action}】您收到一个新的${item.type} (编号: ${item.form_id})`}</p>;
        } else {
          return <p className={classes.notificationContent}>{`【${item.action}】 ${item.type}: ${item.first_name} ${item.last_name} ${t('has submitted applications regarding items')}${item.item_names.join(', ')}`}</p>
        }
      }
      else if (auth.role === USER_ROLE.ADMIN) {
        if (item.type === '申请表') {
          if (item.action === '通过')
            return <p className={classes.notificationContent}>{`【待出库】${item.type} (编号: ${item.form_id}) 领导已批准`}</p>;
          else if (item.action === '已领取')
            return <p className={classes.notificationContent}>{`【已发放】您已向 ${item.type} (编号: ${item.form_id}) 发放物资`}</p>;
          else if (item.action === '有退回') {
            return <p className={classes.notificationContent}>{`【有退回】您已替 ${item.type} (编号: ${item.form_id}) 办理退回`}</p>;
          } else return <p className={classes.notificationContent}>{`【${item.action}】 ${item.type}: ${t('You have a new application pending')}`}</p>;
        } else {
          return <p className={classes.notificationContent}>{`【${item.action}】 ${item.type}: ${t('You have a new application pending')}`}</p>;
        }
      } else if (auth.role === USER_ROLE.EMPLOYEE) {
        if (item.type === '申请表') {
          if (item.action === '通过' || item.action === '拒绝') {
            return <p className={classes.notificationContent}>{`【${item.action}】您的${item.type} (编号: ${item.form_id}) 已被审批`}</p>;
          } else {
            return <p className={classes.notificationContent}>{`【${item.action}】您的${item.type} (编号: ${item.form_id}) 状态已更新`}</p>;
          }
        } else return <p className={classes.notificationContent}>{`【${item.action}】 ${item.type}: ${t('You have a new application pending')}`}</p>
      }
    } else return <p className={classes.notificationContent}></p>;
  }

  const clickNotification = async (selectedNotification) => {
    setOpen(null)
    try {
      const response = await readNotificationAPI(selectedNotification.msg_id);
      if (response.data.status === 'success') {
        setNotification([
          ...notification.map(item => {
            if (selectedNotification.msg_id === item.msg_id)
              return { ...item, is_read: 1 }
            else return item;
          })
        ])
      }
      history.push(`/application/${selectedNotification.form_id}`)
    } catch (err) {
      history.push(`/application/${selectedNotification.form_id}`)
    }
  }

  return (<Box className={classes.root}>
    <IconButton
      id="notification-dropdown-btn"
      className={classes.iconButton}
      onMouseOver={() => { setOpen(true) }}
    >
      {notification.filter(item => item.is_read === 0).length > 0 ?
        <Badge badgeContent={notification.filter(item => item.is_read === 0).length} color="error">
          <NotificationsNoneOutlinedIcon color="primary" style={{ width: 30, height: 30 }} />
        </Badge>
        :
        <NotificationsNoneOutlinedIcon color="primary" style={{ width: 30, height: 30 }} />
      }
    </IconButton>
    <List
      id="notification-dropdown-menu"
      className={[classes.dropdownWrapper, open ? classes.dropdownWrapperOpen : ''].join(' ')}
    >
      {notification.length === 0 ? <ListItem className="load-more" >
        {t('No Notification')}
      </ListItem> : <>{notification.map((item, idx) => {
        if (idx > 4) return null;
        return <ListItem key={idx} button onClick={() => {
          clickNotification(item)
        }}>
          <ListItemIcon>
            {item.is_read === 0 ? <EmailOutlinedIcon color="secondary" /> : <DraftsOutlinedIcon color="primary" />}
          </ListItemIcon>
          <Box className="notification-content">
            {getNotifiExplainText(item)}
            <span className="date">
              {getPassedDate(item.ShowedOn, (timeStr) => {
                return t(timeStr)
              })}
            </span>
          </Box>
        </ListItem>
      })}
        <ListItem className="load-more" button onClick={() => {
          setOpen(null);
          history.push(routes.NOTIFICATION)
        }}>
          {t('Check previous history...')}
        </ListItem></>}
    </List>
  </Box >)

}

export default NotificationDropdown;
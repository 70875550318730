import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import clsx from 'clsx';
import {
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import { useEventListener } from '../hooks';
import { authState } from '../atoms'
import { routes, HEADER_HEIGHT, LEFT_SIDEBAR_WIDTH, USER_ROLE } from '../constants';

const ROOT_MENU = {
  VERIFICATION: 'Verification Parent Menu',
  INVENTORY: 'Inventory Parent Menu',
  BORROW: 'Borrow Parent Menu',
  APPLICATION: 'Application'
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',//fixed',
    left: 0,
    top: HEADER_HEIGHT,
    width: LEFT_SIDEBAR_WIDTH,
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    backgroundImage: 'linear-gradient(to bottom, #3b75a9 , #7db0de)',
    paddingTop: 0,
    overflow: 'visible',
    transition: `width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms`,
    zIndex: 123456,
    '@media (max-width: 767px)': {
      //width: 3,
      //position: 'absolute',
      // left:-170,
      //visibility:'hidden',
    },

    '& .listItem': {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
      backgroundColor: 'transparent',
      height: 50,
      position: 'relative',

      '&:hover': {
        backgroundColor: 'rgba(25, 51, 106, 0.3)',
      },
      '& .menu-icon': {
        minWidth: 46,
        '& .MuiSvgIcon-root': {
          color: 'white'
        }
      },
      '& .MuiListItemText-root': {
        '& .MuiListItemText-primary': {
          fontSize: '1rem',
          lineHeight: '24.38px',
          color: 'white',
          fontWeight: 400,
        },
      },
      '& .large-title': {
        '& .MuiListItemText-primary': {
          fontSize: '0.875rem !important',
        },
      },
      '& .arrow-right-icon': {
        position: 'absolute',
        color: 'white',
        minWidth: 20,
        right: 0
      },
      '&.listItemActive': {
        color: 'white',
        backgroundColor: 'rgba(25, 51, 106, 0.7)',
        '&:hover': {
          backgroundColor: 'rgba(25, 51, 106, 0.5)',
          filter: 'brightness(0.9)',
        },
        '& .MuiListItemText-root': {
          '& .MuiListItemText-primary': {
            fontWeight: 700,
            color: 'white',
          },
        },
      },
    },

    '& .listItemHasChild': {
      position: 'relative',
      '& .submenu': {
        position: 'absolute',
        left: '100%',
        top: 0,
        display: 'none',
        width: 160,
        backgroundImage: 'linear-gradient(to bottom, #3b75a9 , #7db0de)',
        paddingTop: 0,
        paddingBottom: 0,
        zIndex: 1,
      },
      '&.open': {
        '& .listItemParent': {
          backgroundColor: 'rgba(25, 51, 106, 0.3)',
        },
        '& .submenu': {
          display: 'block'
        }
      }
    }
  },
  sidebarOpen: {
    width: `${LEFT_SIDEBAR_WIDTH}px !important`,
  },

}));

const LeftSidebar = ({ open }) => {
  const classes = useStyles();
  const history = useHistory();

  const { t, i18n } = useTranslation();

  const auth = useRecoilValue(authState);

  const getListItemClass = (itemLocation) => {
    const rootClass = ['listItem'];
    const currentPath = history.location.pathname;

    switch (itemLocation) {
      case routes.HOME:
        if (currentPath === routes.HOME)
          rootClass.push('listItemActive');
        break;
      case routes.ACCOUNTS:
        if (currentPath === routes.ACCOUNTS)
          rootClass.push('listItemActive');
        break;
      case ROOT_MENU.INVENTORY:
        rootClass.push('listItemParent');
        if (currentPath === routes.STOCKIN_SHEETS || currentPath.indexOf('inventory/') >= 0)
          rootClass.push('listItemActive');
        break;
      case routes.STOCKIN:
        if (currentPath === routes.STOCKIN || currentPath.indexOf('inventory/stockin/') >= 0)
          rootClass.push('listItemActive');
        break;
      case routes.STOCKIN_SHEETS:
        if (currentPath === routes.STOCKIN_SHEETS)
          rootClass.push('listItemActive');
        break;
      case routes.CHECKINVENTORY:
        if (currentPath === routes.CHECKINVENTORY)
          rootClass.push('listItemActive')
        break;
      case routes.INVENTORYSHEET:
        if (currentPath.indexOf('/inventory/inventory-sheets') >= 0)
          rootClass.push('listItemActive');
        break;
      case ROOT_MENU.APPLICATIONS:
        rootClass.push('listItemParent');
        if (
          currentPath.indexOf('/applications') >= 0 ||
          currentPath.indexOf('/application') >= 0 ||
          currentPath.indexOf('/claim-sheets') >= 0
        )
          rootClass.push('listItemActive')
        break;
      case routes.APPLICATIONS:
        if (currentPath === routes.APPLICATIONS)
          rootClass.push('listItemActive')
        break;
      case routes.CLAIMSHEETS:
        if (currentPath === routes.CLAIMSHEETS)
          rootClass.push('listItemActive')
        break;
      case routes.ALERTS:
        if (currentPath === routes.ALERTS || currentPath === routes.HOME)
          rootClass.push('listItemActive');
        break;
      case routes.MONITOR:
        if (currentPath === routes.MONITOR)
          rootClass.push('listItemActive');
        break;
      case routes.SEARCH:
        if (currentPath === routes.SEARCH)
          rootClass.push('listItemActive');
        break;
      case routes.ANALYZE:
        if (currentPath === routes.ANALYZE)
          rootClass.push('listItemActive');
        break;
      case routes.SETTING:
        if (currentPath === routes.SETTING)
          rootClass.push('listItemActive');
        break;
      case routes.NOTIFICATION:
        if (currentPath === routes.NOTIFICATION)
          rootClass.push('listItemActive');
        break;
      case ROOT_MENU.VERIFICATION:
        rootClass.push('listItemParent');
        if (
          currentPath.indexOf('/verification/create') >= 0 ||
          currentPath.indexOf('/verification/detail') >= 0 ||
          currentPath.indexOf('/verification/edit') >= 0 ||
          currentPath === routes.VERIFICATION
        )
          rootClass.push('listItemActive')
        break;
      case routes.VERIFICATION:
        if (currentPath === routes.VERIFICATION)
          rootClass.push('listItemActive')
        break;
      case routes.VERIFICATION_CREATE:
        if (
          currentPath.indexOf('/verification/create') >= 0 ||
          currentPath.indexOf('/verification/detail') >= 0 ||
          currentPath.indexOf('/verification/edit') >= 0
        )
          rootClass.push('listItemActive')
        break;
      case ROOT_MENU.BORROW:
        rootClass.push('listItemParent');
        if (currentPath === routes.BORROWS || currentPath.indexOf('borrow/') >= 0)
          rootClass.push('listItemActive');
        break;
      case routes.BORROW_CREATE:
        if (
          currentPath.indexOf('/borrow/create') >= 0 ||
          currentPath.indexOf('/borrow/detail') >= 0 ||
          currentPath.indexOf('/borrow/edit') >= 0
        ) {
          rootClass.push('listItemActive');
        }
        break;
      case routes.BORROWS:
        if (currentPath === routes.BORROWS)
          rootClass.push('listItemActive');
        break;
      default:
        break;
    }

    return rootClass;
  };

  const renderMenuItems = () => {
    if (!auth) return null;

    if (auth.role === USER_ROLE.LEADER) {
      return (<>
        <ListItem
          button
          className={getListItemClass(routes.HOME).join(' ')}
          onClick={() => {
            history.push(routes.HOME);
          }}
        >
          <ListItemIcon
            className="menu-icon"
            onClick={() => {
              history.push(routes.HOME);
            }}
          >
            <HomeOutlinedIcon style={{ width: 22, height: 22 }} />
          </ListItemIcon>
          <ListItemText>{t('home')}</ListItemText>
        </ListItem>
        <ListItem
          button
          className={getListItemClass(routes.NOTIFICATION).join(' ')}
          onClick={() => {
            history.push(routes.NOTIFICATION);
          }}
        >
          <ListItemIcon className="menu-icon">
            <NotificationsNoneOutlinedIcon style={{ width: 22, height: 22 }} />
          </ListItemIcon>
          <ListItemText>{t('Notification')}</ListItemText>
        </ListItem>

        <MenuItemHasChild componentId="verification-parent-menu">
          <ListItem
            button
            className={getListItemClass(ROOT_MENU.VERIFICATION).join(' ')}
          >
            <ListItemIcon
              className="menu-icon"
            >
              <GroupOutlinedIcon style={{ width: 22, height: 22 }} />
            </ListItemIcon>
            <ListItemText>{t('Verification')}</ListItemText>
            <ListItemIcon className="arrow-right-icon">
              <ArrowRightOutlinedIcon />
            </ListItemIcon>
          </ListItem>

          <List className='submenu' >
            <ListItem
              button
              className={getListItemClass(routes.VERIFICATION).join(' ')}
              onClick={() => { history.push(routes.VERIFICATION) }}
            >
              <ListItemText>{t('Verification Sheets')}</ListItemText>
            </ListItem>
          </List>
        </MenuItemHasChild>
        <MenuItemHasChild componentId="stockin-parent-menu">
          <ListItem
            className={getListItemClass(ROOT_MENU.INVENTORY).join(' ')}
          >
            <ListItemIcon className="menu-icon">
              <ShoppingCartOutlinedIcon style={{ width: 22, height: 22 }} />
            </ListItemIcon>
            <ListItemText>{t('Inventory')}</ListItemText>
            <ListItemIcon className="arrow-right-icon">
              <ArrowRightOutlinedIcon />
            </ListItemIcon>
          </ListItem>

          <List className='submenu'>
            {/* <ListItem button className={getListItemClass(routes.STOCKIN).join(' ')}
              onClick={() => {
                history.push(routes.STOCKIN)
              }}
            >
              <ListItemText>{t('StockIn')}</ListItemText>
            </ListItem> */}
            <ListItem button className={getListItemClass(routes.STOCKIN_SHEETS).join(' ')}
              onClick={() => { history.push(routes.STOCKIN_SHEETS) }}
            >
              <ListItemText>{t('StockIn Sheets')}</ListItemText>
            </ListItem>
            <ListItem
              button
              className={getListItemClass(routes.INVENTORYSHEET).join(' ')}
              onClick={() => { history.push(routes.INVENTORYSHEET) }}
            >
              <ListItemText>{t('Inventory Sheets')}</ListItemText>
            </ListItem>
            <ListItem
              button
              className={getListItemClass(routes.CHECKINVENTORY).join(' ')}
              onClick={() => { history.push(routes.CHECKINVENTORY) }}
            >
              <ListItemText>{t('Check Inventory')}</ListItemText>
            </ListItem>
          </List>
        </MenuItemHasChild>
        {/* <ListItem
          button
          className={getListItemClass(routes.ACCOUNTS).join(' ')}
          onClick={() => {
            history.push(routes.ACCOUNTS);
          }}
        >
          <ListItemIcon
            className="menu-icon"
            onClick={() => {
              history.push(routes.ACCOUNTS);
            }}
          >
            <GroupOutlinedIcon style={{ width: 22, height: 22 }} />
          </ListItemIcon>
          <ListItemText>{t('Accounts')}</ListItemText>
        </ListItem> */}
        <MenuItemHasChild componentId="application-parent-menu">
          <ListItem
            className={getListItemClass(ROOT_MENU.APPLICATIONS).join(' ')}
          >
            <ListItemIcon className="menu-icon">
              <EventNoteOutlinedIcon style={{ width: 22, height: 22 }} />
            </ListItemIcon>
            <ListItemText>{t('Application')}</ListItemText>
            <ListItemIcon className="arrow-right-icon">
              <ArrowRightOutlinedIcon />
            </ListItemIcon>
          </ListItem>

          <List className='submenu'>
            <ListItem
              button
              className={getListItemClass(routes.APPLICATIONS).join(' ')}
              onClick={() => { history.push(routes.APPLICATIONS) }}
            >
              <ListItemText>{t('Applications')}</ListItemText>
            </ListItem>
            <ListItem
              button
              className={getListItemClass(routes.CLAIMSHEETS).join(' ')}
              onClick={() => { history.push(routes.CLAIMSHEETS) }}
            >
              <ListItemText>{t('Claim Sheets')}</ListItemText>
            </ListItem>
          </List>
        </MenuItemHasChild>
      </>)
    } else if (auth.role === USER_ROLE.ADMIN) {
      return <>

        <ListItem
          button
          className={getListItemClass(routes.HOME).join(' ')}
          onClick={() => {
            history.push(routes.HOME);
          }}
        >
          <ListItemIcon
            className="menu-icon"
            onClick={() => {
              history.push(routes.HOME);
            }}
          >
            <HomeOutlinedIcon style={{ width: 22, height: 22 }} />
          </ListItemIcon>
          <ListItemText>{t('home')}</ListItemText>
        </ListItem>

        <ListItem
          button
          className={getListItemClass(routes.NOTIFICATION).join(' ')}
          onClick={() => {
            history.push(routes.NOTIFICATION);
          }}
        >
          <ListItemIcon className="menu-icon">
            <NotificationsNoneOutlinedIcon style={{ width: 22, height: 22 }} />
          </ListItemIcon>
          <ListItemText>{t('Notification')}</ListItemText>
        </ListItem>

        <MenuItemHasChild componentId="verification-parent-menu">
          <ListItem
            button
            className={getListItemClass(ROOT_MENU.VERIFICATION).join(' ')}
          >
            <ListItemIcon
              className="menu-icon"
            >
              <GroupOutlinedIcon style={{ width: 22, height: 22 }} />
            </ListItemIcon>
            <ListItemText>{t('Verification')}</ListItemText>
            <ListItemIcon className="arrow-right-icon">
              <ArrowRightOutlinedIcon />
            </ListItemIcon>
          </ListItem>

          <List className='submenu' >
            <ListItem
              button
              className={getListItemClass(routes.VERIFICATION_CREATE).join(' ')}
              onClick={() => { history.push(routes.VERIFICATION_CREATE) }}
            >
              <ListItemText>{t('Verify')}</ListItemText>
            </ListItem>
            <ListItem
              button
              className={getListItemClass(routes.VERIFICATION).join(' ')}
              onClick={() => { history.push(routes.VERIFICATION) }}
            >
              <ListItemText>{t('Verification Sheet')}</ListItemText>
            </ListItem>
          </List>
        </MenuItemHasChild>
        <MenuItemHasChild componentId="stockin-parent-menu">
          <ListItem
            className={getListItemClass(ROOT_MENU.INVENTORY).join(' ')}
          >
            <ListItemIcon className="menu-icon">
              <ShoppingCartOutlinedIcon style={{ width: 22, height: 22 }} />
            </ListItemIcon>
            <ListItemText>{t('Inventory')}</ListItemText>
            <ListItemIcon className="arrow-right-icon">
              <ArrowRightOutlinedIcon />
            </ListItemIcon>
          </ListItem>

          <List className='submenu'>
            <ListItem button className={getListItemClass(routes.STOCKIN).join(' ')}
              onClick={() => {
                history.push(routes.STOCKIN)
              }}
            >
              <ListItemText>{t('StockIn')}</ListItemText>
            </ListItem>
            <ListItem button className={getListItemClass(routes.STOCKIN_SHEETS).join(' ')}
              onClick={() => { history.push(routes.STOCKIN_SHEETS) }}
            >
              <ListItemText>{t('StockIn Sheets')}</ListItemText>
            </ListItem>
            <ListItem
              button
              className={getListItemClass(routes.INVENTORYSHEET).join(' ')}
              onClick={() => { history.push(routes.INVENTORYSHEET) }}
            >
              <ListItemText>{t('Inventory Sheets')}</ListItemText>
            </ListItem>
            <ListItem
              button
              className={getListItemClass(routes.CHECKINVENTORY).join(' ')}
              onClick={() => { history.push(routes.CHECKINVENTORY) }}
            >
              <ListItemText>{t('Check Inventory')}</ListItemText>
            </ListItem>
          </List>
        </MenuItemHasChild>
        <MenuItemHasChild componentId="application-parent-menu">
          <ListItem
            className={getListItemClass(ROOT_MENU.APPLICATIONS).join(' ')}
          >
            <ListItemIcon className="menu-icon">
              <EventNoteOutlinedIcon style={{ width: 22, height: 22 }} />
            </ListItemIcon>
            <ListItemText>{t('Application')}</ListItemText>
            <ListItemIcon className="arrow-right-icon">
              <ArrowRightOutlinedIcon />
            </ListItemIcon>
          </ListItem>
          <List className='submenu'>
            <ListItem
              button
              className={getListItemClass(routes.APPLICATIONS).join(' ')}
              onClick={() => { history.push(routes.APPLICATIONS) }}
            >
              <ListItemText>{t('Applications')}</ListItemText>
            </ListItem>
            <ListItem
              button
              className={getListItemClass(routes.CLAIMSHEETS).join(' ')}
              onClick={() => { history.push(routes.CLAIMSHEETS) }}
            >
              <ListItemText>{t('Claim Sheets')}</ListItemText>
            </ListItem>
          </List>
        </MenuItemHasChild>
      </>
    } else if (auth.role === USER_ROLE.EMPLOYEE) {
      return (<>
        <ListItem
          button
          className={getListItemClass(routes.HOME).join(' ')}
          onClick={() => {
            history.push(routes.HOME);
          }}
        >
          <ListItemIcon
            className="menu-icon"
            onClick={() => {
              history.push(routes.HOME);
            }}
          >
            <HomeOutlinedIcon style={{ width: 22, height: 22 }} />
          </ListItemIcon>
          <ListItemText>{t('home')}</ListItemText>
        </ListItem>
        <ListItem
          button
          className={getListItemClass(routes.NOTIFICATION).join(' ')}
          onClick={() => {
            history.push(routes.NOTIFICATION);
          }}
        >
          <ListItemIcon className="menu-icon">
            <NotificationsNoneOutlinedIcon style={{ width: 22, height: 22 }} />
          </ListItemIcon>
          <ListItemText>{t('Notification')}</ListItemText>
        </ListItem>
        {/* <MenuItemHasChild componentId="verification-parent-menu">
          <ListItem
            button
            className={getListItemClass(ROOT_MENU.VERIFICATION).join(' ')}
          >
            <ListItemIcon
              className="menu-icon"
            >
              <GroupOutlinedIcon style={{ width: 22, height: 22 }} />
            </ListItemIcon>
            <ListItemText>{t('Verification')}</ListItemText>
            <ListItemIcon className="arrow-right-icon">
              <ArrowRightOutlinedIcon />
            </ListItemIcon>
          </ListItem>
          <List className='submenu' >
            <ListItem
              button
              className={getListItemClass(routes.VERIFICATION).join(' ')}
              onClick={() => { history.push(routes.VERIFICATION) }}
            >
              <ListItemText>{t('Verification Sheet')}</ListItemText>
            </ListItem>
          </List>
        </MenuItemHasChild> */}
        <MenuItemHasChild componentId="application-parent-menu">
          <ListItem
            className={getListItemClass(ROOT_MENU.APPLICATIONS).join(' ')}
          >
            <ListItemIcon className="menu-icon">
              <EventNoteOutlinedIcon style={{ width: 22, height: 22 }} />
            </ListItemIcon>
            <ListItemText>
              {i18n.language === 'zh' ? '申请' : 'Application'}
            </ListItemText>
            <ListItemIcon className="arrow-right-icon">
              <ArrowRightOutlinedIcon />
            </ListItemIcon>
          </ListItem>
          <List className='submenu'>
            <ListItem
              button
              className={getListItemClass(routes.APPLICATIONS).join(' ')}
              onClick={() => { history.push(routes.APPLICATIONS) }}
            >
              <ListItemText>
                {i18n.language === 'zh' ? '申请单' : 'Applications'}
              </ListItemText>
            </ListItem>
          </List>
        </MenuItemHasChild>
        <MenuItemHasChild componentId="stockin-parent-menu">
          <ListItem
            className={getListItemClass(ROOT_MENU.INVENTORY).join(' ')}
          >
            <ListItemIcon className="menu-icon">
              <ShoppingCartOutlinedIcon style={{ width: 22, height: 22 }} />
            </ListItemIcon>
            <ListItemText>{t('Inventory')}</ListItemText>
            <ListItemIcon className="arrow-right-icon">
              <ArrowRightOutlinedIcon />
            </ListItemIcon>
          </ListItem>

          <List className='submenu'>
            <ListItem
              button
              className={getListItemClass(routes.CHECKINVENTORY).join(' ')}
              onClick={() => { history.push(routes.CHECKINVENTORY) }}
            >
              <ListItemText>{t('Check Inventory')}</ListItemText>
            </ListItem>
          </List>
        </MenuItemHasChild>
      </>)
    } else if (auth.role === USER_ROLE.UNOFFICIAL) {
      return (<>
        <ListItem
          button
          className={getListItemClass(routes.HOME).join(' ')}
          onClick={() => {
            history.push(routes.HOME);
          }}
        >
          <ListItemIcon
            className="menu-icon"
            onClick={() => {
              history.push(routes.HOME);
            }}
          >
            <HomeOutlinedIcon style={{ width: 22, height: 22 }} />
          </ListItemIcon>
          <ListItemText>{t('home')}</ListItemText>
        </ListItem>
        <MenuItemHasChild componentId="borrow-parent-menu">
          <ListItem
            className={getListItemClass(ROOT_MENU.BORROW).join(' ')}
          >
            <ListItemIcon className="menu-icon">
              <AddShoppingCartOutlinedIcon style={{ width: 22, height: 22 }} />
            </ListItemIcon>
            <ListItemText>{t('Borrow')}</ListItemText>
            <ListItemIcon className="arrow-right-icon">
              <ArrowRightOutlinedIcon />
            </ListItemIcon>
          </ListItem>

          <List className='submenu'>
            <ListItem
              button
              className={getListItemClass(routes.BORROW_CREATE).join(' ')}
              onClick={() => { history.push(routes.BORROW_CREATE) }}
            >
              <ListItemText className="large-title">{t('New Borrow Sheet')}</ListItemText>
            </ListItem>
            <ListItem
              button
              className={getListItemClass(routes.BORROWS).join(' ')}
              onClick={() => { history.push(routes.BORROWS) }}
            >
              <ListItemText>{t('Borrow Sheets')}</ListItemText>
            </ListItem>
          </List>
        </MenuItemHasChild>
      </>)
    }
  }

  return (
    <List
      className={clsx(classes.root, open && classes.sidebarOpen)}
      component='nav'
    >
      {renderMenuItems()}
    </List>
  );
};

const MenuItemHasChild = ({ componentId, children }) => {

  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [history.location.pathname])

  const checkMousePos = (e) => {
    if (!open) return;

    const listItem = document.getElementById(componentId);
    const parentMenu = listItem.getElementsByClassName('listItemParent');
    for (let i = 0; i < parentMenu.length; i++) {
      const parentMenuRect = parentMenu[i].getClientRects();
      if (parentMenuRect.length > 0) {
        const subMenuBoundary = {
          left: parentMenuRect[0].x,
          top: parentMenuRect[0].y,
          right: parentMenuRect[0].x + parentMenuRect[0].width,
          bottom: parentMenuRect[0].y + parentMenuRect[0].height
        }
        if (
          e.clientX >= subMenuBoundary.left &&
          e.clientX <= subMenuBoundary.right &&
          e.clientY <= subMenuBoundary.bottom &&
          e.clientY >= subMenuBoundary.top
        ) {
          return;
        }
      }
    }

    const subMenu = listItem.getElementsByClassName('submenu');
    for (let i = 0; i < subMenu.length; i++) {
      const subMenuClientRects = subMenu[i].getClientRects();
      if (subMenuClientRects.length > 0) {
        const subMenuBoundary = {
          left: subMenuClientRects[0].x,
          top: subMenuClientRects[0].y,
          right: subMenuClientRects[0].x + subMenuClientRects[0].width,
          bottom: subMenuClientRects[0].y + subMenuClientRects[0].height
        }
        if (
          e.clientX >= subMenuBoundary.left &&
          e.clientX <= subMenuBoundary.right &&
          e.clientY <= subMenuBoundary.bottom &&
          e.clientY >= subMenuBoundary.top
        ) {
          return;
        }
      }
    }
    setOpen(false);
  }

  useEventListener('mousemove', checkMousePos);
  return (<Box
    id={componentId}
    className={`listItemHasChild ${open ? 'open' : ''}`}
    onMouseOver={() => {
      setOpen(true)
    }}
  >
    {children}
  </Box>)
}

export default LeftSidebar;

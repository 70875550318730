import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import {
  makeStyles,
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  IconButton,
  Snackbar,
  Checkbox,
  FormControlLabel,
  Switch,
  Breadcrumbs,
  Link
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import SaveIcon from '@material-ui/icons/Save';
import {
  DatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import FullLoadingSpinner from '../../components/FullLoadingSpinner';
import {
  getNextBorrowFormIdAPI,
  saveBorrowInAPI,
  getBorrowInFormAPI,
} from '../../apis';
import { useInterval } from '../../hooks';
import { dateToYYYYMMDD } from '../../utils'
import { routes } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const BorrowEdit = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { borrow_form_id } = useParams();
  const history = useHistory()
  const auth = useRecoilValue(authState);

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    borrow_form_id: -1,
    owner_id: -1,
    data: {
      item_name: "",
      item_model: "",
      unit_price: '',
      total_price: "",
      borrow_quantily: "",
      quantily_available: "",
      borrow_time: "",
      location: "",
      borrower_name: "",
      borrow_company: "",
      working_id: "",
      employee_signature: "",
      admin_signature: "",
      borrow_company_signature: "",
      leader_signature: ""
    }
  })

  const [showSnackbar, setShowSnackbar] = useState(false);

  let componentMounted = true;

  useEffect(() => {
    if (!auth) return;
    setLoading(true);
    if (borrow_form_id) {
      getBorrowInFormAPI(borrow_form_id).then(res => {
        if (componentMounted) {
          setLoading(false);
          setFormData({
            ...res.data
          })
        }
      }).catch(err => {
        if (componentMounted) {
          setFormData({
            ...formData,
            borrow_form_id: borrow_form_id
          })
          setLoading(false);
        }
      })
    } else {
      getNextBorrowFormIdAPI(auth.id).then(res => {
        if (componentMounted) {
          setFormData({
            ...formData,
            borrow_form_id: res.data.borrow_form_id,
          })
          setLoading(false);
        }
      }).catch(err => {
        if (componentMounted) {
          setLoading(false);
        }
        console.log('Get Borrow Api Error:');
        console.log(err);
      })
    }
    return () => { componentMounted = false }
  }, [auth])

  const formatBorrowFormData = () => {
    return {
      ...formData,
      owner_id: auth.id,
      data: {
        ...formData.data,
      },
    }
  }

  // useInterval(
  //   () => {
  //   },
  //   30000,
  // )

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const goBack = () => {
    Swal.fire({
      title: t('Do you want to save the changes?'),
      showCancelButton: true,
      cancelButtonText: t('No'),
      showConfirmButton: true,
      confirmButtonText: t('Yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
      } else {
        history.push(`/borrow/detail/${formData.borrow_form_id}`)
      }
    })
  }

  return (
    <Box className="main-page-content">
      <Grid container>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Breadcrumbs className="darin-breadcrumbs">
            <Link onClick={() => { history.push(routes.BORROWS); }} color="inherit">
              {i18n.language === 'zh' ? '茂明风光电站备件及耗材借用单' : 'Borrow Sheets'}
            </Link>
            <Link
              onClick={goBack}
              color="inherit"
            >
              {t('FormID')}: {formData.borrow_form_id}
            </Link>
            <Typography>{t('Edit')}</Typography>
          </Breadcrumbs>
          <IconButton
            onClick={() => {
              setLoading(true);
              saveBorrowInAPI(formatBorrowFormData()).then(res => {
                setLoading(false);
                history.push(`/borrow/detail/${formData.borrow_form_id}`)
              }).catch(err => {
                setLoading(false);
                console.log(err)
              })
            }}
          >
            <SaveIcon color="primary" />
          </IconButton>
          <IconButton disabled>
            <SendOutlinedIcon />
          </IconButton>
          <IconButton
            disabled
          >
            <GetAppOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3">{t('Borrow Sheet')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ textAlign: 'right', marginBottom: '8px' }}>
            {t('form_id')}: &nbsp;{formData.borrow_form_id}
          </Typography>
        </Grid>
      </Grid>

      <Box className={clsx("darin-table-panel")}>
        <TableContainer className='darin-table-container'>
          <Table>
            <TableBody>
              <TableRow>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Snackbar
        className={classes.snackbar}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity="success" elevation={6} variant="filled">
          {t('The form has been automatically saved')}
        </MuiAlert>
      </Snackbar>

      {loading && <FullLoadingSpinner />}
    </Box >)
}

export default BorrowEdit;
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import {
  makeStyles,
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  IconButton,
  Tooltip,
  Snackbar,
  Checkbox,
  FormControlLabel,
  Switch,
  Breadcrumbs,
  Link
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import SaveIcon from '@material-ui/icons/Save';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import RecordModal from './RecordModal';
import ConfirmModal from '../../components/ConfirmModal';
import FullLoadingSpinner from '../../components/FullLoadingSpinner';
import {
  getNextVerificationFormIDAPI,
  saveVerificationFormAPI,
  getVerificationFormAPI,
} from '../../apis';
import { useInterval } from '../../hooks';
import { dateToYYYYMMDD } from '../../utils'
import { routes } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  verficationTableContainer: {
    width: '100%',
    margin: '5px 0 0 0',
  },
  hasToolbarContainer: {
    marginTop: 12,
  },
  tableContainer: {
    width: '100%',
    backgroundColor: 'white',
    marginTop: 60,
    position: 'relative',
    overflow: 'visible',
  },
  formGroup: {
    display: 'flex',
    margin: '8px 0 0',
    width: '100%',
    '&:last-child': {
      marginBottom: '8px'
    },
    '& .MuiTypography-subtitle1': {
      margin: '0 0 0 0',
      padding: '5px 0 0 0',
      textAlign: 'left',
      fontSize: '0.9rem',
      fontWeight: 600
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  rowTableContainer: {
  },
  tableActions: {
  },
  snackbar: {
    left: 250
  }
}));

const VerificationEdit = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { verificate_form_id } = useParams();
  const history = useHistory()
  const auth = useRecoilValue(authState);

  const [loading, setLoading] = useState(true);
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setFormData] = useState({
    verificate_form_id: '',
    data: {
      contract_name: "",
      contract_id: "",
      supplier_name: "",
      order_id: "",
      onsite_contact: "",
      supplier_contact: "",
      contract_date: null,
      expected_delivery_date: null,
      row_data: [],
      verification_comments: [
        { quantity_verified: 0, notes: null },
        { document_verified: 0, notes: null },
        { appearance_verified: 0, notes: null },
        { performance_verified: 0, notes: null },
        { on_time: 0, notes: null },
        { representative_present: 0, notes: null },
        { check_verified: 0, notes: null }
      ],
      leader_name: "",
      verifier_name: "",
      admin_verification_date: null,
    }
  })
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);

  let componentMounted = true;

  useEffect(() => {
    if (!auth) return;
    setLoading(true);
    if (verificate_form_id) {
      getVerificationFormAPI(verificate_form_id).then(res => {
        if (componentMounted) {
          let returnData = res.data;
          returnData.data.contract_date = res.data.data.contract_date ? moment(res.data.data.contract_date) : null;
          returnData.data.expected_delivery_date = res.data.data.expected_delivery_date ? moment(res.data.data.expected_delivery_date) : null;
          returnData.data.admin_verification_date = res.data.data.admin_verification_date ? moment(res.data.data.admin_verification_date) : null;
          setFormData({
            ...returnData,
          })
          setLoading(false);
        }
      }).catch(err => {
        if (componentMounted) {
          setFormData({
            ...formData,
            verificate_form_id: verificate_form_id
          })
          setLoading(false);
        }
      })
    } else {
      getNextVerificationFormIDAPI(auth.id).then(res => {
        if (componentMounted) {
          setFormData({
            ...formData,
            verificate_form_id: res.data.verificate_form_id,
          })
          setLoading(false);
        }
      }).catch(err => {
        if (componentMounted) {
          setLoading(false);
        }
        console.log('Get Verification Api Error:');
        console.log(err);
      })
    }
    return () => { componentMounted = false }
  }, [auth])

  const formatVerificationFormData = () => {
    return {
      ...formData,
      owner_id: auth.id,
      data: {
        ...formData.data,
        //contract_date: formData.data.contract_date ? dateToYYYYMMDD(formData.data.contract_date, i18n.language) : null,
        contract_date: formData.data.contract_date ? dateToYYYYMMDD(formData.data.contract_date) : null,
        //expected_delivery_date: formData.data.expected_delivery_date ? dateToYYYYMMDD(formData.data.expected_delivery_date, i18n.language) : null,
        expected_delivery_date: formData.data.expected_delivery_date ? dateToYYYYMMDD(formData.data.expected_delivery_date) : null,
        admin_verification_date: formData.data.admin_verification_date ? dateToYYYYMMDD(formData.data.admin_verification_date, i18n.language) : null,
      },
    }
  }

  useInterval(
    () => {
      let possibleSave = true;
      Object.keys(formData).forEach(itemKey => {
        if (!formData[itemKey] || formData[itemKey].length === 0)
          possibleSave = false;
      })

      if (!possibleSave) return;

      let saveFormData = formatVerificationFormData();
      saveFormData.data.expected_delivery_date = formData.data.expected_delivery_date ? dateToYYYYMMDD(formData.data.expected_delivery_date, 'en') : null;
      saveFormData.data.admin_verification_date = formData.data.admin_verification_date ? dateToYYYYMMDD(formData.data.admin_verification_date, 'en') : null;
      saveFormData.data.contract_date = formData.data.contract_date ? dateToYYYYMMDD(formData.data.contract_date, 'en') : null;

      saveVerificationFormAPI(
        saveFormData
      ).then(res => {
        if (res.data.status === 'success')
          setShowSnackbar(true)
      })
    },
    30000,
  )

  const addRecord = (newRecord) => {
    if (newRecord.row_num === -1) {
      setFormData({
        ...formData,
        data: {
          ...formData.data,
          row_data: [
            ...formData.data.row_data.map((item, idx) => {
              return { ...item, row_num: idx + 1 }
            }),
            {
              ...newRecord,
              row_num: formData.data.row_data.length + 1
            }
          ]
        }
      })
      setSelectedRecords([
        ...selectedRecords.filter(item => item.row_num !== -1)
      ])
    } else {
      setFormData({
        ...formData,
        data: {
          ...formData.data,
          row_data: [...formData.data.row_data.map(item => {
            if (item.row_num === newRecord.row_num)
              return newRecord;
            else return item;
          })]
        }
      })
      setSelectedRecords([...selectedRecords.map(item => {
        if (item.row_num === newRecord.row_num)
          return newRecord;
        else return item;
      })])
    }
    setShowRecordModal(false)
  }

  const deleteRecord = () => {
    const deletedIds = selectedRecords.map(item => item.row_num)
    setFormData({
      ...formData,
      data: {
        ...formData.data,
        row_data: [...formData.data.row_data.filter(item => !deletedIds.includes(item.row_num)).map((item, idx) => { return { ...item, row_num: idx + 1 } })]
      }
    })
    setSelectedRecords([])
    setShowDeleteModal(false)
  }

  const onSelectAllClick = (event) => {
    if (event.target.checked)
      setSelectedRecords([...formData.data.row_data]);
    else setSelectedRecords([])
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const getRecordForModal = () => {
    const findOne = selectedRecords.find(item => item.row_num === -1)
    if (findOne) {
      return findOne;
    } else return selectedRecords[0];
  }

  const getRecordCheckValue = (recordId) => {
    const findOne = selectedRecords.find(item => item.row_num === recordId)
    if (findOne) return true;
    else return false;
  }

  const goBack = () => {
    Swal.fire({
      title: t('Do you want to save the changes?'),
      showCancelButton: true,
      cancelButtonText: t('No'),
      showConfirmButton: true,
      confirmButtonText: t('Yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        let saveFormData = formatVerificationFormData();
        saveFormData.data.expected_delivery_date = formData.data.expected_delivery_date ? dateToYYYYMMDD(formData.data.expected_delivery_date, 'en') : null;
        saveFormData.data.admin_verification_date = formData.data.admin_verification_date ? dateToYYYYMMDD(formData.data.admin_verification_date, 'en') : null;
        saveFormData.data.contract_date = formData.data.contract_date ? dateToYYYYMMDD(formData.data.contract_date, 'en') : null;

        saveVerificationFormAPI(saveFormData).then(res => {
          setLoading(false);
          history.push(`/verification/detail/${formData.verificate_form_id}`)
        }).catch(err => {
          setLoading(false);
          console.log(err)
        })
      } else {
        history.push(`/verification/detail/${formData.verificate_form_id}`)
      }
    })
  }

  return (
    <Box className="main-page-content">
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Breadcrumbs className="darin-breadcrumbs">
            <Link onClick={() => { history.push(routes.VERIFICATION) }} color="inherit">
              {t('Verification Sheets')}
            </Link>
            <Link
              onClick={goBack}
              color="inherit"
            >
              {t('FormID')}: {formData.verificate_form_id}
            </Link>
            <Typography>{t('Edit')}</Typography>
          </Breadcrumbs>
          <Tooltip title={t('Save')}>
            <IconButton
              onClick={() => {
                setLoading(true);
                let saveFormData = formatVerificationFormData();
                saveFormData.data.expected_delivery_date = formData.data.expected_delivery_date ? dateToYYYYMMDD(formData.data.expected_delivery_date, 'en') : null;
                saveFormData.data.admin_verification_date = formData.data.admin_verification_date ? dateToYYYYMMDD(formData.data.admin_verification_date, 'en') : null;
                saveFormData.data.contract_date = formData.data.contract_date ? dateToYYYYMMDD(formData.data.contract_date, 'en') : null;

                saveVerificationFormAPI(saveFormData).then(res => {
                  setLoading(false);
                  history.push(`/verification/detail/${formData.verificate_form_id}`)
                }).catch(err => {
                  setLoading(false);
                  console.log(err)
                })
              }}
            >
              <SaveIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('submit')}>
            <IconButton disabled><SendOutlinedIcon /></IconButton>
          </Tooltip>
          <Tooltip title={t('download')}>
            <IconButton disabled><GetAppOutlinedIcon /></IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3">{t('Verification Sheet')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ textAlign: 'right', marginBottom: '8px' }}>
            {t('FormID')}: &nbsp;{formData.verificate_form_id}
          </Typography>
        </Grid>
      </Grid>

      <Box className={clsx(classes.verficationTableContainer, "darin-table-panel")}>
        <TableContainer className='darin-table-container'>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={3}
                  style={{ border: 'none', fontWeight: 600, fontSize: '1.1rem', paddingBottom: 0, height: 32 }}
                >
                  {t('contract name and id')}
                </TableCell>
                <TableCell
                  colSpan={3}
                  style={{ border: 'none', fontWeight: 600, fontSize: '1.1rem', paddingBottom: 0, height: 32 }}
                >
                  {t('supplier name')}
                </TableCell>
              </TableRow>
              <TableRow >
                <TableCell colSpan={3} style={{ border: 'none' }} >
                  <Box className={classes.formGroup}>
                    <Typography variant="subtitle1" style={{ width: 180, fontWeight: 600 }}>
                      {t('Contract Name')}:
                    </Typography>
                    <TextField
                      label=""
                      value={formData.data.contract_name}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          data: {
                            ...formData.data,
                            contract_name: e.target.value
                          }
                        })
                      }}
                      size="small"
                      variant="outlined"
                    />
                  </Box>

                  <Box className={classes.formGroup}>
                    <Typography variant="subtitle1" style={{ width: 180, fontWeight: 600 }}>
                      {t('Contract ID')}:
                    </Typography>
                    <TextField
                      label=""
                      value={formData.data.contract_id}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          data: {
                            ...formData.data,
                            contract_id: e.target.value
                          }
                        })
                      }}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </TableCell>
                <TableCell colSpan={3} style={{ border: 'none' }} >
                  <Box className={classes.formGroup} >
                    <TextField
                      style={{ marginLeft: '10%', width: '80%' }}
                      label=""
                      value={formData.data.supplier_name}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          data: {
                            ...formData.data,
                            supplier_name: e.target.value
                          }
                        })
                      }}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{
                  width: i18n.language === 'zh' ? 70 : 98,
                  border: 'none',
                  fontWeight: 600,
                  fontSize: '0.9rem',
                  paddingTop: 16,
                  paddingBottom: 0,
                  height: 32
                }}
                >
                  {t('Order id')}</TableCell>
                <TableCell
                  style={{
                    border: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem',
                    paddingTop: 16,
                    paddingBottom: 0,
                    height: 32
                  }}
                >
                  {t('on-site contact/phone')}
                </TableCell>
                <TableCell
                  style={{
                    border: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem',
                    paddingTop: 16,
                    paddingBottom: 0,
                    height: 32
                  }}
                >
                  {t('supplier contact/phone')}
                </TableCell>
                <TableCell
                  style={{
                    border: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem',
                    paddingTop: 16,
                    paddingBottom: 0,
                    height: 32
                  }}
                >
                  {t('contract date')}
                </TableCell>
                <TableCell
                  style={{
                    border: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem',
                    paddingTop: 16,
                    paddingBottom: 0,
                    height: 32
                  }}
                >
                  {t('expected delivery date')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <Box className={classes.formGroup}>
                    <TextField
                      label=""
                      value={formData.data.order_id}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          data: {
                            ...formData.data,
                            order_id: e.target.value
                          }
                        })
                      }}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </TableCell>
                <TableCell style={{ border: 'none' }}>
                  <Box className={classes.formGroup}>
                    <TextField
                      label=""
                      value={formData.data.onsite_contact}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          data: {
                            ...formData.data,
                            onsite_contact: e.target.value
                          }
                        })
                      }}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </TableCell>
                <TableCell style={{ border: 'none' }}>
                  <Box className={classes.formGroup}>
                    <TextField
                      label=""
                      value={formData.data.supplier_contact}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          data: {
                            ...formData.data,
                            supplier_contact: e.target.value
                          }
                        })
                      }}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </TableCell>
                <TableCell style={{ border: 'none' }}>
                  <Box className={classes.formGroup}>
                    <KeyboardDatePicker
                      value={formData.data.contract_date}
                      onChange={(newValue) => {
                        console.log(newValue)
                        setFormData({
                          ...formData,
                          data: {
                            ...formData.data,
                            contract_date: newValue ? moment(newValue) : null
                          }
                        })
                      }}
                      format={i18n.language === 'zh' ? 'yyyy-MM-dd' : 'yyyy-MM-dd'}
                      variant="inline"
                      inputVariant="outlined"
                      size="small"
                      required
                    />
                  </Box>
                </TableCell>
                <TableCell style={{ border: 'none', }}>
                  <Box className={classes.formGroup}>
                    <KeyboardDatePicker
                      value={formData.data.expected_delivery_date}
                      onChange={(newValue) => {
                        setFormData({
                          ...formData,
                          data: {
                            ...formData.data,
                            expected_delivery_date: newValue ? moment(newValue) : null
                          }
                        })
                      }}
                      format={i18n.language === 'zh' ? 'yyyy-MM-dd' : 'yyyy-MM-dd'}
                      variant="inline"
                      inputVariant="outlined"
                      size="small"
                      required
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box className={clsx(classes.hasToolbarContainer, "darin-table-panel")}>
        <Box className='table-action-wrapper'>
          <IconButton
            color="primary"
            onClick={() => {
              setSelectedRecords([...selectedRecords, {
                row_num: -1,
                item_name: '',
                item_model: '',
                quantity: '',
                unit: '',
                item_id: '',
              }])
              setShowRecordModal(true);
            }}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowRecordModal(true)
            }}
            disabled={selectedRecords.length !== 1}
          >
            <CreateOutlinedIcon color="primary" />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowDeleteModal(true);
            }}
            disabled={selectedRecords.length < 1}
          >
            <DeleteForeverOutlinedIcon color="error" />
          </IconButton>
        </Box>
        <TableContainer className='darin-table-container'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ paddingLeft: 0, width: 60, textAlign: 'center' }}>
                  <Checkbox
                    className={classes.tableCheck}
                    color="primary"
                    checked={selectedRecords.filter(item => item.row_num !== -1).length > 0 && selectedRecords.filter(item => item.row_num !== -1).length === formData.data.row_data.filter(item => item.row_num !== -1).length}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </TableCell>
                <TableCell style={{ width: i18n.language === 'zh' ? 70 : 35, fontWeight: 600 }}>{t('No.')}</TableCell>
                <TableCell style={{ fontWeight: 600, fontSize: '0.9rem' }}>{t('Name')}</TableCell>
                <TableCell style={{ fontWeight: 600, fontSize: '0.9rem' }}>{t('item_model')}</TableCell>
                <TableCell style={{ fontWeight: 600, fontSize: '0.9rem' }}>{t('quantity')}</TableCell>
                <TableCell style={{ fontWeight: 600, fontSize: '0.9rem' }}>{t('unit')}</TableCell>
                <TableCell style={{ fontWeight: 600, fontSize: '0.9rem' }}>{t('item_id')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.data.row_data.length === 0 && <TableRow><TableCell colSpan={7}><span style={{ color: 'grey' }}>{t("There's no record")}</span></TableCell></TableRow>}
              {
                formData.data.row_data.length > 0 && <>
                  {formData.data.row_data.map((itemRow, idx) => {
                    return <TableRow key={idx}>
                      <TableCell style={{ paddingLeft: 0, width: 60, textAlign: 'center' }}>
                        <Checkbox
                          className={classes.tableCheck}
                          checked={getRecordCheckValue(itemRow.row_num)}
                          onChange={(event) => {
                            if (event.target.checked)
                              setSelectedRecords([...selectedRecords, itemRow]);
                            else
                              setSelectedRecords([...selectedRecords.filter(itemSelected => itemSelected.row_num !== itemRow.row_num)])
                          }}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>{itemRow.row_num}</TableCell>
                      <TableCell>{itemRow.item_name}</TableCell>
                      <TableCell>{itemRow.item_model}</TableCell>
                      <TableCell>{itemRow.quantity}</TableCell>
                      <TableCell>{itemRow.unit}</TableCell>
                      <TableCell>{itemRow.item_id}</TableCell>
                    </TableRow>
                  })}</>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box className={clsx(classes.tableContainer, "darin-table-panel")}>
        <TableContainer className='darin-table-container'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} style={{ fontSize: '1.1rem' }}>{t('Verification Comments')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('project')}</TableCell>
                <TableCell>{t('result')}</TableCell>
                <TableCell>{t('notes')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t('quantity verified')}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        name="quantity verified'"
                        color="primary"
                        checked={formData.data.verification_comments[0].quantity_verified}
                        onChange={(event) => {
                          let verification_comments = formData.data.verification_comments;
                          verification_comments[0].quantity_verified = event.target.checked;
                          setFormData({
                            ...formData,
                            data: {
                              ...formData.data,
                              verification_comments: verification_comments
                            }
                          })
                        }}
                      />
                    }
                    label={formData.data.verification_comments[0].quantity_verified ? t('Yes') : t('No')}
                  />
                </TableCell>
                <TableCell>
                  <TextField

                    type="textarea"
                    multiline
                    rowsMax={1}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={formData.data.verification_comments[0].notes}
                    onChange={(event) => {
                      let verification_comments = formData.data.verification_comments;
                      verification_comments[0].notes = event.target.value;
                      setFormData({
                        ...formData,
                        data: {
                          ...formData.data,
                          verification_comments: verification_comments
                        }
                      })
                    }}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('document verified')}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        name="quantity verified'"
                        color="primary"
                        checked={formData.data.verification_comments[1].document_verified}
                        onChange={(event) => {
                          let verification_comments = formData.data.verification_comments;
                          verification_comments[1].document_verified = event.target.checked;
                          setFormData({
                            ...formData,
                            data: {
                              ...formData.data,
                              verification_comments: verification_comments
                            }
                          })
                        }}
                      />
                    }
                    label={formData.data.verification_comments[1].document_verified ? t('Yes') : t('No')}
                  />
                </TableCell>
                <TableCell>
                  <TextField

                    type="textarea"
                    multiline
                    rowsMax={1}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={formData.data.verification_comments[1].notes}
                    onChange={(event) => {
                      let verification_comments = formData.data.verification_comments;
                      verification_comments[1].notes = event.target.value;
                      setFormData({
                        ...formData,
                        data: {
                          ...formData.data,
                          verification_comments: verification_comments
                        }
                      })
                    }}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('appearance verified')}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        name="appearance verified"
                        color="primary"
                        checked={formData.data.verification_comments[2].appearance_verified}
                        onChange={(event) => {
                          let verification_comments = formData.data.verification_comments;
                          verification_comments[2].appearance_verified = event.target.checked;
                          setFormData({
                            ...formData,
                            data: {
                              ...formData.data,
                              verification_comments: verification_comments
                            }
                          })
                        }}
                      />
                    }
                    label={formData.data.verification_comments[2].appearance_verified ? t('Yes') : t('No')}
                  />
                </TableCell>
                <TableCell>
                  <TextField

                    type="textarea"
                    multiline
                    rowsMax={1}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={formData.data.verification_comments[2].notes}
                    onChange={(event) => {
                      let verification_comments = formData.data.verification_comments;
                      verification_comments[2].notes = event.target.value;
                      setFormData({
                        ...formData,
                        data: {
                          ...formData.data,
                          appearance_verified: verification_comments
                        }
                      })
                    }}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('performance verified')}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        name="performance_verified"
                        color="primary"
                        checked={formData.data.verification_comments[3].performance_verified}
                        onChange={(event) => {
                          let verification_comments = formData.data.verification_comments;
                          verification_comments[3].performance_verified = event.target.checked;
                          setFormData({
                            ...formData,
                            data: {
                              ...formData.data,
                              verification_comments: verification_comments
                            }
                          })
                        }}
                      />
                    }
                    label={formData.data.verification_comments[3].performance_verified ? t('Yes') : t('No')}
                  />
                </TableCell>
                <TableCell>
                  <TextField

                    type="textarea"
                    multiline
                    rowsMax={1}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={formData.data.verification_comments[3].notes}
                    onChange={(event) => {
                      let verification_comments = formData.data.verification_comments;
                      verification_comments[3].notes = event.target.value;
                      setFormData({
                        ...formData,
                        data: {
                          ...formData.data,
                          verification_comments: verification_comments
                        }
                      })
                    }}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('on time')}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        name="on_time"
                        color="primary"
                        checked={formData.data.verification_comments[4].on_time}
                        onChange={(event) => {
                          let verification_comments = formData.data.verification_comments;
                          verification_comments[4].on_time = event.target.checked;
                          setFormData({
                            ...formData,
                            data: {
                              ...formData.data,
                              verification_comments: verification_comments
                            }
                          })
                        }}
                      />
                    }
                    label={formData.data.verification_comments[4].on_time ? t('Yes') : t('No')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="textarea"
                    multiline
                    rowsMax={1}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={formData.data.verification_comments[4].notes}
                    onChange={(event) => {
                      let verification_comments = formData.data.verification_comments;
                      verification_comments[4].notes = event.target.value;
                      setFormData({
                        ...formData,
                        data: {
                          ...formData.data,
                          verification_comments: verification_comments
                        }
                      })
                    }}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('representative present')}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        name="representative_present"
                        color="primary"
                        checked={formData.data.verification_comments[5].representative_present}
                        onChange={(event) => {
                          let verification_comments = formData.data.verification_comments;
                          verification_comments[5].representative_present = event.target.checked;
                          setFormData({
                            ...formData,
                            data: {
                              ...formData.data,
                              verification_comments: verification_comments
                            }
                          })
                        }}
                      />
                    }
                    label={formData.data.verification_comments[5].representative_present ? t('Yes') : t('No')}
                  />
                </TableCell>
                <TableCell>
                  <TextField

                    type="textarea"
                    multiline
                    rowsMax={1}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={formData.data.verification_comments[5].notes}
                    onChange={(event) => {
                      let verification_comments = formData.data.verification_comments;
                      verification_comments[5].notes = event.target.value;
                      setFormData({
                        ...formData,
                        data: {
                          ...formData.data,
                          verification_comments: verification_comments
                        }
                      })
                    }}
                    required
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('check verified')}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        name="check verified"
                        color="primary"
                        checked={formData.data.verification_comments[6].check_verified === 1}
                        onChange={(event) => {
                          let verification_comments = formData.data.verification_comments;
                          verification_comments[6].check_verified = event.target.checked;
                          setFormData({
                            ...formData,
                            data: {
                              ...formData.data,
                              verification_comments: verification_comments
                            }
                          })
                        }}
                      />
                    }
                    label={formData.data.verification_comments[6].check_verified ? t('Yes') : t('No')}
                  />
                </TableCell>
                <TableCell>
                  <TextField

                    type="textarea"
                    multiline
                    rowsMax={1}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={formData.data.verification_comments[6].notes}
                    onChange={(event) => {
                      let verification_comments = formData.data.verification_comments;
                      verification_comments[6].notes = event.target.value;
                      setFormData({
                        ...formData,
                        data: {
                          ...formData.data,
                          verification_comments: verification_comments
                        }
                      })
                    }}
                    required
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box className={clsx(classes.tableContainer, "darin-table-panel")}>
        <TableContainer className='darin-table-container'>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    textAlign: 'left',
                    width: '200px',
                    borderBottom: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem'
                  }}
                >
                  {t('accepter name')}:
                </TableCell>
                <TableCell style={{ textAlign: 'left', borderBottom: 'none' }}>
                  <TextField
                    label=""
                    value={formData.data.leader_name}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        data: {
                          ...formData.data,
                          leader_name: e.target.value
                        }
                      })
                    }}
                    size="small"
                    variant="outlined"
                  />
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'right',
                    borderBottom: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem'
                  }}
                >
                  {t('acceptance time')}:
                </TableCell>
                <TableCell style={{ textAlign: 'left', borderBottom: 'none' }}>
                  <KeyboardDatePicker
                    value={formData.data.admin_verification_date}
                    onChange={(newValue) => {
                      setFormData({
                        ...formData,
                        data: {
                          ...formData.data,
                          admin_verification_date: newValue ? moment(newValue) : null
                        }
                      })
                    }}
                    format={i18n.language === 'zh' ? "yyyy年MM月dd日" : "yyyy-MM-dd"}
                    variant="inline"
                    inputVariant="outlined"
                    size="small"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{
                  textAlign: 'left',
                  fontWeight: 600,
                  fontSize: '0.9rem'
                }}>
                  {t('accepter')}:
                </TableCell>
                <TableCell style={{ textAlign: 'left' }}>
                  <TextField
                    label=""
                    value={formData.data.verifier_name}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        data: {
                          ...formData.data,
                          verifier_name: e.target.value
                        }
                      })
                    }}
                    size="small"
                    variant="outlined"
                  />
                </TableCell>
                <TableCell colspan={2} ></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {
        showRecordModal && <RecordModal
          isShow={showRecordModal}
          recordInfo={getRecordForModal()}
          addRecord={addRecord}
          hideModal={() => {
            setShowRecordModal(false)
            setSelectedRecords([
              ...selectedRecords.filter(item => item.row_num !== -1)
            ])
          }} />
      }

      {
        showDeleteModal && <ConfirmModal
          isShow={showDeleteModal}
          hideModal={() => {
            setShowDeleteModal(false)
          }}
          onOk={deleteRecord}
          title={t('Please confirm')}
          description={`${t('Are you going to delete it?')}`}
        />
      }

      <Snackbar
        className={classes.snackbar}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity="success" elevation={6} variant="filled">
          {t('The form has been automatically saved')}
        </MuiAlert>
      </Snackbar>

      {loading && <FullLoadingSpinner />}
    </Box >)
}

export default VerificationEdit;
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms';
import clsx from 'clsx';
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  IconButton,
  Checkbox
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ConfirmModal from '../../components/ConfirmModal';
import FullLoadingSpinner from '../../components/FullLoadingSpinner';
import { showAllBorrowInFormsAPI, deleteBorrowAPI } from '../../apis';
import { routes } from '../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .page-title-wrapper': {
      marginBottom: 0,
    }
  },
}));

const BorrowSheets = () => {

  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const auth = useRecoilValue(authState);

  const [loading, setLoading] = useState(true);
  const [borrowList, setBorrowList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBorrows, setSelectedBorrows] = useState([])

  let componentMounted = true;

  useEffect(() => {
    if (auth && auth.id) {
      showAllBorrowInFormsAPI().then(res => {
        if (componentMounted) {
          setBorrowList([...res]);
          setLoading(false);
        }
      }).catch(err => {
        if (componentMounted) {
          setBorrowList([]);
          setLoading(false);
        }
        console.log("Get show all borrow in forms api error:")
        console.log(err);
      })
    }
    return () => {
      componentMounted = false;
    }
  }, [auth]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteBorrow = () => {
    setLoading(true);
    setShowDeleteModal(false);
    const selectedIDs = selectedBorrows.map(item => item.borrow_form_id);
    deleteBorrowAPI(selectedIDs).then(res => {
      setBorrowList([
        ...borrowList.filter(item => selectedIDs.indexOf(item.borrow_form_id) < 0)
      ])
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    })
  }

  const [orderBy, setOrderBy] = useState({
    name: '',
    value: 'asc'
  })

  const getSortedData = () => {
    if (!orderBy.name) return borrowList;

    if (orderBy.name === 'borrow_time' && orderBy.value) {
      return borrowList.sort((a, b) => {
        const orderValue = orderBy.value === 'desc' ? -1 : 1;
        if (!a.borrow_time) return -1 * orderValue;
        if (!b.borrow_time) return 1 * orderValue;

        const aDateValue = new Date(a.borrow_time).valueOf();
        const bDateValue = new Date(b.borrow_time).valueOf();

        if (bDateValue > aDateValue) return -1 * orderValue;
        else if (bDateValue < aDateValue) return 1 * orderValue;
        else return 0;
      })
    } else if (orderBy.name === 'borrow_form_id' && orderBy.value) {
      return borrowList.sort((a, b) => {
        const orderValue = orderBy.value === 'desc' ? -1 : 1;
        if (b.borrow_form_id > a.borrow_form_id) return -1 * orderValue;
        else if (b.borrow_form_id < a.borrow_form_id) return 1 * orderValue;
        else return 0;
      })
    }
    else return borrowList;
  }

  const onSelectAllClick = (event) => {
    if (event.target.checked)
      setSelectedBorrows([...borrowList]);
    else setSelectedBorrows([])
  }

  return (
    <Box className={clsx(classes.root, "main-page-content")}>
      <Box className="page-title-wrapper">
        <Typography variant="h3" className="page-title">
          {i18n.language === 'zh' ? '茂明风光电站备件及耗材借用单' : 'Borrow Sheets'}
        </Typography>
        <IconButton
          style={{ marginLeft: 'auto' }}
          onClick={() => { history.push(routes.BORROW_CREATE) }}>
          <AddIcon color="primary" />
        </IconButton>
        <IconButton
          onClick={() => {
            setShowDeleteModal(true);
          }}
        >
          <DeleteForeverOutlinedIcon color="error" />
        </IconButton>
      </Box>

      <Box className="darin-table-panel">
        <Box className="table-action-wrapper">
          <TablePagination
            className="table-pagination-wrapper"
            rowsPerPageOptions={[5, 10, 25]}
            count={borrowList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            component="div"
          />
        </Box>
        <TableContainer className="darin-table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ paddingLeft: 0, width: '40px', textAlign: 'left' }}>
                  <Checkbox
                    className={classes.tableCheck}
                    color="primary"
                    checked={selectedBorrows.length === borrowList.length}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    width: i18n.language === 'zh' ? '150px' : '170px',
                    minWidth: i18n.language === 'zh' ? '150px' : '170px',
                    textAlign: 'center'
                  }}
                  sortDirection={orderBy.name === 'borrow_form_id' ? orderBy.value : false}
                >
                  <TableSortLabel
                    active={orderBy.name === 'borrow_form_id'}
                    direction={orderBy.name === 'borrow_form_id' ? orderBy.value : 'asc'}
                    onClick={() => {
                      if (orderBy.name === 'borrow_form_id') {
                        if (orderBy.value === 'desc')
                          setOrderBy({
                            name: 'borrow_form_id',
                            value: 'asc'
                          })
                        else
                          setOrderBy({
                            name: 'borrow_form_id',
                            value: 'desc'
                          })
                      } else {
                        setOrderBy({
                          name: 'borrow_form_id',
                          value: 'asc'
                        })
                      }
                    }}
                  >
                    {t('Borrow FormID')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ textAlign: 'center', width: '33.33%' }}>{t('Item Name')}</TableCell>
                <TableCell style={{ textAlign: 'center', width: '33.33%' }}>{t('Item Model')}</TableCell>
                <TableCell style={{ textAlign: 'center', width: '33.33%' }}>{t('Total Price')}</TableCell>
                <TableCell
                  sortDirection={orderBy.name === 'borrow_time' ? orderBy.value : false}
                  style={{
                    width: i18n.language === 'zh' ? '150px' : '155px',
                    minWidth: i18n.language === 'zh' ? '150px' : '155px',
                    textAlign: 'right'
                  }}
                >
                  <TableSortLabel
                    active={orderBy.name === 'borrow_time'}
                    direction={orderBy.name === 'borrow_time' ? orderBy.value : 'asc'}
                    onClick={() => {
                      if (orderBy.name === 'borrow_time') {
                        if (orderBy.value === 'desc')
                          setOrderBy({
                            name: 'borrow_time',
                            value: 'asc'
                          })
                        else
                          setOrderBy({
                            name: 'borrow_time',
                            value: 'desc'
                          })
                      } else {
                        setOrderBy({
                          name: 'borrow_time',
                          value: 'asc'
                        })
                      }
                    }}
                  >
                    {t('Borrow Time')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getSortedData().map((item, idx) => {
                if (idx >= (page + 1) * rowsPerPage || idx < page * rowsPerPage)
                  return null;
                return <TableRow key={idx} hover style={{ cursor: 'pointer' }}>
                  <TableCell style={{ paddingLeft: 0, width: '40px', textAlign: 'left' }}>
                    <Checkbox
                      className={classes.tableCheck}
                      checked={selectedBorrows.map(item => item.borrow_form_id).indexOf(item.borrow_form_id) >= 0}
                      onChange={(event) => {
                        if (event.target.checked)
                          setSelectedBorrows([...selectedBorrows, item]);
                        else
                          setSelectedBorrows([...selectedBorrows.filter(itemSelected => itemSelected.borrow_form_id !== item.borrow_form_id)])
                      }}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell
                    style={{ width: i18n.language === 'zh' ? '120px' : '170px', textAlign: 'center', paddingRight: '40px' }}
                    onClick={() => { history.push(`/borrow/detail/${item.borrow_form_id}`); }}
                  >
                    {item.borrow_form_id}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '33.33%' }}
                    onClick={() => { history.push(`/borrow/detail/${item.borrow_form_id}`); }}
                  >
                    {item.item_name}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '33.33%' }}
                    onClick={() => { history.push(`/borrow/detail/${item.borrow_form_id}`) }}
                  >
                    {item.item_model}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '33.33%' }}
                    onClick={() => { history.push(`/borrow/detail/${item.borrow_form_id}`) }}
                  >
                    {item.total_price}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'right', paddingRight: '40px', width: i18n.language === 'zh' ? '150px' : '155px' }}
                    onClick={() => { history.push(`/borrow/detail/${item.borrow_form_id}`) }}
                  >
                    {item.borrow_time}
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {
        showDeleteModal && <ConfirmModal isShow={showDeleteModal} hideModal={() => {
          setShowDeleteModal(false)
        }}
          onOk={deleteBorrow}
          title={t('Please confirm')}
          description={t('Are you going to delete it?')}
        />
      }
      {loading && <FullLoadingSpinner />}
    </Box >)
}

export default BorrowSheets;
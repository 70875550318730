import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  makeStyles,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tableContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    backgroundColor: '#EFF1F8',
    padding: '16px 16px',
    borderRadius: theme.spacing(1),
    margin: '8px 0',
    '& .table-header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& .MuiTypography-subtitle1': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 15,
      lineHeight: '18px',
      color: '#3C54B9',
    },
    '& .MuiTableContainer-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    '& .table-footer': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1),
      '& .table-footer-item': {
        display: 'flex',
        flexDirection: 'column',
        marginRight: theme.spacing(2),
        '& .MuiTypography-subtitle2': {
          marginTop: theme.spacing(1)
        }
      }
    }
  },
  tableContainer: {
    marginTop: 8,
    maxHeight: 331,
    '& .MuiTableCell-head': {
      border: '1px solid rgba(0, 0, 0, 0.87)',
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
    '& .MuiTableCell-body': {
      border: '1px solid rgba(0, 0, 0, 0.87)'
    }
  },
  tablePagination: {
    '& .MuiToolbar-regular': {
      paddingLeft: 0
    },
    '& .MuiTablePagination-selectRoot': {
      marginRight: 16
    }
  }
}))

const ItemListTableMessage = ({ messageContent, clickRow }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tables = [];
  Object.keys(messageContent.content).forEach((item, idx) => {
    const tableItem = messageContent.content[item];
    tables.push(<Box className={classes.tableContentWrapper} key={idx}>
      <TableContainer>
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[5, 10, 25]}
          count={tableItem.item_list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          component="div"
        />

        <Table className={classes.tableContainer}>
          <TableHead>
            <TableRow>
              {tableItem.attributes.map((itemAttribute, idxOne) => {
                const attributeType = typeof itemAttribute;
                if (attributeType === 'string') {
                  return <TableCell key={idxOne}>{itemAttribute}</TableCell>;
                } else if (attributeType === 'object') {
                  const tableHeader = [];
                  itemAttribute.forEach((itemFormAttribute, idxTwo) => {
                    if (itemFormAttribute !== 'form_id')
                      tableHeader.push(<TableCell key={idxOne + idxTwo}>{itemFormAttribute}</TableCell>)
                  })
                  return tableHeader;
                } else {
                  return <TableCell>{itemAttribute}</TableCell>;
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableItem.item_list.map((itemRow, idxOne) => {
              if (idxOne >= (page + 1) * rowsPerPage || idxOne < page * rowsPerPage)
                return null;

              return <TableRow hover={true} onClick={() => {
                clickRow(itemRow[0].toString())
              }} key={idxOne} style={{ cursor: 'pointer' }}>
                {itemRow.map((itemCell, idxTwo) => {
                  const attributeType = typeof (itemCell);
                  if (attributeType === 'string') {
                    return <TableCell key={idxTwo}>{itemCell}</TableCell>
                  } else if (attributeType === 'object') {
                    const tableCell = [];
                    itemCell.forEach((itemFormCell, idxThree) => {
                      if (idxThree > 0)
                        tableCell.push(<TableCell key={idxThree}>{itemFormCell}</TableCell>)
                    })
                    return tableCell;
                  } else {
                    return <TableCell>{itemCell}</TableCell>
                  }
                })}
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>)
  })

  return (<>{tables}</>)
}

export default React.memo(ItemListTableMessage);
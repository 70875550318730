import axiosInstance from '../lib/axios';

export const getNextFormIdAPI = (userId) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.get(`/get_next_form_id?id=${userId}`).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const saveStockInAPI = (stockInData) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post('/stock_in', stockInData).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
      console.log("Save StockIn API:");
      console.log(err);
    }
  })
}

export const submitStockInAPI = (stockinId) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post(`/submit_table?table_name=stock_in&form_id=${stockinId}`).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
        console.log(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const getShowAllStockInFormsAPI = () => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.get('/show_all_stock_in_forms').then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const getStockInFormAPI = (stockInFormId) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.get(`/get_stock_in_form?stock_in_form_id=${stockInFormId}`).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const deleteStockInFormAPI = (stockinFormIds) => {
  return new Promise((resolve, reject) => {
    try {
      const axiosArr = [];
      stockinFormIds.forEach(item => {
        axiosArr.push(axiosInstance.get(`/delete_stock_in_form?stock_in_form_id=${item}`))
      })
      Promise.all(axiosArr).then(responses => {
        resolve(true)
      }).catch(err => {
        reject(false)
      })
    } catch (err) {
      reject(err)
    }
  })
}
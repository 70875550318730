import React from "react";
import { useRecoilValue } from "recoil";
import { Route, Redirect } from "react-router-dom";

import { authState } from "../atoms";
import { routes } from "../constants";

import Root from '../layouts/Root';

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;

  const localAuth = localStorage.getItem('auth');

  const render = props => {
    if (!localAuth) {
      return <Redirect to={routes.LOGIN} />;
    }

    return <Root><Component {...props} /></Root>;
  };

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menuWrapper: {
    maxHeight: '320px',
    overflowY: 'auto',
    zIndex: 1,
  },
  columnCheckBoxControl: {
    width: '100%',
    margin: 0,
    '& .MuiTypography-body1': {
      textTransform: 'capitalize'
    }
  }
}));

const TableFilterDropdown = ({ filterList = [], setFilterColumn }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        Filter&nbsp;
        <FilterListIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
        style={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center top' }}
          >
            <Paper className={classes.menuWrapper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {filterList.map((item, idx) => {
                    return <MenuItem key={idx}>
                      <FormControlLabel
                        className={classes.columnCheckBoxControl}
                        control={<Checkbox color="primary" checked={item.checked} onChange={(e) => {
                          setFilterColumn({ ...item, checked: !item.checked })
                        }} />}
                        label={t(item.name)}
                      />
                    </MenuItem>
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default TableFilterDropdown;
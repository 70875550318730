import React, { useState, useEffect } from 'react';
import { useRecoilValue } from "recoil";
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Box,
  TextField,
  Popover,
  Button,
  IconButton,
  Paper,
  MenuItem,
} from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import SendButton from './SendButton';

import { authState } from '../../../atoms';

import {
  QUESTION_TYPE,
  MESSAGE_OWNER,
  USER_ROLE,
  getChatQuickAnswers,
} from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(1)}px`,
  },
  controlWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid #C8D5E4',
    padding: '12px 0',
    '& .instance-btn': {
      marginRight: '1rem',
      backgroundColor: '#2996d6',
    }
  },
  dumpDropdownWrapper: {
    width: 313,
    maxHeight: 160,
    background: 'linear-gradient(180deg, #FFFFFF 0%, #DEDEDE 161.63%)',
    border: '1px solid #E3E8F2',
    overflow: 'hidden',
  },
  dumpMenuItem: {
    height: 40,
    fontFamily: 'SimSun !important',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24',
    '&:focus': {
      color: 'white',
      fontWeight: 'bold',
      background: 'linear-gradient(180deg, #3C54B9 0%, #283D98 100%)',
    },
  },
  iconButton: {
    padding: 8
  },
  inputWrapper: {
    display: 'flex',
  },
  inputSentence: {
    flex: '1 1 auto',
    fontFamily: 'SimSun !important',
    '& .MuiOutlinedInput-multiline': {
      paddingTop: 10,
      paddingBottom: 10,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2996d6'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2996d6'
    }
  },
}));

const SentenceInput = ({ chatList = [], question, sendAnswer, disabled = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useRecoilValue(authState);

  const [sentence, setSentence] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowPos, setArrowPos] = useState(-1);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setArrowPos(-1)
  }, [chatList])

  const sendAnswerToList = (sendSentence) => {
    sendAnswer(sendSentence);
    setSentence('');
    setArrowPos(-1);
  }

  const onClickDump = (dumpAnswer) => {
    handleClose();
    sendAnswerToList(dumpAnswer);
  };

  const getSendButtonStatus = () => {
    if (question.type === QUESTION_TYPE.TEXT) return sentence.length > 0;
    else if (question.type === QUESTION_TYPE.BUTTON) {
      if (sentence.length > 0) {
        let findOne = false;
        Object.keys(question.content).forEach((item) => {
          if (!findOne)
            if (
              question.content[item].text.toLowerCase() ===
              sentence.toLowerCase()
            ) {
              findOne = true;
            }
        });
        return findOne;
      } else return false;
    }
    return true;
  };

  const onKeyDownSentence = (event) => {
    if (event.key === 'Enter') {
      if (getSendButtonStatus()) {
        event.preventDefault();
        sendAnswerToList(sentence);
      }
    }
  };

  const onKeyUpSentence = (event) => {
    if (event.key === "ArrowUp" || event.key === 'ArrowDown') {
      if (chatList.length > 1) {
        const possibleSelect = [];
        for (let i = chatList.length - 1; i >= 1; i--) {
          if (chatList[i].owner === MESSAGE_OWNER.SENDER && chatList[i].type === QUESTION_TYPE.TEXT) {
            possibleSelect.push(chatList[i].content)
          }
        }

        if (event.key === 'ArrowUp') {
          if (arrowPos < possibleSelect.length) {
            setSentence(possibleSelect[arrowPos + 1]);
            setArrowPos(arrowPos + 1);
          } else
            setSentence(possibleSelect[arrowPos]);
        } else if (event.key === 'ArrowDown' && arrowPos >= 1) {
          setSentence(possibleSelect[arrowPos - 1]);
          setArrowPos(arrowPos - 1);
        }
      }
    }
  };

  const onBlur = (event) => {
    setArrowPos(-1)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.controlWrapper}>
        {auth && auth.role === USER_ROLE.LEADER &&
          <Button
            className="instance-btn"
            size="small"
            variant="contained"
            color="primary"
            onClick={() => { sendAnswerToList('审批申请') }}
          >
            审批申请
          </Button>
        }
        {auth.role === USER_ROLE.ADMIN && <>
          <Button
            className="instance-btn"
            size="small"
            variant="contained"
            color="primary"
            onClick={() => { sendAnswerToList('办理出库') }}
          >
            办理出库
          </Button>
          <Button
            className="instance-btn"
            size="small"
            variant="contained"
            color="primary"
            onClick={() => { sendAnswerToList('办理退回') }}
          >
            办理退回
          </Button>
        </>}
        {auth.role === USER_ROLE.EMPLOYEE && <Button
          className="instance-btn"
          size="small"
          variant="contained"
          color="primary"
          onClick={() => { sendAnswerToList('我要申请') }}
        >
          我要申请
        </Button>}
        <Button
          className="instance-btn"
          size="small"
          variant="contained"
          color="primary"
          onClick={() => { sendAnswerToList('停止') }}
        >
          停止
        </Button>
        {/* <IconButton
          className={classes.iconButton}
          aria-describedby={id}
          variant='contained'
          color='primary'
          onClick={handleClick}
          disabled={question.type === QUESTION_TYPE.BUTTON || disabled}
        >
          <MessageIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {auth && <Paper className={classes.dumpDropdownWrapper}>
            {getChatQuickAnswers(auth.role).map((item, idx) => {
              return (
                <MenuItem
                  className={classes.dumpMenuItem}
                  onClick={() => {
                    onClickDump(item);
                  }}
                  key={idx}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Paper>}
        </Popover> */}
      </Box>
      <Box className={classes.inputWrapper}>
        <TextField
          className={classes.inputSentence}
          multiline
          placeholder={t('enter your message')}
          rowsMax={4}
          value={sentence}
          onChange={(event) => {
            setSentence(event.target.value);
          }}
          onKeyDown={onKeyDownSentence}
          onKeyUp={onKeyUpSentence}
          onBlur={onBlur}
          variant='outlined'
          disalbed={disabled}
        />
        <SendButton
          disabled={disabled || !getSendButtonStatus()}
          onClick={() => {
            sendAnswerToList(sentence);
          }}
        />
      </Box>
    </Box>
  );
};

export default React.memo(SentenceInput);

import axiosInstance from '../lib/axios';
import { USER_ROLE } from '../constants';

export const getMessageAPI = async ({ userRole, userId, count = 0 }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apiURL = 'show_msgs?role=l';
      if (userRole === USER_ROLE.LEADER)
        apiURL = 'show_msgs?role=l';
      else if (userRole === USER_ROLE.ADMIN)
        apiURL = '/show_msgs?role=a';
      else if (userRole === USER_ROLE.EMPLOYEE)
        apiURL = `/show_msgs?id=${userId}&role=e`;

      if (count > 0) apiURL = apiURL + `&count=${count}`;
      const response = await axiosInstance.get(apiURL);
      resolve(response.data.data);
    } catch (err) {
      reject(err);
    }
  })
}
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
	makeStyles,
	Typography,
	Box,
	IconButton,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel,
	TextField,
	Checkbox
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms'
import AccountModal from './AccountModal';
import ConfirmModal from '../../components/ConfirmModal';
import FullLoadingSpinner from '../../components/FullLoadingSpinner';
import { getAllAccountsAPI, createAccountsAPI, updateLeaderAccountsAPI, deleteAccountAPI } from '../../apis';
import { routes, USER_ROLE } from '../../constants'

const useStyles = makeStyles((theme) => ({
	root: {}
}))

const Accounts = () => {
	const { t } = useTranslation();

	const history = useHistory();
	const auth = useRecoilValue(authState);

	const [loading, setLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState({
		user_id: -1,
		first_name: '',
		last_name: '',
		user_name: '',
		group_name: 'admin'
	})
	const [accountList, setAccountList] = useState([]);
	const [selectedRows, setSelectedRows] = useState([])

	let componentMounted = true;
	useEffect(() => {
		if (!!auth && auth.role !== USER_ROLE.LEADER) {
			history.push(routes.HOME)
		} else if (auth && auth.role === USER_ROLE.LEADER) {
			getAllAccountsAPI().then(res => {
				if (componentMounted) {
					setAccountList([...res]);
					setLoading(false);
				}
			}).catch(err => {
				if (componentMounted) {
					setAccountList([]);
					setLoading(false)
				}
			})
		}
		return () => { componentMounted = false; }
	}, [auth])

	const [orderBy, setOrderBy] = useState({
		name: '',
		value: 'asc'
	})

	const getSortedData = () => {
		if (!orderBy.name) return accountList;
		if (orderBy.name === 'group_name' && orderBy.value) {
			return accountList.sort((a, b) => {
				const orderValue = orderBy.value === 'desc' ? -1 : 1;
				if (b.group_name > a.group_name) return -1 * orderValue;
				if (b.group_name < a.group_name) return 1 * orderValue;
				else return 0;
			})
		} else if (orderBy.name === 'status' && orderBy.value) {
			return accountList.sort((a, b) => {
				const orderValue = orderBy.value === 'desc' ? -1 : 1;
				if (b.status > a.status) return -1 * orderValue;
				if (b.status < a.status) return 1 * orderValue;
				else return 0;
			})
		}
	}

	const createAccount = async (accountInfo) => {
		setLoading(true);
		if (accountInfo.user_id === -1) {
			try {
				const res = await createAccountsAPI({
					account: [{
						first_name: accountInfo.first_name,
						last_name: accountInfo.last_name,
						user_name: accountInfo.user_name,
						manager_name: `${accountInfo.first_name} ${accountInfo.last_name}`,
						group_name: accountInfo.group_name,
						status: 'active',
					}]
				})
				setAccountList([
					...accountList,
					{
						user_id: res.data.user_id,
						first_name: accountInfo.first_name,
						last_name: accountInfo.last_name,
						user_name: accountInfo.user_name,
						manager_name: `${accountInfo.first_name} ${accountInfo.last_name}`,
						group_name: accountInfo.group_name,
						status: 'active',
					}
				])
				setLoading(false);
				Swal.fire({
					title: t('Success'),
					text: t('Account has been created'),
					icon: 'success',
					confirmButtonText: t('Ok')
				})
			} catch (err) {
				setLoading(false);
				Swal.fire({
					title: `${t('Error')}!`,
					text: t('Create account has been failed'),
					icon: 'error',
					confirmButtonText: t('Ok')
				})
			}
		} else {
			try {
				await updateLeaderAccountsAPI({
					account: [{
						id: accountInfo.user_id,
						first_name: accountInfo.first_name,
						last_name: accountInfo.last_name,
						user_name: accountInfo.user_name,
						manager_name: `${accountInfo.first_name} ${accountInfo.last_name}`,
						group_name: accountInfo.group_name,
						status: accountInfo.status,
					}]
				})
				setAccountList([
					...accountList.map(item => {
						if (item.user_id === accountInfo.user_id) {
							return accountInfo;
						} else return item;
					})
				])
				setSelectedRows([{
					...accountInfo
				}])
				setLoading(false);
				Swal.fire({
					title: t('Success'),
					text: t('Account has been updated'),
					icon: 'success',
					confirmButtonText: t('Ok')
				})
			} catch (err) {
				setLoading(false);
				Swal.fire({
					title: `${t('Error')}!`,
					text: t('Update account has been failed'),
					icon: 'error',
					confirmButtonText: t('Ok')
				})
			}
		}
	}

	const formatSelectedAccount = () => {
		setSelectedAccount({
			user_id: -1,
			first_name: '',
			last_name: '',
			user_name: '',
			group_name: 'admin'
		})
	}

	const changeAccountStatus = async (event, account) => {
		setLoading(true)
		const prevStatus = account.status;
		try {
			setAccountList([...accountList.map(itemOne => {
				if (account.user_id === itemOne.user_id) return { ...itemOne, status: event.target.value };
				else return itemOne
			})])

			const updateAccountTemp = { ...account };
			delete updateAccountTemp.user_id
			const res = await updateLeaderAccountsAPI({
				account: [{
					...updateAccountTemp,
					id: account.user_id,
					status: event.target.value
				}]
			});
			if (res.data.status === 'success') {
				console.log('Change account status success');
				Swal.fire({
					title: t('Success'),
					text: t('Account has been updated'),
					icon: 'success',
					confirmButtonText: t('Ok')
				})
				setSelectedRows([
					...selectedRows.map(item => {
						if (item.user_id === account.user_id) {
							return { ...account, status: event.target.value }
						} else return item;
					})
				])
			} else {
				setAccountList([...accountList.map(itemOne => {
					if (account.user_id === itemOne.user_id) return { ...itemOne, status: prevStatus };
					else return itemOne
				})])
				Swal.fire({
					title: `${t('Error')}!`,
					text: t('Update account has been failed'),
					icon: 'error',
					confirmButtonText: t('Ok')
				})
			}
		} catch (err) {
			console.log('Change account status API:');
			console.log(err);
			setAccountList([...accountList.map(itemOne => {
				if (account.user_id === itemOne.user_id) return { ...itemOne, status: prevStatus };
				else return itemOne
			})])
		}
		setLoading(false);
	}

	const onSelectAllClick = (event) => {
		if (event.target.checked)
			setSelectedRows([...accountList]);
		else setSelectedRows([])
	}

	const deleteRows = async () => {
		setLoading(true);
		setShowDeleteModal(false);
		const selectedIDs = selectedRows.map(item => item.user_id);
		deleteAccountAPI(selectedIDs).then(res => {
			setAccountList([
				...accountList.filter(item => selectedIDs.indexOf(item.user_id) < 0)
			])
			setSelectedRows([]);
			setLoading(false);
			Swal.fire({
				title: t('Success'),
				text: t('Selected accounts has been deleted'),
				icon: 'success',
				confirmButtonText: t('Ok')
			})
		}).catch(err => {
			setLoading(false);
			Swal.fire({
				title: `${t('Error')}!`,
				text: t('Delete accounts has been failed'),
				icon: 'error',
				confirmButtonText: t('Ok')
			})
		})
	}

	return (
		<Box className="main-page-content">
			<Box className="page-title-wrapper">
				<Typography variant="h3" className="page-title">{t('Manage Accounts')}</Typography>
				<IconButton
					onClick={() => {
						setShowModal(true)
					}}
					style={{ marginLeft: 'auto' }}
				>
					<AddIcon color="primary" />
				</IconButton>
				<IconButton
					onClick={() => {
						setSelectedAccount({ ...selectedRows[0] })
						setShowModal(true)
					}}
					disabled={selectedRows.length > 1 || selectedRows.length === 0}
				>
					<CreateOutlinedIcon
						color={selectedRows.length > 1 || selectedRows.length === 0 ? '' : "primary"}
					/>
				</IconButton>
				<IconButton
					onClick={() => {
						setShowDeleteModal(true);
					}}
					disabled={selectedRows.length === 0}
				>
					<DeleteForeverOutlinedIcon color={selectedRows.length === 0 ? '' : 'error'} />
				</IconButton>
			</Box>
			<Box className="darin-table-panel">
				<TableContainer className="darin-table-container">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: 40, textAlign: 'left' }}>
									<Checkbox
										className="table-checkbox"
										color="primary"
										checked={selectedRows.length === accountList.length}
										onChange={onSelectAllClick}
										inputProps={{ 'aria-label': 'select all desserts' }}
									/>
								</TableCell>
								<TableCell style={{ textAlign: 'center' }}>{t('Last Name')}</TableCell>
								<TableCell style={{ textAlign: 'center' }}>{t('First Name')}</TableCell>
								<TableCell style={{ textAlign: 'center' }}>{t('User Name')}</TableCell>
								<TableCell style={{ textAlign: 'center' }}>
									<TableSortLabel
										active={orderBy.name === 'group_name'}
										direction={orderBy.name === 'group_name' ? orderBy.value : 'asc'}
										onClick={() => {
											if (orderBy.name === 'group_name') {
												if (orderBy.value === 'desc')
													setOrderBy({
														name: 'group_name',
														value: 'asc'
													})
												else
													setOrderBy({
														name: 'group_name',
														value: 'desc'
													})
											} else {
												setOrderBy({
													name: 'group_name',
													value: 'asc'
												})
											}
										}}
									>
										{t('Group')}
									</TableSortLabel>
								</TableCell>
								<TableCell style={{ textAlign: 'center', width: '180px' }}>
									<TableSortLabel
										active={orderBy.name === 'status'}
										direction={orderBy.name === 'status' ? orderBy.value : 'asc'}
										onClick={() => {
											if (orderBy.name === 'status') {
												if (orderBy.value === 'desc')
													setOrderBy({
														name: 'status',
														value: 'asc'
													})
												else
													setOrderBy({
														name: 'status',
														value: 'desc'
													})
											} else {
												setOrderBy({
													name: 'status',
													value: 'asc'
												})
											}
										}}
									>
										{t('Status')}
									</TableSortLabel>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{getSortedData().map((item, idx) => {
								return <TableRow key={idx}>
									<TableCell style={{ width: 40, textAlign: 'left' }}>
										<Checkbox
											className="table-checkbox"
											checked={selectedRows.map(item => item.user_id).indexOf(item.user_id) >= 0}
											onChange={(event) => {
												if (event.target.checked)
													setSelectedRows([...selectedRows, item]);
												else
													setSelectedRows([...selectedRows.filter(itemSelected => itemSelected.user_id !== item.user_id)])
											}}
											inputProps={{ 'aria-label': 'primary checkbox' }}
											color="primary"
										/>
									</TableCell>
									<TableCell style={{ textAlign: 'center' }}>{item.last_name}</TableCell>
									<TableCell style={{ textAlign: 'center' }}>{item.first_name}</TableCell>
									<TableCell style={{ textAlign: 'center' }}>{item.user_name}</TableCell>
									<TableCell style={{ textAlign: 'center', paddingRight: 40 }}>{t(item.group_name)}</TableCell>
									<TableCell style={{ textAlign: 'right', paddingRight: 40 }}>
										<TextField
											id="status-select"
											select
											label=""
											value={item.status}
											onChange={(event) => {
												changeAccountStatus(event, item)
											}}
											SelectProps={{ native: true }}
											variant="outlined"
											size="small"
										>
											<option key="active" value="active">{t('Active')}</option>
											<option key="deactived" value="deactived">{t('Deactivated')}</option>
										</TextField>
									</TableCell>
								</TableRow>
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
			{showModal && <AccountModal
				isShow={showModal}
				accountInfo={selectedAccount}
				createAccount={createAccount}
				hideModal={() => {
					setShowModal(false)
					formatSelectedAccount();
				}}
				setLoading={setLoading}
			/>
			}
			{showDeleteModal && <ConfirmModal
				isShow={showDeleteModal}
				hideModal={() => {
					setShowDeleteModal(false)
					formatSelectedAccount();
				}}
				onOk={deleteRows}
				title={t('Please confirm')}
				description={`${t('Are you going to delete it?')}`}
			/>}
			{loading && <FullLoadingSpinner />}
		</Box>
	)
}

export default Accounts;
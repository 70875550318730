import {
  makeStyles,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const FullLoadingSpinner = () => {
  const classes = useStyles();

  return (<Backdrop className={classes.backdrop} open={true}>
    <CircularProgress color="primary" />
  </Backdrop>
  )
}

export default FullLoadingSpinner;
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from '../../../atoms';
import Swal from 'sweetalert2';
import {
  makeStyles,
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Tooltip,
  IconButton,
  Snackbar,
  Checkbox,
  Breadcrumbs,
  Link,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import SaveIcon from '@material-ui/icons/Save';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import ConfirmModal from '../../../components/ConfirmModal';
import FullLoadingSpinner from '../../../components/FullLoadingSpinner';
import RecordModal from './RecordModal';
import { getNextFormIdAPI, saveStockInAPI, getStockInFormAPI } from '../../../apis';
import { useInterval } from '../../../hooks';
import { routes } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  gridContainer: {
    border: '1px solid lightgrey',
    padding: '20px 30px',
    backgroundColor: 'white',
    marginBottom: 70,
    position: 'relative',
  },
  formGroup: {
    display: 'flex',
    '& .MuiTypography-subtitle1': {
      margin: '0 10px 0 0',
      padding: '5px 0 0 0',
      width: 165,
      flex: '0 0 165px',
      textAlign: 'left'
    },
    '& .MuiFormControl-root': {
      flex: '1 1 100%',
    }
  },
  rowTableContainer: {
    '& .MuiTableCell-head': {
      fontWeight: '600 !important',
      paddingTop: 4,
      paddingBottom: 4,
      border: 'none',
      fontSize: '0.9rem !important',
    },
    '& .MuiTableCell-root': {
      textAlign: 'center',
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: '1rem',
      fontWeight: 'normal'
    }
  },
  tableCheck: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  tableActions: {
    right: '40px',
    display: 'flex',
    alighItems: 'center',
    position: 'absolute',
    left: 0,
    bottom: '100%',
  },
  snackbar: {
    left: 250
  }
}));

const StockIn = () => {

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { stock_in_form_id } = useParams();
  const auth = useRecoilValue(authState);
  const history = useHistory();

  const recordTableHeaders = [
    {
      label: t('Stock Id'),
      key: 'row_num',
      width: '10%'
    },
    {
      label: t('Name'),
      key: 'name',
      width: 'auto'
    },
    {
      label: t('Model'),
      key: 'model',
      width: '10%'
    },
    {
      label: t('Price'),
      key: 'price',
      width: '10%'
    },
    {
      label: t('Quantity'),
      key: 'quantity',
      width: '10%'
    },
    {
      label: t('Total Price'),
      key: 'total_price',
      width: '10%'
    },
    {
      label: t('Item Id'),
      key: 'item_id',
      width: 'auto'
    },
    {
      label: t('Notes'),
      key: 'notes',
      width: '10%'
    }
  ]

  const [loading, setLoading] = useState(true);
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setFormData] = useState({
    stock_form_id: '',
    contract_name: '',
    contract_id: '',
    contract_company: '',
    row_data: [],
    leader_name: '',
    leader_date: null,
    admin_name: '',
    admin_date: null
  })
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);

  let componentMounted = true;

  useEffect(() => {
    return () => { componentMounted = false; }
  }, [])

  useEffect(() => {
    if (!auth) return;
    if (stock_in_form_id) {
      getStockInFormAPI(stock_in_form_id).then(res => {
        if (componentMounted) {
          setLoading(false);
          const returnData = res.data;
          setFormData({
            stock_form_id: returnData.stock_form_id,
            contract_name: returnData.data.contract_name,
            contract_id: returnData.data.contract_id,
            contract_company: returnData.data.contract_company,
            row_data: returnData.data.row_data,
            leader_name: returnData.data.leader_name,
            leader_date: returnData.data.leader_date ? moment(returnData.data.leader_date) : null,
            admin_name: returnData.data.admin_name,
            admin_date: returnData.data.admin_date ? moment(returnData.data.admin_date) : null
          })
        }
      }).catch(err => {
        if (componentMounted) {
          setLoading(false);
          setFormData({
            ...formData,
            stock_form_id: stock_in_form_id
          })
        }
      })
    } else {
      getNextFormIdAPI(auth.id).then(res => {
        if (componentMounted) {
          setLoading(false);
          setFormData({
            ...formData,
            stock_form_id: res.data.stock_form_id
          })
        }
      }).catch(err => {
        console.log('Get StockIn Api Error:');
        console.log(err);
        if (componentMounted) {
          setLoading(false);
        }
      })

    }
  }, [auth])


  const formatFormData = () => {
    return {
      owner_id: auth.id,
      stock_form_id: formData.stock_form_id,
      data: {
        contract_name: formData.contract_name || null,
        contract_id: formData.contract_id || null,
        contract_company: formData.contract_company || null,
        row_data: [...formData.row_data],
        leader_name: formData.leader_name,
        leader_date: formData.leader_date ? moment(formData.leader_date).format('YYYY-MM-DD') : null,
        admin_name: formData.admin_name || null,
        admin_date: formData.admin_date ? moment(formData.admin_date).format('YYYY-MM-DD') : null
      }
    }
  }

  useInterval(
    () => {
      saveStockInAPI(formatFormData()).then(res => {
        if (res.data.status === 'success')
          setShowSnackbar(true)
      })
    },
    30000,
  )

  const addRecord = (newRecord) => {
    if (newRecord.row_num === -1) {
      setFormData({
        ...formData,
        row_data: [
          ...formData.row_data.map((item, idx) => {
            return { ...item, row_num: idx + 1 }
          }),
          {
            ...newRecord,
            row_num: formData.row_data.length + 1
          }
        ]
      })
      setSelectedRecords([
        ...selectedRecords.filter(item => item.row_num !== -1)
      ])
    } else {
      setFormData({
        ...formData,
        row_data: [...formData.row_data.map(item => {
          if (item.row_num === newRecord.row_num)
            return newRecord;
          else return item;
        })]
      })
      setSelectedRecords([...selectedRecords.map(item => {
        if (item.row_num === newRecord.row_num)
          return newRecord;
        else return item;
      })])
    }
    setShowRecordModal(false)
  }

  const deleteRecord = () => {
    const deletedIds = selectedRecords.map(item => item.row_num)
    setFormData({
      ...formData,
      row_data: [...formData.row_data.filter(item => !deletedIds.includes(item.row_num)).map((item, idx) => { return { ...item, row_num: idx + 1 } })]
    })
    setSelectedRecords([])
    setShowDeleteModal(false)
  }

  const onSelectAllClick = (event) => {
    if (event.target.checked)
      setSelectedRecords([...formData.row_data]);
    else setSelectedRecords([])
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const getRecordForModal = () => {
    const findOne = selectedRecords.find(item => item.row_num === -1)
    if (findOne) {
      return findOne;
    } else return selectedRecords[0];
  }

  const getRecordCheckValue = (recordId) => {
    const findOne = selectedRecords.find(item => item.row_num === recordId)
    if (findOne) return true;
    else return false;
  }

  const goBack = () => {
    Swal.fire({
      title: t('Do you want to save the changes?'),
      showCancelButton: true,
      cancelButtonText: t('No'),
      showConfirmButton: true,
      confirmButtonText: t('Yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        saveStockInAPI(formatFormData()).then(res => {
          history.push(`/inventory/stockin/${formData.stock_form_id}`)
        }).catch(err => {
          setLoading(false);
        })
      } else {
        history.push(`/inventory/stockin/${formData.stock_form_id}`)
      }
    });
  }

  return (
    <Box className="main-page-content">
      <Grid container>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Breadcrumbs className="darin-breadcrumbs">
            <Link onClick={() => { history.push(routes.STOCKIN_SHEETS) }} color="inherit">{t('StockIn Sheets')}</Link>
            <Link color="inherit" onClick={goBack} style={{ cursor: 'pointer' }}>{t('FormID')}: {formData.stock_form_id}</Link>
            <Typography>{t('Edit')}</Typography>
          </Breadcrumbs>
          <Tooltip title={t('Save')}>
            <IconButton
              onClick={() => {
                setLoading(true);
                saveStockInAPI(formatFormData()).then(res => {
                  setLoading(false);
                  history.push(`/inventory/stockin/${formData.stock_form_id}`)
                }).catch(err => {
                  setLoading(false);
                })
              }}
              disabled={formData.submit}
            >
              <SaveIcon color={formData.submit ? '' : 'primary'} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('submit')}>
            <IconButton disabled={true}>
              <SendOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('download')}>
            <IconButton disabled={true}>
              <GetAppOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid xs={12}>
          <Typography variant="h3">{t('Godown Entry')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ textAlign: 'right', lineHeight: 1, marginBottom: '1rem' }}>
            {t('FormID')}: &nbsp;{formData.stock_form_id}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className='darin-panel-one' spacing={1}>
        <Grid item xs={12}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1" >{t('Contract Name')}:</Typography>
            <TextField
              label=""
              value={formData.contract_name}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  contract_name: e.target.value
                })
              }}
              size="small"
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1">{t('Contract ID')}:</Typography>
            <TextField
              label=""
              value={formData.contract_id}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  contract_id: e.target.value
                })
              }}
              size="small"
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1">{t('Contract Company')}:</Typography>
            <TextField
              label=""
              value={formData.contract_company}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  contract_company: e.target.value
                })
              }}
              size="small"
              variant="outlined"
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container className='darin-panel-one' spacing={1}>
        <Box className={classes.tableActions}>
          <IconButton
            color="primary"
            onClick={() => {
              setSelectedRecords([...selectedRecords, {
                row_num: -1,
                item_name: '',
                item_model: '',
                price: '',
                quantity: '',
                total_price: '',
                item_id: '',
                notes: ''
              }])
              setShowRecordModal(true);
            }}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowRecordModal(true)
            }}
            disabled={selectedRecords.length !== 1}
          >
            <CreateOutlinedIcon color="primary" />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowDeleteModal(true);
            }}
            disabled={selectedRecords.length < 1}
          >
            <DeleteForeverOutlinedIcon color="error" />
          </IconButton>
        </Box>
        <Grid item xs={12}>
          <TableContainer className={classes.rowTableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ paddingLeft: 0, width: 40, textAlign: 'left' }}>
                    <Checkbox
                      className={classes.tableCheck}
                      color="primary"
                      checked={selectedRecords.filter(item => item.row_num !== -1).length > 0 && selectedRecords.filter(item => item.row_num !== -1).length === formData.row_data.filter(item => item.row_num !== -1).length}
                      onChange={onSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                  </TableCell>
                  {recordTableHeaders.map((item, idx) => {
                    return <TableCell
                      key={item.key}
                      style={{ width: item.width }}
                    >
                      {item.label}
                    </TableCell>
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.row_data.length === 0 ? <TableRow>
                  <TableCell colSpan={9}><span style={{ color: 'grey' }}>{t("There's no record")}</span></TableCell>
                </TableRow> : <>
                  {formData.row_data.map((itemRow, idx) => {
                    return <TableRow key={idx}>
                      <TableCell style={{ paddingLeft: 0, width: 40, textAlign: 'left' }}>
                        <Checkbox
                          className={classes.tableCheck}
                          checked={getRecordCheckValue(itemRow.row_num)}
                          onChange={(event) => {
                            if (event.target.checked)
                              setSelectedRecords([...selectedRecords, itemRow]);
                            else
                              setSelectedRecords([...selectedRecords.filter(itemSelected => itemSelected.row_num !== itemRow.row_num)])
                          }}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>{itemRow.row_num}</TableCell>
                      <TableCell>{itemRow.item_name}</TableCell>
                      <TableCell>{itemRow.item_model || t('None')}</TableCell>
                      <TableCell>{itemRow.price}</TableCell>
                      <TableCell>{itemRow.quantity}</TableCell>
                      <TableCell>{itemRow.total_price}</TableCell>
                      <TableCell>{itemRow.item_id}</TableCell>
                      <TableCell>{itemRow.notes || t('None')}</TableCell>
                    </TableRow>
                  })}</>}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container className='darin-panel-one' spacing={1}>
        <Grid item xs={6} sm={4}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1" style={{ width: 125 }}>{t('Leader Name')}:</Typography>
            <TextField
              label=""
              value={formData.leader_name}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  leader_name: e.target.value
                })
              }}
              size="small"
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid item sm={4}></Grid>
        <Grid item xs={6} sm={4}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1" style={{ width: 125 }}>{t('Admin Name')}:</Typography>
            <TextField
              label=""
              value={formData.admin_name}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  admin_name: e.target.value
                })
              }}
              size="small"
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item sm={4} xs={6}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1" style={{ width: 125 }}>{t('Date')}:</Typography>
            <KeyboardDatePicker
              value={formData.leader_date}
              onChange={(newValue) => {
                setFormData({
                  ...formData,
                  leader_date: newValue ? moment(newValue) : null
                })
              }}
              format={i18n.language === 'zh' ? 'yyyy-MM-dd' : 'yyyy-MM-dd'}
              variant="inline"
              inputVariant="outlined"
              size="small"
              required
            />
          </Box>
        </Grid>
        <Grid item sm={4}></Grid>
        <Grid item sm={4} xs={6}>
          <Box className={classes.formGroup}>
            <Typography variant="subtitle1" style={{ width: 125 }}>{t('Date')}:</Typography>
            <KeyboardDatePicker
              value={formData.admin_date}
              onChange={(newValue) => {
                setFormData({
                  ...formData,
                  admin_date: newValue
                })
              }}
              format={i18n.language === 'zh' ? 'yyyy年MM月dd天' : 'yyyy-MM-dd'}
              variant="inline"
              inputVariant="outlined"
              size="small"
              required
            />
          </Box>
        </Grid>
      </Grid>

      {loading && <FullLoadingSpinner />}

      {
        showRecordModal && <RecordModal
          isShow={showRecordModal}
          recordInfo={getRecordForModal()}
          addRecord={addRecord}
          hideModal={() => {
            setShowRecordModal(false)
            setSelectedRecords([
              ...selectedRecords.filter(item => item.row_num !== -1)
            ])
          }} />
      }

      {
        showDeleteModal && <ConfirmModal
          isShow={showDeleteModal}
          hideModal={() => {
            setShowDeleteModal(false)
          }}
          onOk={deleteRecord}
          title={t('Please confirm')}
          description={`${t('Are you going to delete it?')}`}
        />
      }

      <Snackbar
        className={classes.snackbar}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity="success" elevation={6} variant="filled">
          {t('The form has been automatically saved')}
        </MuiAlert>
      </Snackbar>
    </Box >)
}

export default StockIn;
import axiosInstance from '../lib/axios';
import { USER_ROLE } from '../constants';

export const getAllVerificationFormsAPI = () => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.get('/show_all_verification_forms').then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const getNextVerificationFormIDAPI = () => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.get(`/get_next_verification_form_id`).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })

}

export const getVerificationFormAPI = (verificationId) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.get(`/get_verification_sheet?verification_form_id=${verificationId}`)
        .then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const saveVerificationFormAPI = (verificationFormData) => {
  return new Promise((resolve, reject) => {

    console.log(verificationFormData);
    try {
      axiosInstance.post('/update_verification_sheet', { ...verificationFormData }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const deleteVerificationFormAPI = (verificationIds) => {
  return new Promise((resolve, reject) => {
    try {
      const axiosArr = [];
      verificationIds.forEach(item => {
        axiosArr.push(axiosInstance.get(`/delete_verification_form?verification_form_id=${item}`))
      })
      Promise.all(axiosArr).then(responses => {
        resolve(true)
      }).catch(err => {
        reject(false);
      })
    } catch (err) {
      reject(err)
    }
  })
}
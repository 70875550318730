import moment from 'moment-timezone';

export const getPassedDate = (dateStr, getTimeStr) => {

  const oneMinute = 60 * 1000;
  const oneHour = 60 * 60 * 1000;
  const oneDay = 24 * 60 * 60 * 1000;
  const oneWeek = 7 * 60 * 60 * 1000;

  const utcOffset = moment().utcOffset();
  const dateValue = moment(dateStr).valueOf() - (8 - utcOffset / 60) * oneHour;
  const curDate = moment().utc();
  const curDateValue = curDate.valueOf();
  const diff = curDateValue - dateValue;

  if (diff < oneMinute)
    return getTimeStr('just now');

  else if (diff < oneHour) {
    const minutesValue = Math.floor(diff / oneMinute)
    return `${minutesValue} ${getTimeStr(minutesValue > 1 ? 'mins' : 'min')}${getTimeStr('ago')}`;
  } else if (diff < oneDay) {
    const hoursValue = Math.floor(diff / oneHour);
    const minutesValue = Math.floor(diff % oneHour / oneMinute);
    return `${hoursValue} ${getTimeStr('h')} ${minutesValue > 0 ? `${minutesValue} ${getTimeStr(minutesValue > 1 ? 'mins' : 'min')}` : ''}${getTimeStr('ago')}`;
  } else if (diff < oneDay * 2)
    return getTimeStr(`one day ago`);
  else if (diff < oneDay * 7)
    return `${Math.floor(diff / oneDay)} ${getTimeStr('days')}${getTimeStr('ago')}`;
  else if (diff < oneDay * 28) {
    const week = Math.floor(diff / (oneDay * 7));
    return `${week} ${week > 1 ? getTimeStr('weeks') : getTimeStr('week')}${getTimeStr('ago')}`;
  } else {
    const month = Math.floor(diff / (oneDay * 28));
    return `${month} ${month > 1 ? getTimeStr('months') : getTimeStr('month')}${getTimeStr('ago')}`;
  }
}

export const getChineseDayName = (dayNumber) => {
  switch (dayNumber) {
    case 1:
      return '星期一';
    case 2:
      return '星期二';
    case 3:
      return '星期三';
    case 4:
      return '星期四';
    case 5:
      return '星期五';
    case 6:
      return '星期六';
    case 0:
      return '星期日';
    default:
      return '星期一';
  }
}

export const chinaToLocal = (date) => {
  const dateValue = moment(date).utc().subtract(8, 'hours');
  return moment(dateValue.format('YYYY-MM-DD hh:mm')).local().format('YYYY-MM-DD hh:mm a')
}

export const getCurChinaTime = () => {
  return moment().utc().add(8, 'hours');
}

export const dateToYYYYMMDD = (date, locale = 'zh') => {
  try {
    if (locale == 'zh')
      return date.format('YYYY年MM月DD日')
    else
      return date.format('YYYY-MM-DD')
  } catch (err) {
    return ''
  }
}

export const YYYYMMDDtoFormat = (strDate, locale = 'zh') => {
  if (strDate.length < 6) return '-';
  if (locale === 'zh') {
    return strDate.substr(0, 4) + '年' + strDate.substr(4, 6) + '月';
  } else {
    return strDate.substr(0, 4) + '-' + strDate.substr(4, 6);
  }
}
import axiosInstance from '../lib/axios';
import { USER_ROLE } from '../constants';

export const getNewNotificationAPI = ({ userId, userType }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apiURL = '/exist_new_msgs?status=2breviewed';

      if (userType === USER_ROLE.LEADER || userType === USER_ROLE.ADMIN) {
        if (userType === USER_ROLE.LEADER)
          apiURL = `/exist_new_msgs?status=2breviewed&role=l`;
        if (userType === USER_ROLE.ADMIN)
          apiURL = '/exist_new_msgs?status=approved&role=a';

        const response = await axiosInstance.get(apiURL);
        if (typeof (response.data) === 'string')
          resolve([]);
        resolve(response.data.data);
      } else if (userType === USER_ROLE.EMPLOYEE) {
        apiURL = `/exist_new_msgs?id=${userId}&status=rejected&role=e`;
        const responseRejected = await axiosInstance.get(apiURL);
        apiURL = `/exist_new_msgs?id=${userId}&status=approved&role=e`;
        const responseApproved = await axiosInstance.get(apiURL);

        let returnData = [];
        if (responseRejected && responseRejected.data && responseRejected.data.data)
          returnData = [...responseRejected.data.data]
        if (responseApproved && responseApproved.data && responseApproved.data.data)
          returnData = [...returnData, ...responseApproved.data.data]
        resolve(returnData)
      }
    } catch (err) {
      reject(err);
    }
  })
}

export const getNotificationAPI = ({ userId, userType }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apiURL = '/exist_new_msgs?status=2breviewed';

      if (userType === USER_ROLE.LEADER || userType === USER_ROLE.ADMIN) {
        if (userType === USER_ROLE.LEADER)
          apiURL = `/exist_new_msgs?role=l`;
        if (userType === USER_ROLE.ADMIN)
          apiURL = '/exist_new_msgs?status=approved&role=a';

        const response = await axiosInstance.get(apiURL);
        if (typeof (response.data) === 'string')
          resolve([]);
        resolve(response.data.data);
      } else if (userType === USER_ROLE.EMPLOYEE) {
        apiURL = `/exist_new_msgs?id=${userId}&status=rejected`;
        const responseRejected = await axiosInstance.get(apiURL);
        apiURL = `/exist_new_msgs?id=${userId}&status=approved`;
        const responseApproved = await axiosInstance.get(apiURL);

        let returnData = [];
        if (responseRejected && responseRejected.data && responseRejected.data.data)
          returnData = [...responseRejected.data.data]
        if (responseApproved && responseApproved.data && responseApproved.data.data)
          returnData = [...returnData, ...responseApproved.data.data]
        resolve(returnData)
      }
    } catch (err) {
      reject(err);
    }
  })
}

export const notifiedNotificationAPI = (notificationIds = []) => {
  return new Promise(async (resolve, reject) => {
    try {
      const axiosArr = [];
      notificationIds.forEach(item => {
        axiosArr.push(axiosInstance.post(`/is_notified?msg_id=${item}`))
      })

      Promise.all(axiosArr).then(responses => {
        resolve(true)
      }).catch(err => {
        reject(false)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const readNotificationAPI = (notificationId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axiosInstance.post(`/read_msg?msg_id=${notificationId}`).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err)
    }
  })
}
import axiosInstance from '../lib/axios';
import { USER_ROLE } from '../constants';

export const showAppsAPI = ({ userRole = '', user_id = -1 }) => {
  return new Promise((resolve, reject) => {
    try {
      let apiURL = '/show_apps';
      if (userRole === USER_ROLE.EMPLOYEE)
        apiURL += `?id=${user_id}`;
      else if (userRole === USER_ROLE.ADMIN)
        apiURL += `?status=approved`;

      axiosInstance.get(apiURL)
        .then(res => {
          resolve(res.data.data)
        }).catch(err => {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const getApplicationAPI = ({ formId = 'all' }) => {
  return new Promise((resolve, reject) => {
    try {
      let apiURL = '/get_application';
      if (formId !== 'all') apiURL += `?form_id=${formId}`;

      axiosInstance.get(apiURL)
        .then(res => {
          resolve(res.data.data)
        }).catch(err => {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

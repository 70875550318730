import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authState } from "../../atoms";
import clsx from 'clsx';
import {
  makeStyles,
  Box,
  Fab,
  Grow,
  Typography,
  IconButton,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SimpleBar from 'simplebar-react';
import ChatListContainer from './ChatListContainer';
import SentenceInput from './SendInput/SentenceInput';
import ImageInput from './SendInput/ImageInput';
import { getQuestionAPI } from '../../apis';
import { MESSAGE_OWNER, QUESTION_TYPE, USER_ROLE } from '../../constants';
import { formatChat } from '../../utils';
import ExpandSvg from '../../assets/images/expand-white.svg';
import ZoomInSvg from '../../assets/images/zoomin-white.svg';
import GradSvg from '../../assets/images/grad-blue.svg';
import LogoImgPng from '../../assets/images/logo-img.png';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    right: 20,
    bottom: 20,
    height: 500,
    minHeight: 200,
    maxHeight: 'calc(100vh - 90px)',
    display: 'inline-block',
    flexDirection: 'column',
    overflow: 'visible',
    transition: 'width 0.5s',
    transitionTimingFunction: 'ease-in',
    zIndex: 1,
    '@media (min-width: 1600px)': {
      height: 600
    },
    '&.expanded': {
      height: 'calc(100vh - 90px)',
      '@media (min-width: 1600px)': {
        height: 'calc(100vh - 90px)',
      },
    }
  },
  chatBotContainer: {
    position: 'absolute',
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    width: 424,
    height: '100%',
    overflow: 'hidden',
    transition: 'width 0.2s ease-in',
    maxWidth: 'calc(100vw - 20px)',
    borderRadius: 20,
    background: 'linear-gradient(180deg, #DEDEDE -7.42%, #FFFFFF 5.86%)',
    '&.expanded': {
      width: 640,
      '@media (min-width: 1600px)': {
        width: 960
      }
    }
  },
  statusWrapper: {
    position: 'relative',
    flex: '0 0 50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `4px ${theme.spacing(1)}px 4px 25px`,
    '& .MuiTypography-h5': {
      fontWeight: 'bold',
      marginRight: 'auto',
      fontSize: 25,
      color: 'black',
      zIndex: 1,
    },
    '& .gradImg': {
      position: 'absolute',
      right: -150,
      top: 1,
      transition: 'right 0.2s ease-in',
      '&.expanded': {
        right: 0,
      },
    },
    '& .logo-img': {
      width: 'auto',
      height: '30px',
      marginRight: '0.5rem'
    }
  },
  statusButton: {
    color: 'white',
    padding: '4px',
    marginLeft: `${theme.spacing(1)}px`,
    fontFamily: 'SimSun !important'
  },
  chatBotWrapper: {
    padding: theme.spacing(1),
    width: '100%',
    flex: '1 1 100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    overflow: 'auto',
    '& > div': {
      boxSizing: 'border-box',
    },
  },
  scrollWrapper: {
    width: '100%',
    flex: '1 1 100%',
    overflow: 'hidden',
  },
  chatSimplebar: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
  },
  chatbotStickyFab: {
    position: 'fixed',
    bottom: 20,
    right: 20,
  },
}));

const Chatbot = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const auth = useRecoilValue(authState);

  const [loading, setLoading] = useState(false);
  const [showChatBox, setShowChatBox] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [chatList, setChatList] = useState([]);

  const scrollableNodeRef = useRef();

  let componentMounted = true;
  useEffect(() => {
    scrollableNodeRef.current.recalculate();
    updateChatList(null);

    return () => { componentMounted = false }
  }, []);

  useEffect(() => {
    if (showChatBox) {
      if (chatList.length === 0 || chatList[chatList.length - 1].content === '停止') {
        setLoading(true);
        getQuestionAPI({
          userType: auth.role,
          userId: auth.id,
          question: {
            type: "phrase",
            phrase: '开始',
            attempt: 1
          }
        }).then(res => {
          if (componentMounted) {
            setLoading(false);
            setChatList([
              ...chatList,
              ...res.data.data.map(questionItem => {
                return {
                  ...formatChat(questionItem),
                  owner: MESSAGE_OWNER.RECEIVED,
                }
              })
            ]);
            gotoScrollBottom();
          }
        }).catch(err => {
          if (componentMounted) setLoading(false)
        })
      }
    }
  }, [showChatBox])

  const showReplyBox = () => {

    if (chatList.length === 0) {
      return <SentenceInput
        chatList={chatList}
        question={{
          type: QUESTION_TYPE.TEXT,
          answer: '',
        }}
        sendAnswer={(answer) => {
          updateChatList({
            type: QUESTION_TYPE.TEXT,
            content: answer,
          });
        }}
      />
    }

    const question = chatList[chatList.length - 1];
    if (
      question.type === QUESTION_TYPE.TEXT ||
      question.type === QUESTION_TYPE.BUTTON ||
      question.type === QUESTION_TYPE.LIST
    )
      return (
        <SentenceInput
          chatList={chatList}
          question={chatList[chatList.length - 1]}
          sendAnswer={(answer) => {
            updateChatList({
              type: QUESTION_TYPE.TEXT,
              content: answer,
            });
          }}
          disabled={loading || (chatList.length > 0 && chatList[chatList.length - 1].owner === MESSAGE_OWNER.SENDER)}
        />
      );
    // else if (question.type === QUESTION_TYPE.BUTTON)
    //   return (
    //     <ButtonInput
    //       question={question}
    //       sendAnswer={(answer) => {
    //         updateChatList({
    //           type: QUESTION_TYPE.BUTTON,
    //           content: answer,
    //         });
    //       }}
    //     />
    //   );
    else if (question.type === QUESTION_TYPE.IMAGE)
      return (
        <ImageInput
          sendAnswer={(answer) => {
            updateChatList({
              type: QUESTION_TYPE.IMAGE,
              content: answer,
            });
          }}
        />
      );
    else {
      return null;
    }
  };

  const updateChatList = (answer) => {
    if (!answer) return;
    const chatListTemp = chatList;
    if (answer)
      chatListTemp.push({
        owner: MESSAGE_OWNER.SENDER,
        ...answer,
      });
    setChatList([...chatListTemp]);
    gotoScrollBottom();

    setLoading(true);
    getQuestionAPI({
      userType: auth.role,
      userId: auth.id,
      question: {
        type: "phrase",
        phrase: answer.content,
        attempt: 1
      }
    }).then((res) => {
      if (componentMounted) {
        setLoading(false);
        setChatList([...chatListTemp,
        ...res.data.data.map(questionItem => {
          return {
            ...formatChat(questionItem),
            owner: MESSAGE_OWNER.RECEIVED,
          }
        })]);
        gotoScrollBottom();
      }
    }).catch(err => {
      if (componentMounted) {
        setLoading(false);
      }
    })
  };

  const gotoScrollBottom = () => {
    setTimeout(() => {
      if (scrollableNodeRef && scrollableNodeRef.current) {
        scrollableNodeRef.current.getScrollElement().scrollTop =
          scrollableNodeRef.current.getContentElement().offsetHeight -
          scrollableNodeRef.current.getScrollElement().offsetHeight;
      }
    }, [200]);
  };

  return (
    <>
      <Grow in={showChatBox}>
        <Box className={clsx(classes.root, { expanded: expanded })}>
          <Box className={clsx(classes.chatBotContainer, { expanded: expanded })}>
            <Box className={classes.statusWrapper}>
              <img
                src={GradSvg}
                className={expanded ? 'gradImg expanded' : 'gradImg '}
                alt='grad'
              />
              <img src={LogoImgPng} className="logo-img" alt="logo" />
              <Typography variant='h5'>{t('chatbot')}</Typography>
              <IconButton
                className={classes.statusButton}
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                {expanded ? (
                  <img src={ZoomInSvg} alt='zoom out' />
                ) : (
                  <img src={ExpandSvg} alt='expand' />
                )}
              </IconButton>
              <IconButton
                className={classes.statusButton}
                onClick={() => {
                  setShowChatBox(false);
                }}
              >
                <RemoveIcon />
              </IconButton>
            </Box>
            <Box className={classes.chatBotWrapper}>
              <Box className={classes.scrollWrapper}>
                <SimpleBar
                  className={classes.chatSimplebar}
                  ref={scrollableNodeRef}
                >
                  <ChatListContainer
                    loading={loading}
                    chatList={chatList}
                    sendAnswer={updateChatList}
                  />
                </SimpleBar>
              </Box>
              {showReplyBox()}
            </Box>
          </Box>
        </Box>
      </Grow>
      <Grow in={!showChatBox}>
        <Fab
          className={classes.chatbotStickyFab}
          color='primary'
          aria-label='chatbot'
          onClick={() => {
            setShowChatBox(true);
          }}
        >
          <QuestionAnswerIcon />
        </Fab>
      </Grow>
    </>
  );
};

export default Chatbot;

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Box,
  Grow,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import SimpleBar from 'simplebar-react';
import { useRecoilValue } from "recoil";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FormTableMessage from './FormTableMessage';
import ItemListTableMessage from './ItemListTableMessage';
import AdminTableMessage from './AdminTableMessage';
import ListTableMessage from './ListTableMessage';
import TextLoadingIndicator from '../TextLoadingIndicator';
import { authState } from '../../atoms';
import { MESSAGE_OWNER, QUESTION_TYPE, USER_ROLE } from '../../constants/constants';
import { getTableType } from '../../utils';
import { TABLE_TYPE } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    '& .chat': {
      display: 'flex',
      flexDirection: 'column',
      margin: `${theme.spacing(1)}px 0`,

      '&.left': {
        paddingRight: '20%',
        alignItems: 'flex-start',
        fontSize: 15,
        fontWeight: 500,
        lineHeight: '18px',
        '& .chat-content': {
          textAlign: 'left',
          color: '#333',
          backgroundColor: 'rgb(246 249 255)',
          borderRadius: '0px 10px 10px 10px',
          padding: `10px 25px 10px 20px`,
        },
      },
      '&.right': {
        paddingLeft: '20%',
        alignItems: 'flex-end',
        '& .chat-content': {
          textAlign: 'left',
          color: 'white',
          background: '#2996d6',
          borderRadius: '0px 10px 10px 10px',
          padding: `10px 20px 10px 25px`,
          fontWeight: '800',
        },
      },
      '& .chat-content': {
        fontFamily: 'SimSun !important',
        display: 'inline-flex',
        flexDirection: 'column',
        maxWidth: '100%',
        boxShadow: `rgb(0 0 0 / 20%) 0px 3px 1px -2px, 
          rgb(0 0 0 / 14%) 0px 2px 2px 0px, 
          rgb(0 0 0 / 12%) 0px 1px 5px 0px`,
        overflowWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        '&.image-content': {
          padding: '2px !important',
          borderRadius: 4,
          '& img': {
            width: '100%',
            borderRadius: 4,
          },
          '& .MuiTypography-subtitle2': {
            fontSize: 12,
            margin: '4px 0 0 0',
          },
        },
      },
    },
  },
  buttonsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  answerButton: {
    margin: 4,
    border: '2px solid #2996d6 !important',
    fontWeight: 600,
    '& .MuiButton-label': {
      fontFamily: 'SimSun !important'
    }
  },
  listAnswerItemWrapper: {
    display: 'flex',
    height: 'auto',
    maxHeight: '200px',
    marginRight: '20%',
  },
  listAnswerItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    wordBreak: 'break-all',
    whiteSpace: 'break-spaces',
    background: '#EFF1F8',
    padding: '17px 25px 20px 20px',
    borderRadius: 0,
    borderBottom: '1px solid white',
    '&:hover': {
      borderLeft: '4px solid #3C54B9',
    },
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      '& .list-item-content-wrapper': {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        '& .MuiTypography-subtitle1': {
          fontWeight: 'bold',
          fontSize: '15px',
          lineHeight: '18px',
          color: '#3C54B9',
        },
        '& .MuiTypography-subtitle2': {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '12px',
          lineHeight: '14px',
          color: '#9FA6C5',
          magin: '4px 0 0 0',
        },
      },
      '& .list-item-arrow-btn': {
        flex: '0 0 24px',
        width: 24,
        height: 24,
        backgroundColor: 'white',
      },
    },
  },
  listAnswerItemActive: {
    borderLeft: '4px solid #3C54B9',
  },
}));

const ChatListContainer = ({ loading, chatList, sendAnswer }) => {

  const classes = useStyles();
  const history = useHistory();
  const auth = useRecoilValue(authState);

  const renderContent = (messageItem) => {
    if (!messageItem) return null;
    else if (messageItem.type === QUESTION_TYPE.IMAGE) {
      if (messageItem.owner === MESSAGE_OWNER.RECEIVED)
        return <Box className='chat-content'>{messageItem.content}</Box>;
      else {
        return (
          <Box className={'chat-content image-content'}>
            <img
              src={messageItem.content.imgSrc}
              alt={messageItem.content.name}
            />
          </Box>
        );
      }
    } else if (messageItem.owner === MESSAGE_OWNER.SENDER && messageItem.type === QUESTION_TYPE.TABLE) {
      return <Box className='chat-content'>{`我想选择第${messageItem.content}个`}</Box>
    } else if (messageItem.type === QUESTION_TYPE.TEXT) {
      if (typeof (messageItem.content) === 'object') {
        return <>{messageItem.content.map((item, idx) => {
          return <Box className='chat-content' key={idx}>{item}</Box>;
        })}
        </>
      } else if (typeof (messageItem.content) === 'function') {
        return <Box className='chat-content'>{messageItem.content()}</Box>;
      } else
        return <Box className='chat-content'>{messageItem.content.replace(/ +(?= )/g, '')}</Box>;
    }
  };

  const renderAnswerBtns = (messageItem, nIndex) => {
    const buttonArr = [];
    Object.keys(messageItem.content).forEach((item) => {
      buttonArr.push(
        <Button
          className={classes.answerButton}
          variant='outlined'
          color='primary'
          size='small'
          key={item}
          onClick={() => {
            if (nIndex === chatList.length - 1)
              sendAnswer({
                type: QUESTION_TYPE.TEXT,
                content: messageItem.content[item].text,
              });
          }}
        >
          {messageItem.content[item].text}
        </Button>
      );
    });

    return (
      <Grow in={true}>
        <Grid item className={classes.buttonsWrapper}>
          {buttonArr}
        </Grid>
      </Grow>
    );
  };

  const renderListItem = (messageItem, nIndex) => {
    const listArr = [];

    Object.keys(messageItem.answer).forEach((answerKey) => {
      const listRootClass = [classes.listAnswerItem];
      if (
        nIndex < chatList.length - 1 &&
        chatList[nIndex + 1].type === QUESTION_TYPE.LIST &&
        chatList[nIndex + 1].owner === MESSAGE_OWNER.SENDER &&
        chatList[nIndex + 1].content === answerKey
      )
        listRootClass.push(classes.listAnswerItemActive);

      listArr.push(
        <Button
          className={listRootClass.join(' ')}
          key={answerKey}
          onClick={() => {
            sendAnswer({
              type: QUESTION_TYPE.LIST,
              content: answerKey,
            });
            history.push(messageItem.answer[answerKey].url);
          }}
        >
          <Box className='list-item-content-wrapper'>
            <Typography variant='subtitle1'>
              {messageItem.answer[answerKey].name}
            </Typography>
            <Typography variant='subtitle2'>
              {messageItem.answer[answerKey].sub_name}
            </Typography>
          </Box>
          <IconButton className='list-item-arrow-btn'>
            <KeyboardArrowRightIcon />
          </IconButton>
        </Button>
      );
    });
    return (
      <SimpleBar className={classes.listAnswerItemWrapper}>{listArr}</SimpleBar>
    );
  };

  const renderTables = (nIndex, messageItem) => {
    if (auth.role === USER_ROLE.EMPLOYEE || auth.role === USER_ROLE.LEADER) {
      const tableType = getTableType(messageItem);
      if (tableType === TABLE_TYPE.FORM_TABLE)
        return <FormTableMessage messageContent={messageItem} />;
      else if (tableType === TABLE_TYPE.ATTRIBUTE_TABLE)
        return <ItemListTableMessage
          messageContent={messageItem}
          clickRow={(formId) => {
            sendAnswer({
              type: QUESTION_TYPE.TABLE,
              content: formId
            })
          }}
        />;
    } else {
      return <AdminTableMessage
        messageContent={messageItem}
        clickRow={(row_num) => {
          if (
            chatList.length - 1 > nIndex &&
            chatList[nIndex + 1].type === QUESTION_TYPE.TEXT &&
            chatList[nIndex + 1].content[0] === '请问需要办理退回的是第几张表？'
          )
            sendAnswer({
              type: QUESTION_TYPE.TEXT,
              content: `我想选择第${row_num}个申请表`
            })
          else
            sendAnswer({
              type: QUESTION_TYPE.TABLE,
              content: row_num
            })
        }}
      />
    }
  }

  return (
    <Box className={classes.root}>
      {chatList.map((item, idx) => {
        if (
          item.owner === MESSAGE_OWNER.SENDER &&
          item.type === QUESTION_TYPE.LIST
        )
          return null;
        const itemClass = ['chat'];

        if (item.owner === MESSAGE_OWNER.RECEIVED) {
          itemClass.push('left');
        } else {
          itemClass.push('right');
        }

        return (
          <React.Fragment key={idx}>
            <Grow in={true}>
              <Grid item className={itemClass.join(' ')} xs={12}>
                {renderContent(item)}
              </Grid>
            </Grow>
            {item.owner === MESSAGE_OWNER.RECEIVED &&
              item.type === QUESTION_TYPE.BUTTON &&
              idx === chatList.length - 1 && <Grow in={true}>{renderAnswerBtns(item, idx)}</Grow>}
            {item.owner === MESSAGE_OWNER.RECEIVED &&
              item.type === QUESTION_TYPE.LIST && (
                <Grow in={true}>
                  <ListTableMessage
                    messageContent={item}
                    clickRow={(clickInfo) => {
                      sendAnswer({
                        type: QUESTION_TYPE.TEXT,
                        content: clickInfo
                      })
                    }}
                  />
                </Grow>
              )
            }
            {item.owner === MESSAGE_OWNER.RECEIVED && item.type === QUESTION_TYPE.TABLE && renderTables(idx, item)}
          </React.Fragment>
        );
      })}
      {loading && <Grow in={true}><TextLoadingIndicator /></Grow>}
    </Box >
  );
};

export default React.memo(ChatListContainer);

import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import './i18n';
import theme from './theme';
import { routes } from './constants';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';

import Home from './pages/Home';
import Accounts from './pages/Accounts';
import Applications from './pages/Application/Applications';
import ApplicationDetail from './pages/Application/Applications/Detail';
import ClaimSheet from './pages/Application/ClaimSheet';
import ClaimSheetDetail from './pages/Application/ClaimSheet/Detail'
import ClaimSheetEdit from './pages/Application/ClaimSheet/Edit'
import Notifications from './pages/Notification';
import Inventory from './pages/Inventory';
import StockIn from './pages/Inventory/StockIn';
import StockInEdit from './pages/Inventory/StockIn/Edit';
import StockInSheets from './pages/Inventory/StockInSheets';
import CheckInventory from './pages/Inventory/CheckInventory';
import CheckInventoryEdit from './pages/Inventory/CheckInventory/Edit';
import InventorySheet from './pages/Inventory/InventorySheet';
import InventorySheetDetail from './pages/Inventory/InventorySheet/Detail';
import InventorySheetEdit from './pages/Inventory/InventorySheet/Edit';
import Verification from './pages/Verification';
import VerificationDetail from './pages/Verification/Detail';
import VerificationEdit from './pages/Verification/Edit';
import BorrowSheets from './pages/Borrow/BorrowSheets';
import BorrowDetail from './pages/Borrow/Detail';
import BorrowEdit from './pages/Borrow/Edit';
import MyAccount from './pages/MyAccount';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Chatbot from './components/Chatbot';

import { authState } from './atoms';
import { USER_ROLE } from './constants'

const App = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const localAuth = localStorage.getItem('auth');

  useEffect(() => {
    if (!!localAuth) {
      setAuth({
        ...JSON.parse(localAuth),
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <PublicRoute
            exact
            restricted
            path={routes.LOGIN}
            component={Login}
          />
          <PrivateRoute exact path={routes.HOME} component={Home} />
          <PrivateRoute exact path={routes.ACCOUNTS} component={Accounts} />
          <PrivateRoute exact path={routes.SIGNUP} component={Signup} />
          <PrivateRoute exact path={routes.APPLICATIONS} component={Applications} />
          <PrivateRoute exact path={routes.APPLICATION_DETAIL} component={ApplicationDetail} />
          <PrivateRoute exact path={routes.CLAIMSHEETS} component={ClaimSheet} />
          <PrivateRoute exact path={routes.CLAIMSHEET_MONTH} component={ClaimSheetDetail} />
          <PrivateRoute exact path={routes.CLAIMSHEET_EDIT} component={ClaimSheetEdit} />
          <PrivateRoute exact path={routes.NOTIFICATION} component={Notifications} />
          <PrivateRoute exact path={routes.INVENTORY} component={Inventory} />
          <PrivateRoute exact path={routes.STOCKIN} component={StockInEdit} />
          <PrivateRoute exact path={routes.STOCKIN_DETAIL} component={StockIn} />
          <PrivateRoute exact path={routes.STOCKIN_EDIT} component={StockInEdit} />
          <PrivateRoute exact path={routes.STOCKIN_SHEETS} component={StockInSheets} />
          <PrivateRoute exact path={routes.INVENTORYSHEET} component={InventorySheet} />
          <PrivateRoute exact path={routes.INVENTORYSHEET_DETAIL} component={InventorySheetDetail} />
          <PrivateRoute exact path={routes.INVENTORYSHEET_EDIT} component={InventorySheetEdit} />
          <PrivateRoute exact path={routes.CHECKINVENTORY} component={CheckInventory} />
          <PrivateRoute exact path={routes.CHECKINVENTORY_EDIT} component={CheckInventoryEdit} />
          <PrivateRoute exact path={routes.MYACCOUNT} component={MyAccount} />
          <PrivateRoute exact path={routes.VERIFICATION} component={Verification} />
          <PrivateRoute exact path={routes.VERIFICATION_DETAIL} component={VerificationDetail} />
          <PrivateRoute exact path={routes.VERIFICATION_EDIT} component={VerificationEdit} />
          <PrivateRoute exact path={routes.VERIFICATION_CREATE} component={VerificationEdit} />
          <PrivateRoute exact path={routes.BORROWS} component={BorrowSheets} />
          <PrivateRoute exact path={routes.BORROW_DETAIL} component={BorrowDetail} />
          <PrivateRoute exact path={routes.BORROW_CREATE} component={BorrowEdit} />
          <PrivateRoute exact path={routes.BORROW_EDIT} component={BorrowEdit} />
        </Switch>
        {!!localAuth && JSON.parse(localAuth).role !== USER_ROLE.UNOFFICIAL && < Chatbot />}
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;

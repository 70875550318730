import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import FullLoadingSpinner from '../../components/FullLoadingSpinner';
import { useRecoilState, useRecoilValue } from 'recoil';
import { authState, notificationState } from '../../atoms'
import { readNotificationAPI } from '../../apis'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .page-title-wrapper': {
      marginBottom: 0,
    }
  },
}));

const Notifications = () => {

  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const auth = useRecoilValue(authState);
  const [notification, setNotification] = useRecoilState(notificationState);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [loading, setLoading] = useState(false);

  const clickNotification = async (notificationId) => {
    try {
      const response = await readNotificationAPI(notificationId);
      if (response.data.status === 'success') {
        setNotification([
          ...notification.map(item => {
            if (notificationId === item.msg_id)
              return { ...item, is_read: 1 }
            else return item;
          })
        ])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box className='main-page-content'>
      <Box className="page-title-wrapper">
        <Typography variant="h3" className="page-title">
          {t('Notification')}
        </Typography>
      </Box>
      <Box className="darin-table-panel">
        <Box className="table-action-wrapper">
          <TablePagination
            className="table-pagination-wrapper"
            rowsPerPageOptions={[5, 10, 25]}
            count={notification.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            component="div"
          />
        </Box>
        <TableContainer className="darin-table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '50px' }}></TableCell>
                <TableCell>{t('Application FormId')}</TableCell>
                <TableCell>{t('Notification Table Name')}</TableCell>
                <TableCell>{t('Items')}</TableCell>
                <TableCell>{t('State')}</TableCell>
                <TableCell>{t('Showed On')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notification.length === 0 ?
                <TableCell colSpan={7} style={{ textAlign: 'center' }}>{t('Currently there is no notification~')}</TableCell>
                : <>{notification.map((item, idx) => {
                  if (idx >= (page + 1) * rowsPerPage || idx < page * rowsPerPage)
                    return null;
                  return <TableRow
                    key={idx}
                    hover
                    onClick={() => {
                      clickNotification(item.msg_id)
                      history.push(`/application/${item.form_id}`)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                      {item.is_read === 0 ? <EmailOutlinedIcon color="secondary" /> : <DraftsOutlinedIcon color="primary" />}
                    </TableCell>
                    <TableCell>{item.form_id}</TableCell>
                    <TableCell>{`${item.last_name || ''} ${item.first_name || ''}`}</TableCell>
                    <TableCell>{item.item_names.join(', ')}</TableCell>
                    <TableCell>{item.state}</TableCell>
                    <TableCell>{item.ShowedOn}</TableCell>
                  </TableRow>
                })}</>}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>

  );
};

export default Notifications;

import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmModal({ isShow, hideModal, title, description = "", onOk }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isShow}
      onClose={hideModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk} color="primary">
          {t('Yes')}
        </Button>
        <Button autoFocus onClick={hideModal} color="primary">
          {t('No')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

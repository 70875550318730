import axiosInstance from '../lib/axios';
import { USER_ROLE } from '../constants';

export const showAllBorrowInFormsAPI = () => {
  return new Promise((resolve, reject) => {
    try {
      let apiURL = '/show_all_borrow_in_forms';

      axiosInstance.get(apiURL)
        .then(res => {
          resolve(res.data.data)
        }).catch(err => {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const getNextBorrowFormIdAPI = (onwer_id) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.get(`/get_next_borrow_form_id?onwer_id=${onwer_id}`).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const getBorrowInFormAPI = (borrow_form_id = 'all') => {
  return new Promise((resolve, reject) => {
    try {
      let apiURL = '/get_borrow_in_form';
      if (borrow_form_id !== 'all') apiURL += `?borrow_form_id=${borrow_form_id}`;

      axiosInstance.get(apiURL)
        .then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const saveBorrowInAPI = (borrowData) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post('/borrow_in', { ...borrowData }).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const deleteBorrowAPI = (borrow_form_id = []) => {
  return new Promise((resolve, reject) => {
    try {
      const axiosArr = [];
      borrow_form_id.forEach(item => {
        axiosArr.push(axiosInstance.get(`/delete_borrow_in_form?borrow_form_id=${item}`))
      })
      Promise.all(axiosArr).then(responses => {
        resolve(true)
      }).catch(err => {
        reject(false)
      })

    } catch (err) {
      reject(err)
    }
  })
}

export const submitBorrowAPI = (form_id) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post(`/submit_table?table_name=borrow&form_id=${form_id}`).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}
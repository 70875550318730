import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { authState, notificationState } from '../../atoms';
import {
  makeStyles, Grid, Box, Typography, List,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import FullLoadingSpinner from '../../components/FullLoadingSpinner';
import { readNotificationAPI } from '../../apis';
import { getPassedDate } from '../../utils'
import { routes, USER_ROLE } from '../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  mapWrapper: {
    marginBottom: theme.spacing(2)
  },
  notificationBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: theme.spacing(2)
  },
  weatherWrapper: {
    display: 'flex',
    alignItems: 'center',
    border: '2px solid #d3d3d3',
    backgroundColor: 'white',
    height: 200,
  },
  dropdownWrapper: {
    width: '100%',
    backgroundColor: 'white',
    paddingTop: 8,
    paddingBottom: 8,
    border: '2px solid #d3d3d3',
    '& .MuiListItem-root': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      '& .MuiTypography-subtitle1': {
        fontSize: 14,
        lineHeight: '16px',
        fontStyle: 'normal',
        color: theme.palette.primary.main,
        fontWeight: 600,
        cursor: 'pointer',
      },
      '& .MuiListItemIcon-root': {
        minWidth: 30,
        marginRight: 4,
      },
      '& .MuiListItemText-root': {
        '& .MuiTypography-body1': {
          color: '#333',
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 600,
        }
      },
      '& .notification-content': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 100%',
        '& .MuiTypography-subtitle2': {
          fontSize: 14,
          fontStyle: 'normal',
          color: '#333'
        },
        '& .date': {
          fontSize: 12,
          lineHeight: '14px',
          color: 'grey',
          fontStyle: 'normal',
          fontWeight: 400,
          textAlign: 'left',
          flex: '1 0 auto',
          margin: '4px 0 0 0',
          '@media (max-width: 767px)': {
           display:'none',
          },
        }
      },
    },
    '& .load-more': {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 600,
      cursor: 'pointer',
      color: '#333',
      textAlign: 'center',
      justifyContent: 'center'
    }
  },
  notificationContent: {
    color: theme.palette.primary.main,
    fontSize: '13px',
    lineHeight: '15px',
    margin: 0,
    overflow: 'hidden',
    fontWeight: 500
  }
}));

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const [notification, setNotification] = useRecoilState(notificationState);
  const auth = useRecoilValue(authState);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  setTimeout(function () { setLoading(false); }, 2000);

  const clickNotification = async (selectedNotification) => {
    try {
      const response = await readNotificationAPI(selectedNotification.msg_id);
      if (response.data.status === 'success') {
        setNotification([
          ...notification.map(item => {
            if (selectedNotification.msg_id === item.msg_id)
              return { ...item, is_read: 1 }
            else return item;
          })
        ])
      }
      history.push(`/application/${selectedNotification.form_id}`)
    } catch (err) {
      history.push(`/application/${selectedNotification.form_id}`)
    }
  }

  const getNotifiExplainText = (item) => {
    if (auth) {
      if (auth.role === USER_ROLE.LEADER) {
        if (item.type === '申请表' && item.action === '待审批') {
          return <p className={classes.notificationContent}>{`【${item.action}】您收到一个新的${item.type} (编号: ${item.form_id})`}</p>;
        } else {
          return <p className={classes.notificationContent}>{`【${item.action}】 ${item.type}: ${item.first_name} ${item.last_name} ${t('has submitted applications regarding items')}${item.item_names.join(', ')}`}</p>
        }
      }
      else if (auth.role === USER_ROLE.ADMIN) {
        if (item.type === '申请表') {
          if (item.action === '通过')
            return <p className={classes.notificationContent}>{`【待出库】${item.type} (编号: ${item.form_id}) 领导已批准`}</p>;
          else if (item.action === '已领取')
            return <p className={classes.notificationContent}>{`【已发放】您已向 ${item.type} (编号: ${item.form_id}) 发放物资`}</p>;
          else if (item.action === '有退回') {
            return <p className={classes.notificationContent}>{`【有退回】您已替 ${item.type} (编号: ${item.form_id}) 办理退回`}</p>;
          } else return <p className={classes.notificationContent}>{`【${item.action}】 ${item.type}: ${t('You have a new application pending')}`}</p>;
        } else {
          return <p className={classes.notificationContent}>{`【${item.action}】 ${item.type}: ${t('You have a new application pending')}`}</p>;
        }
      } else if (auth.role === USER_ROLE.EMPLOYEE) {
        if (item.type === '申请表') {
          if (item.action === '通过' || item.action === '拒绝') {
            return <p className={classes.notificationContent}>{`【${item.action}】您的${item.type} (编号: ${item.form_id}) 已被审批`}</p>;
          } else {
            return <p className={classes.notificationContent}>{`【${item.action}】您的${item.type} (编号: ${item.form_id}) 状态已更新`}</p>;
          }
        } else return <p className={classes.notificationContent}>{`【${item.action}】 ${item.type}: ${t('You have a new application pending')}`}</p>
      }
    } else return <p className={classes.notificationContent}></p>;
  }
  return (
    <div className='main-page-content'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box className="page-title-wrapper">
            <Typography variant="h3" className="page-title">
              {t('home')}
            </Typography>
          </Box>
        </Grid>
        <Grid className={classes.mapWrapper} item xs={12}>
          <iframe
            src="/map.html"
            class="topBlue" width="100%" height="200" frameBorder="0" scrolling="no"
          />
        </Grid>
        <Grid className={classes.notificationBox} item sm={8} xs={12}>
          <List className={classes.dropdownWrapper}>
            {notification.length === 0 ? <ListItem className="load-more" >
              {t('No Notification')}
            </ListItem> : <>{notification.map((item, idx) => {
              if (idx > 4) return null;
              return <ListItem key={idx} button onClick={() => {
                clickNotification(item)
              }}>
                <ListItemIcon>
                  {item.is_read === 0 ? <EmailOutlinedIcon color="secondary" /> : <DraftsOutlinedIcon color="primary" />}
                </ListItemIcon>
                <Box className="notification-content">
                  {getNotifiExplainText(item)}
                  <span className="date">
                    {getPassedDate(item.ShowedOn, (timeStr) => {
                      return t(timeStr)
                    })}
                  </span>
                </Box>
              </ListItem>
            })}
              <ListItem className="load-more" button onClick={() => {
                history.push(routes.NOTIFICATION)
              }}>
                {t('Check previous history...')}
              </ListItem></>}
          </List>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box className={classes.weatherWrapper}>
            <iframe
              allowtransparency="true"
              frameBorder="0"
              width="100%"
              height="200"
              scrolling="no"
              src="/w.html" />
          </Box>
        </Grid>
      </Grid>
      {loading && <FullLoadingSpinner />}
    </div>

  );
};

export default Home;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
	makeStyles,
	AppBar,
	Typography,
	Box,
	IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import LeftSidebar from './../LeftSidebar';
import AuthDropdown from './AuthDropdown';
import SettingsDropdown from './SettingsDropdown';
import NotificationDropdown from './NotificationDropdown';
import { authState } from '../../atoms';
import { LEFT_SIDEBAR_WIDTH, HEADER_HEIGHT, routes, USER_ROLE } from '../../constants';
import { getChineseDayName, getCurChinaTime } from '../../utils';
import { useInterval } from '../../hooks';
import LogoPng from '../../assets/images/logo-white.png';

const useStyles = makeStyles((theme) => ({
	root: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexGrow: 1,
		zIndex: 9,
		padding: 0,
		background: '#FFFFFF',
		// boxShadow: '0px 4px 16px rgba(131, 131, 131, 0.1)',
		height: HEADER_HEIGHT,
		borderBottom: '1px solid #86a3d8',
		'& .main-container': {
			display: 'flex',
			margin: '0 auto',
			width: '100%',
			height: '100%',
			paddingLeft: '100px',
			paddingRight: '65px',
			overflow: 'visible',
			alignItems: 'center',
		},
	},
	userInfo: {
		flexShrink: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		overflow: 'hidden',
		textAlign: 'center',
		position: 'absolute',
		left: 0,
		top: 0,
		width: LEFT_SIDEBAR_WIDTH,
		backgroundColor: theme.palette.primary.main,
		padding: '5px 20px',
		height: `${HEADER_HEIGHT + 1}px`,
		cursor: 'pointer',
		'& img': {
			height: '90%'
		},
	},
	menuWrapper: {
		marginLeft: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
	},
	date: {
		fontWeight: 600,
		color: theme.palette.primary.main,
		marginLeft: 'auto',
		'@media (max-width: 767px)': {
			display:'none',
		  },
	}
}));

const Header = () => {
	const classes = useStyles();
	const history = useHistory();
	const { t, i18n } = useTranslation();
	const auth = useRecoilValue(authState);
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [curChinaTime, setCurChinaTime] = useState(getCurChinaTime())



	const HideMenu = () => {
		if (window.innerWidth > 768) { return; }
		var drag = document.getElementsByClassName('drag-handler')[0];
		var main = document.getElementsByClassName('main-page-content')[0];
		var nav = document.getElementsByTagName('nav')[0];
		if (window.event.clientX < 11) {
			main.style.marginLeft = '180px';
			drag.style.left = '170px';
			nav.style.display = 'block';
			drag.style.backgroundImage = '';
			// drag.style.width='10px';
			//drag.style.top='100px';
			drag.innerText = '《';
			drag.style.height = '15px';
			//	drag.style.display='none';
		} else {
			drag.innerText = '》';
			drag.style.top = '50px';
			//drag.style.display='block';
			main.style.marginLeft = '0px';
			drag.style.left = '0px';
			// drag.style.width='20px';
			nav.style.display = 'none';
			drag.style.height = 'calc(100% - 50px)';
			drag.style.backgroundImage = 'linear-gradient(to bottom, #3b75a9 , #7db0de)';
		}
		console.log(drag.innerText);
	}
	useInterval(() => {
		setCurChinaTime(getCurChinaTime());
	}, 1000)
	return (
		<>
			<AppBar className={classes.root} position='fixed'>
				<Box className={'main-container'}>
					<IconButton
						className={classes.menuButton}
						color='inherit'
						aria-label='open drawer'
						onClick={() => {
							setSidebarOpen(!sidebarOpen);
						}}
						edge='start'
						className={clsx(classes.menuButton, sidebarOpen && classes.hide)}
					>
						<MenuIcon />
					</IconButton>
					{i18n.language}
					{auth && <Box className={classes.userInfo} onClick={() => {
						history.push(routes.HOME)
					}}>
						<img src={LogoPng} alt="logo" />
					</Box>}

					<Typography variant="h6" className={classes.date}>
						{i18n.language === 'en' && <>
							{curChinaTime.format('MMMM D')}
							<sup style={{ fontSize: 12, fontWeight: 600 }}>th </sup>
							&nbsp;
							{curChinaTime.format('YYYY, ddd, H:mm a')}</>
						}
						{i18n.language === 'zh' &&
							<>{`${curChinaTime.format('YYYY年MM月DD日')}, ${getChineseDayName(curChinaTime.day())}, ${curChinaTime.format('HH:mm a')}`}</>
						}
					</Typography>

					<Box className={classes.menuWrapper}>
						{auth !== null && (
							<>
								{auth.role !== USER_ROLE.UNOFFICIAL && <NotificationDropdown />}
								<AuthDropdown />
								<SettingsDropdown />
							</>
						)}
					</Box>
				</Box>
			</AppBar>
			<LeftSidebar open={sidebarOpen} />
			<div class="drag-handler"

				onClick={HideMenu}>
				》</div>

		</>
	);
};

export default Header;

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms';
import clsx from 'clsx';
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Breadcrumbs,
  Link
} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import FullLoadingSpinner from '../../components/FullLoadingSpinner';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import {
  getBorrowInFormAPI, submitBorrowAPI
} from '../../apis';
import { routes } from '../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const BorrowDetail = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { borrow_form_id } = useParams();
  const history = useHistory()
  const auth = useRecoilValue(authState);

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    borrow_form_id: -1,
    owner_id: -1,
    data: {
      item_name: "",
      item_model: "",
      unit_price: '',
      total_price: "",
      borrow_quantily: "",
      quantily_available: "",
      borrow_time: "",
      location: "",
      borrower_name: "",
      borrow_company: "",
      working_id: "",
      employee_signature: "",
      admin_signature: "",
      borrow_company_signature: "",
      leader_signature: ""
    }
  })

  let componentMounted = true;
  useEffect(() => {
    if (!auth) return;
    if (borrow_form_id) {
      getBorrowInFormAPI(borrow_form_id).then(res => {
        if (componentMounted) {
          let returnData = res.data;
          setFormData({
            ...returnData,
          })
          setLoading(false);
        }
      }).catch(err => {
        if (componentMounted) {
          setLoading(false);
          setFormData({
            ...formData,
            borrow_form_id: borrow_form_id
          })
        }
      })
    }
    return () => { componentMounted = false; }
  }, [auth])

  const formatBorrowFormData = () => {
    return {
      ...formData,
      data: {
        ...formData.data,
      },
    }
  }

  const exportCSV = () => {
    // const exportData = formatBorrowFormData();
    // let recordBody = '';
    // exportData.data.row_data.forEach(item => {
    //   recordBody += `<tr>
    //     <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.row_num}</td>
    //     <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 180px">${item.item_name}</td>
    //     <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.item_model}</td>
    //     <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.quantity}</td>
    //     <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.unit}</td>
    //     <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 180x">${item.item_id}</td>
    //   </tr>`
    // });

    // const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
    // const worksheet = 'Sheet1'
    // const uri = 'data:application/vnd.ms-excel;base64,';
    // const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
    // xmlns:x="urn:schemas-microsoft-com:office:excel"
    // xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
    // <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
    // <x:Name>${worksheet}</x:Name>
    // <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
    // </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
    // </head><body>
    // <body link="#0563C1" vlink="#954F72">
    //   <table style="font-family: SimSun">
    //     <thead>
    //       <tr>
    //         <th colspan="6" style="font-size: 18pt; height: 25pt; text-align: center;  vertical-align: middle; border: 0.5pt solid black;">
    //           ${t('Borrow Sheet')}
    //         </th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       <tr>
    //         <td colspan="6" style="font-size: 14pt;  text-align: right; height: 18pt; vertical-align: middle; border: 0.5pt solid black;">
    //           ${t('FormID')}:${formData.borrow_form_id}
    //         </td>
    //       </tr>
    //       <tr>
    //         <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
    //           ${t('contract name and id')}
    //         </td>
    //         <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
    //           ${t('supplier name')}
    //         </td>            
    //       </tr>
    //       <tr>
    //         <td colspan="3" style="height: 30pt; font-size: 14pt; vertical-align: middle; border: 0.5pt solid black;">            
    //           ${t('Contract Name')}:  ${exportData.data.contract_name} <br/>
    //           ${t('Contract ID')}:  ${exportData.data.contract_id}
    //         </td>
    //         <td colspan="3" style="height: 30pt; font-size: 14pt; vertical-align: middle; border: 0.5pt solid black; text-align: center">
    //           ${exportData.data.supplier_name}
    //         </td>            
    //       </tr>
    //       <tr>
    //         <td style="width: 30pt; font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Order id')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; width: 150pt;">${t('on-site contact/phone')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; width: 150pt;">${t('supplier contact/phone')}</td>
    //         <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; width: 120pt">${t('contract date')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; width: 120pt">${t('expected delivery date')}</td>
    //       </tr>          
    //       <tr>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
    //           ${exportData.data.order_id}
    //         </td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
    //           ${exportData.data.onsite_contact}
    //         </td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
    //           ${exportData.data.supplier_contact}
    //         </td>
    //         <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
    //           ${exportData.data.contract_date}
    //         </td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
    //           ${moment(exportData.data.expected_delivery_date).format('YYYY-MM-DD')}
    //         </td>
    //       </tr>
    //       <tr>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('No.')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('Name')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('item_model')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('quantity')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('unit')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('item_id')}</td>
    //       </tr>
    //       ${recordBody}          
    //       <tr>
    //         <td colspan="6" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('Borrow Comments')}</td>
    //       </tr>
    //       <tr>
    //         <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('project')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('result')}</td>
    //         <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('notes')}</td>
    //       </tr>
    //       <tr>
    //         <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('quantity verified')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[0].quantity_verified === 1 ? t('yes') : t('no')}</td>
    //         <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[0].notes || ''}</td>
    //       </tr>
    //       <tr>
    //         <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('document verified')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[1].document_verified === 1 ? t('yes') : t('no')}</td>
    //         <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[1].notes || ''}</td>
    //       </tr>
    //       <tr>
    //         <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('appearance verified')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[2].appearance_verified === 1 ? t('yes') : t('no')}</td>
    //         <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[2].notes || ''}</td>
    //       </tr>
    //       <tr>
    //         <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('performance verified')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[3].performance_verified === 1 ? t('yes') : t('no')}</td>
    //         <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[3].notes || ''}</td>
    //       </tr>
    //       <tr>
    //         <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('on time')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[4].on_time === 1 ? t('yes') : t('no')}</td>
    //         <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[4].notes || ''}</td>
    //       </tr>
    //       <tr>
    //         <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('representative present')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[5].representative_present === 1 ? t('yes') : t('no')}</td>
    //         <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[5].notes || ''}</td>
    //       </tr>
    //       <tr>
    //         <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('check verified')}</td>
    //         <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[6].check_verified === 1 ? t('yes') : t('no')}</td>
    //         <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[6].notes || ''}</td>
    //       </tr>
    //       <tr>
    //         <td colspan="6"></td>
    //       </tr>
    //       <tr>
    //         <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: left; border: none;">${t('accepter name')}: ${exportData.data.leader_name}</td>            
    //         <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: left; border: none;">${t('acceptance time')}: ${exportData.data.admin_verification_date}</td>
    //       </tr>
    //       <tr>
    //         <td colspan="6" style="font-size: 14pt; vertical-align: middle; text-align: left; border: none;">${t('accepter')}: ${exportData.data.verifier_name}</td>
    //       </tr>
    //     </tbody>
    //   </table>
    // </body></html>
    // `;

    // const link = document.createElement("a");
    // link.href = uri + base64(template);
    // link.target = "_blank";
    // link.download = "BorrowSheet.xls";
    // link.click();
  }

  return (
    <Box className={clsx(classes.root, 'main-page-content')}>
      <Box xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Breadcrumbs className="darin-breadcrumbs">
          <Link onClick={() => { history.push(routes.BORROWS); }} color="inherit">
            {i18n.language === 'zh' ? '茂明风光电站备件及耗材借用单' : 'Borrow Sheets'}
          </Link>
          <Typography>{t('FormID')}: {formData.borrow_form_id}</Typography>
        </Breadcrumbs>
        <IconButton
          onClick={() => {
            history.push(`/borrow/edit/${borrow_form_id}`)
          }}
        >
          <CreateOutlinedIcon color="primary" />
        </IconButton>
        <IconButton>
          <SendOutlinedIcon color="primary" />
        </IconButton>
        <IconButton
          onClick={exportCSV}
        >
          <GetAppOutlinedIcon color="primary" />
        </IconButton>
      </Box>

      <Box>
        <Typography variant="h3">{t('Borrow Sheet')}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" style={{ textAlign: 'right', marginBottom: '8px' }}>
          {t('form_id')}: &nbsp;{formData.borrow_form_id}
        </Typography>
      </Box>

      <Box className="darin-table-panel record-table-container">
        <TableContainer className="darin-table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Item Name')}</TableCell>
                <TableCell>{t('Item Model')}</TableCell>
                <TableCell>{t('Unit Price')}</TableCell>
                <TableCell>{t('Total Price')}</TableCell>
                <TableCell>{t('borrow quantity')}</TableCell>
                <TableCell>{t('quantity available')}</TableCell>
                <TableCell>{t('borrow time')}</TableCell>
                <TableCell>{t('location')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{formData.data.item_name}</TableCell>
                <TableCell>{formData.data.item_model}</TableCell>
                <TableCell>{formData.data.unit_price}</TableCell>
                <TableCell>{formData.data.total_price}</TableCell>
                <TableCell>{formData.data.borrow_quantily}</TableCell>
                <TableCell>{formData.data.quantily_available}</TableCell>
                <TableCell>{formData.data.borrow_time}</TableCell>
                <TableCell>{formData.data.location}</TableCell>
              </TableRow>
            </TableBody>
            <TableHead>
              <TableCell>{t('borrow name')}</TableCell>
              <TableCell>{t('borrow company')}</TableCell>
              <TableCell colspan={2}>{t('working id')}</TableCell>
              <TableCell>{t('employee signature')}</TableCell>
              <TableCell>{t('admin signature')}</TableCell>
              <TableCell>{t('borrow company signature')}</TableCell>
              <TableCell>{t('leader signature')}</TableCell>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{formData.data.borrow_name}</TableCell>
                <TableCell>{formData.data.borrow_company}</TableCell>
                <TableCell>{formData.data.working_id}</TableCell>
                <TableCell>{formData.data.employee_signature}</TableCell>
                <TableCell>{formData.data.admin_signature}</TableCell>
                <TableCell>{formData.data.borrow_company_signature}</TableCell>
                <TableCell>{formData.data.leader_signature}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {loading && <FullLoadingSpinner />}
    </Box >)
}

export default BorrowDetail;
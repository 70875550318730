import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Tooltip,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import SaveIcon from '@material-ui/icons/Save';
import FullLoadingSpinner from '../../../components/FullLoadingSpinner';
import { showClaimSheetAPI, updateClaimSheetAPI } from '../../../apis';
import { YYYYMMDDtoFormat, getColumnValue } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .page-title-wrapper': {
      marginBottom: 0,
    }
  },
  userInfoTableContainer: {
    marginTop: 60
  }
}))

const ClaimSheetEdit = () => {

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { month, title } = useParams();

  const [claimSheets, setClaimSheets] = useState([]);
  const [loading, setLoading] = useState(true);

  let componentMounted = true;

  useEffect(() => {
    if (month) {
      setLoading(true);
      showClaimSheetAPI(month).then(res => {
        if (componentMounted) {
          setLoading(false);
          setClaimSheets([...res.data.data]);
        }
      })
    }
  }, [month])

  const renderTitle = () => {
    try {
      const firstText = title.substr(0, title.indexOf(' '));
      const secondText = title.substr(title.indexOf(' ') + 1, title.length);
      return `${firstText}${YYYYMMDDtoFormat(month)}${secondText}`;
    } catch (err) {
      return ''
    }
  }

  const goBack = () => {
    Swal.fire({
      title: t('Do you want to save the changes?'),
      showCancelButton: true,
      cancelButtonText: t('No'),
      showConfirmButton: true,
      confirmButtonText: t('Yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        updateClaimSheetAPI({
          month: month,
          data: [
            ...claimSheets.map(item => {
              return { row_num: item.row_num, notes: item.notes }
            })
          ]
        }).then(res => {
          setLoading(false);
          history.push(`/claim-sheet/${month}/${title}`)
        }).catch(err => {
          setLoading(false);
          console.log(err)
        })
      } else {
        history.push(`/claim-sheet/${month}/${title}`)
      }
    })
  }

  return (
    <Box className={clsx(classes.root, 'main-page-content')}>
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Breadcrumbs className="darin-breadcrumbs">
            <Link
              onClick={() => { history.push(`/claim-sheets`); }}
              color="inherit"
            >
              {t('Claim Sheets')}
            </Link>
            <Link
              onClick={goBack}
              color="inherit"
            >
              {t('Edit')}
            </Link>
            <Typography>{t('time')}: {YYYYMMDDtoFormat(month, i18n.language)}</Typography>
          </Breadcrumbs>
          <Tooltip title={t('Save')}>
            <IconButton
              onClick={() => {
                setLoading(true)
                updateClaimSheetAPI({
                  month: month,
                  data: [
                    ...claimSheets.map(item => {
                      return { row_num: item.row_num, notes: item.notes }
                    })
                  ]
                }).then(res => {
                  setLoading(false);
                  history.push(`/claim-sheet/${month}/${title}`)
                }).catch(err => {
                  setLoading(false);
                  console.log(err)
                })
              }}
            >
              <SaveIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('submit')}>
            <IconButton disabled><SendOutlinedIcon /></IconButton>
          </Tooltip>
          <Tooltip title={t('download')}>
            <IconButton disabled><GetAppOutlinedIcon /></IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} style={{ marginBottom: 32 }}>
            <Typography variant="h3">{renderTitle()}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box className="darin-table-panel">
            <Box className="darin-table-container-wrapper">
              <TableContainer className="darin-table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: 'left', minWidth: '70px', width: '70px' }} >{t('num')}</TableCell>
                      <TableCell style={{ minWidth: '92px', width: '92px' }} >{t('contract name')}</TableCell>
                      <TableCell style={{ minWidth: '92px', width: '92px' }} >{t('contract id')}</TableCell>
                      <TableCell style={{ minWidth: '92px', width: '92px' }} >{t('Item Name')}</TableCell>
                      <TableCell style={{ minWidth: '110px', width: '110px' }} >{t('claim item model')}</TableCell>
                      <TableCell style={{ minWidth: '92px', width: '92px' }} >{t('item_id')}</TableCell>
                      <TableCell style={{ minWidth: '70px', width: '70px' }} >{t('unit')}</TableCell>
                      <TableCell style={{ minWidth: '70px', width: '70px' }} >{t('quantity')}</TableCell>
                      <TableCell style={{ minWidth: '103px', width: '103px' }} >{t('net unit price')}</TableCell>
                      <TableCell style={{ minWidth: '114px', width: '114px' }} >{t('inventory date')}</TableCell>
                      <TableCell style={{ minWidth: '92px', width: '92px' }} >{t('consumption quantity')}</TableCell>
                      <TableCell style={{ minWidth: '118px', width: '118px' }} >{t('consumption amount')}</TableCell>
                      <TableCell style={{ minWidth: '114px', width: '114px' }} >{t('out time')}</TableCell>
                      <TableCell style={{ minWidth: '106px', width: '106px' }} >{t('app num')}</TableCell>
                      <TableCell style={{ minWidth: '92px', width: '92px' }} >{t('quantity available')}</TableCell>
                      <TableCell style={{ minWidth: '200px', width: '200px' }} >{t('notes')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {claimSheets.map((item, idx) => {
                      return <TableRow key={idx}>
                        <TableCell>{getColumnValue(item, 'row_num')}</TableCell>
                        <TableCell>{getColumnValue(item, 'contract_name')}</TableCell>
                        <TableCell>{getColumnValue(item, 'contract_id')}</TableCell>
                        <TableCell>{getColumnValue(item, 'item_name')}</TableCell>
                        <TableCell>{getColumnValue(item, 'item_model')}</TableCell>
                        <TableCell>{getColumnValue(item, 'item_id')}</TableCell>
                        <TableCell>{getColumnValue(item, 'unit')}</TableCell>
                        <TableCell>{getColumnValue(item, 'quantity')}</TableCell>
                        <TableCell>{getColumnValue(item, 'net_unit_price')}</TableCell>
                        <TableCell>{getColumnValue(item, 'inventory_time')}</TableCell>
                        <TableCell>{getColumnValue(item, 'consumption_quantity')}</TableCell>
                        <TableCell>{getColumnValue(item, 'consumption_amount')}</TableCell>
                        <TableCell>{getColumnValue(item, 'out_time')}</TableCell>
                        <TableCell>{getColumnValue(item, 'app_num')}</TableCell>
                        <TableCell>{getColumnValue(item, 'quantity_availabl')}</TableCell>
                        <TableCell>
                          <TextField
                            size="small"
                            value={item.notes}
                            onChange={(e) => {
                              setClaimSheets([
                                ...claimSheets.map((itemOne, idxOne) => {
                                  if (idx === idxOne)
                                    return { ...itemOne, notes: e.target.value }
                                  else return itemOne;
                                })
                              ])
                            }}
                            variant="outlined"
                          />
                        </TableCell>
                      </TableRow>
                    })}
                    <TableRow>
                      <TableCell colSpan={11}>
                        {`${t('total amount')}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box className={clsx("darin-table-panel", classes.userInfoTableContainer)}>
            <TableContainer className="darin-table-container">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{
                      borderBottom: 'none', textAlign: 'left', fontSize: '0.9rem',
                      fontWeight: 600
                    }}>{t('accountant name')}:</TableCell>
                    <TableCell style={{
                      borderBottom: 'none', textAlign: 'left', fontSize: '0.9rem',
                      fontWeight: 600
                    }}></TableCell>
                    <TableCell style={{
                      borderBottom: 'none', textAlign: 'left', fontSize: '0.9rem',
                      fontWeight: 600
                    }}>{t('Leader Name')}:</TableCell>
                    <TableCell style={{
                      borderBottom: 'none', textAlign: 'left', fontSize: '0.9rem',
                      fontWeight: 600
                    }}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{
                      borderBottom: 'none', textAlign: 'left', fontSize: '0.9rem',
                      fontWeight: 600
                    }}>{t('Admin Name')}:</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

      </Grid>
      {loading && <FullLoadingSpinner />}
    </Box>
  )
}

export default ClaimSheetEdit;
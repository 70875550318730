import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tableContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    backgroundColor: '#EFF1F8',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    borderRadius: theme.spacing(1),
    margin: `${theme.spacing(2)}px 0`,
    boxShadow: `rgb(0 0 0 / 20%) 0px 3px 1px -2px, 
       rgb(0 0 0 / 14%) 0px 2px 2px 0px, 
       rgb(0 0 0 / 12%) 0px 1px 5px 0px`,
    '& .table-header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& .MuiTypography-subtitle1': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 15,
      lineHeight: '18px',
      color: '#3C54B9',
    },
    '.MuiTableContainer-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    '& .table-footer': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1),
      '& .table-footer-item': {
        display: 'flex',
        flexDirection: 'column',
        marginRight: theme.spacing(2),
        '& .MuiTypography-subtitle2': {
          marginTop: theme.spacing(1)
        }
      }
    }
  },
  tableContainer: {
    marginTop: 8,
    '& .MuiTableCell-head': {
      border: '1px solid rgba(0, 0, 0, 0.87)',
      whiteSpace: 'nowrap'
    },
    '& .MuiTableCell-body': {
      border: '1px solid rgba(0, 0, 0, 0.87)'
    }
  },
  subtitleBtn: {
    color: '#3C54B9',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '18px',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    minWidth: 'auto',
    marginLeft: 0,
    '&:hover': {
      textDecoration: 'underline',

    }
  },
  tablePagination: {
    '& .MuiToolbar-regular': {
      paddingLeft: 0
    },
    '& .MuiTablePagination-selectRoot': {
      marginRight: 16
    }
  }
}))

const FormTableMessage = ({ messageContent }) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const tables = [];
  const getFormId = (strFormID) => {
    return strFormID.substr(strFormID.indexOf('编号:') + 3, strFormID.legth);
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  Object.keys(messageContent.content).forEach((item, idx) => {
    const tableItem = messageContent.content[item];
    tables.push(<Box className={classes.tableContentWrapper} key={idx}>
      <Box className="table-header">
        <Button
          className={classes.subtitleBtn}
          onClick={() => { history.push(`/application/${getFormId(tableItem.subtitle)}`) }}
        >
          {tableItem.title}
        </Button>
        <Typography variant="subtitle2">{tableItem.subtitle}</Typography>
      </Box>
      <TableContainer className={classes.tableContainer}>
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[5, 10, 25]}
          count={tableItem.item_list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          component="div"
        />
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableItem.attributes.map(itemAttribute => {
                return <TableCell key={itemAttribute}>{itemAttribute}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableItem.item_list.map((itemRow, idxOne) => {
              if (idxOne >= (page + 1) * rowsPerPage || idxOne < page * rowsPerPage)
                return null;
              return <TableRow key={idxOne}>
                {itemRow.map((itemCell, idx2) => {
                  return <TableCell key={idx2}>{itemCell}</TableCell>
                })}
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="table-footer">
        <Box className="table-footer-item">
          <Typography variant="subtitle2">{t('Leader Name')}: {tableItem.leader_name || ''}</Typography>
          <Typography variant="subtitle2">{t('Leader Date')}: {tableItem.leader_date || ''}</Typography>
        </Box>
        <Box className="table-footer-item">
          <Typography variant="subtitle2">{t('Employee Name')}: {tableItem.employee_name.user_name || ''}</Typography>
          <Typography variant="subtitle2">{t('Employee Date')}: {tableItem.employee_date || ''}</Typography>
        </Box>
        <Box className="table-footer-item">
          <Typography variant="subtitle2">{t('Admin Name')}: {tableItem?.admin_name?.user_name || ''}</Typography>
          <Typography variant="subtitle2">{t('Date')}: {tableItem?.admin_date || ''}</Typography>
        </Box>
      </Box>
    </Box>)
  })

  return (
    <>{tables}</>
  )
}

export default React.memo(FormTableMessage);
export const MESSAGE_OWNER = {
  RECEIVED: 'Received',
  SENDER: 'Sender',
};

export const QUESTION_TYPE = {
  TEXT: 'text',
  BUTTON: 'button',
  LIST: 'list',
  IMAGE: 'image',
  TABLE: 'table'
};

export const SENTENCE_DUMP_ANSWERS = [
  'Hello',
  'Dump Sentence Answer2',
  'Dump Sentence Answer3',
  'Dump Sentence Answer4',
];

export const USER_ROLE = {
  EMPLOYEE: 'employee',
  ADMIN: 'admin',
  LEADER: 'leader',
  UNOFFICIAL: 'unofficial'
}

export const APPLICATION_STATUS_VALUE = {
  '待审批': 0,
  '拒绝': 1,
  '通过': 2,
  '已领取': 3,
}

export const TABLE_TYPE = {
  FORM_TABLE: 'Form Table',
  ATTRIBUTE_TABLE: 'Attribute Table'
}
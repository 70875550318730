import React, { useState } from 'react';
import {
  makeStyles,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Typography
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  tableContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    backgroundColor: '#EFF1F8',
    padding: '16px 16px',
    borderRadius: theme.spacing(1),
    margin: '8px 0',
  },
  '& .MuiTableContainer-root': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  tableContainer: {
    marginTop: 8,
    maxHeight: 331,
    '& .MuiTable-root': {
      width: 'calc(100% - 1px)'
    },
    '& .MuiTableCell-head': {
      border: '1px solid rgba(0, 0, 0, 0.87)',
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
    '& .MuiTableCell-body': {
      border: '1px solid rgba(0, 0, 0, 0.87)'
    }
  },
  tableFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    position: 'relative',
    '& .totalPageShow': {
      position: 'absolute',
      right: 0,
      color: 'grey'
    }
  }
}))

const PAGE_PER_COUNT = 5;

const ListTableMessage = ({ messageContent, clickRow }) => {
  const classes = useStyles();

  const [curPage, setCurPage] = useState(0);

  return (<Box className={classes.tableContentWrapper}>
    <TableContainer className={classes.tableContainer}>
      <Table>
        {/* {messageContent.content.length > 0 && <TableHead>
          <TableRow>
            {Object.keys(messageContent.content[0]).map((item, idx) => {
              if (idx >= 1) return true;
              return <TableCell key={idx} style={{ textAlign: 'left' }}>{item}</TableCell>
            })}
          </TableRow>
        </TableHead>} */}
        <TableBody>
          {messageContent.content.length > 0 && <>
            {messageContent.content.map((item, idx) => {
              return <TableRow key={idx + 1} hover style={{ cursor: 'pointer' }}>
                {Object.keys(item).map((itemOne, idxOne) => {
                  if (idxOne >= 1) return null;
                  if (idx < curPage * PAGE_PER_COUNT || idx >= (curPage + 1) * PAGE_PER_COUNT) return null;
                  return <TableCell key={`${idx}-${idxOne}`} onClick={() => { clickRow(item[itemOne]) }}>{item[itemOne]}</TableCell>
                })}
              </TableRow>
            })}
          </>}
        </TableBody>
      </Table>
      <Box className={classes.tableFooter}>
        <IconButton
          onClick={() => { setCurPage(curPage - 1) }}
          disabled={curPage <= 0}>
          <ChevronLeftIcon color={curPage >= 1 ? 'primary' : ''} />
        </IconButton>
        <Typography variant="subtitle1">{curPage + 1}</Typography>
        <IconButton
          onClick={() => { setCurPage(curPage + 1) }}
          disabled={curPage + 1 >= Math.ceil(messageContent.content.length / PAGE_PER_COUNT)}
        >
          <ChevronRightIcon
            color={curPage + 1 >= Math.ceil(messageContent.content.length / PAGE_PER_COUNT) ? '' : 'primary'}
          />
        </IconButton>
        <Typography className="totalPageShow" variant="subtitle1">{`${curPage + 1} of ${Math.ceil(messageContent.content.length / PAGE_PER_COUNT)}`}</Typography>
      </Box>
    </TableContainer>
  </Box>)
}

export default React.memo(ListTableMessage);
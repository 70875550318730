import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Tooltip,
  IconButton,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import FullLoadingSpinner from '../../../components/FullLoadingSpinner';
import { showInventorySheetAPI, submitInventorySheetAPI } from '../../../apis';
import { getColumnValue } from '../../../utils'
import { routes } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .page-title-wrapper': {
      marginBottom: 0,
    }
  },
}))

const InventorySheetDetail = () => {

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { month, title } = useParams();

  const [inventorySheets, setInventorySheets] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [orderBy, setOrderBy] = useState({
    name: '',
    value: 'asc'
  })

  let componentMounted = true;

  useEffect(() => {
    if (month) {
      setLoading(true);
      showInventorySheetAPI(month).then(res => {
        if (componentMounted) {
          setLoading(false);
          setInventorySheets([...res.data.data]);
          setSubmitted(res.data.submit)
        }
      }).catch(err => {
        if (componentMounted) {
          setLoading(false);
          setInventorySheets([]);
        }
      })
    }
  }, [month])

  const exportCSV = () => {
    let recordBody = '';
    inventorySheets.forEach(item => {
      recordBody += `<tr>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 70px">${getColumnValue(item, 'row_num')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'item_name')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'item_model')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'item_id')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 110px">${getColumnValue(item, 'unit')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'quantity')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 70px">${getColumnValue(item, 'net_unit_price')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 70x">${getColumnValue(item, 'current_total_price')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 80px">${getColumnValue(item, 'inventory_time')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'quantity_available')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'storage_location')}</td>        
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 70px">${getColumnValue(item, 'notes')}</td>
      </tr>`
    });

    const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
    const worksheet = 'Sheet1'
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:x="urn:schemas-microsoft-com:office:excel"
    xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
    <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
    <x:Name>${worksheet}</x:Name>
    <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
    </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
    </head><body>
    <body link="#0563C1" vlink="#954F72">
      <table style="font-family: SimSun">
        <thead>
          <tr>
            <th colspan="12" style="font-size: 18pt; height: 25pt; text-align: center;  vertical-align: middle; border: 0.5pt solid black;">
              ${month} - ${title}
            </th>
          </tr>
        </thead>
        <tbody>          
          <tr>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('num')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Item Name')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Item Model')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('item_id')}</td>            
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('unit')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('quantity')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('net unit price')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('current total price')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('inventory date')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('quantity available')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('storage location')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('notes')}</td>            
          </tr>
          ${recordBody}
        </tbody>
      </table>
    </body></html>
    `;

    const link = document.createElement("a");
    link.href = uri + base64(template);
    link.target = "_blank";
    link.download = `${t('Inventory Sheets')}_${month}_${title}.xls`;
    link.click();
  }

  const getStorageValue = (storageLocation, nIndex) => {
    console.log(storageLocation);
    if (!storageLocation) return t('null');
    const parseData = JSON.parse(storageLocation);
    if (parseData.length - 1 >= nIndex)
      return parseData[nIndex];
    else return t('null')
  }

  const submitInventorySheet = () => {
    setLoading(true);
    submitInventorySheetAPI(month).then(res => {
      setLoading(false);
      if (res.data.status === 'success') {
        Swal.fire({
          title: t('Success'),
          text: t('Inventory Sheet has been submitted'),
          icon: 'success',
          confirmButtonText: t('Ok')
        })
      } else {
        Swal.fire({
          title: `${t('Error')}!`,
          text: t('Inventory Sheet submit has been failed'),
          icon: 'error',
          confirmButtonText: t('Ok')
        })
      }
      setSubmitted(true);
    }).catch(err => {
      setLoading(false);
      Swal.fire({
        title: `${t('Error')}!`,
        text: t('Inventory Sheet submit has been failed'),
        icon: 'error',
        confirmButtonText: t('Ok')
      })
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getSortedData = () => {
    if (!orderBy.name) return inventorySheets;
    if (orderBy.name && orderBy.value) {
      return inventorySheets.sort((a, b) => {
        const orderValue = orderBy.value === 'desc' ? -1 : 1;
        if (b[orderBy.name] > a[orderBy.name]) return -1 * orderValue;
        if (b[orderBy.name] < a[orderBy.name]) return 1 * orderValue;
        else return 0;
      })
    }
    else return inventorySheets;
  }

  return (
    <Box className={clsx(classes.root, 'main-page-content')}>
      <Grid container>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Breadcrumbs className="darin-breadcrumbs">
            <Link
              onClick={() => { setInventorySheets([]); history.push(routes.INVENTORYSHEET); }}
              color="inherit"
            >
              {t('Inventory Sheets')}
            </Link>
            <Typography>{t('time')}: {month}</Typography>
          </Breadcrumbs>
          <Tooltip title={t('Edit')}>
            <IconButton
              onClick={() => {
                history.push(`/inventory/inventory-sheets/edit/${month}/${title}`)
              }}
              disabled={submitted}
            >
              <CreateOutlinedIcon color={submitted ? '' : 'primary'} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('submit')}>
            <IconButton onClick={submitInventorySheet} disabled={submitted}>
              <SendOutlinedIcon color={submitted ? '' : 'primary'} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('download')}>
            <IconButton onClick={exportCSV} >
              <GetAppOutlinedIcon color='primary' />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} style={{ marginBottom: 24 }}>
            <Typography variant="h3">{`${month} - ${title}`}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box className="darin-table-panel">
            <Box className="table-action-wrapper">
              <TablePagination
                className="table-pagination-wrapper"
                rowsPerPageOptions={[5, 10, 25]}
                count={inventorySheets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={t('Rows per page')}
                component="div"
              />
            </Box>
            <Box className="darin-table-container-wrapper">
              <TableContainer className="darin-table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell rowSpan={2} style={{ textAlign: 'left', minWidth: '70px', width: '70px' }} >{t('num')}</TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '132px', width: '132px' }}>
                        <TableSortLabel
                          active={orderBy.name === 'item_name'}
                          direction={orderBy.name === 'item_name' ? orderBy.value : 'asc'}
                          onClick={() => {
                            if (orderBy.name === 'item_name') {
                              if (orderBy.value === 'desc')
                                setOrderBy({
                                  name: 'item_name',
                                  value: 'asc'
                                })
                              else
                                setOrderBy({
                                  name: 'item_name',
                                  value: 'desc'
                                })
                            } else {
                              setOrderBy({
                                name: 'item_name',
                                value: 'asc'
                              })
                            }
                          }}
                        >
                          {t('Item Name')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '110px', width: '110px' }}>
                        <TableSortLabel
                          active={orderBy.name === 'item_model'}
                          direction={orderBy.name === 'item_model' ? orderBy.value : 'asc'}
                          onClick={() => {
                            if (orderBy.name === 'item_model') {
                              if (orderBy.value === 'desc')
                                setOrderBy({
                                  name: 'item_model',
                                  value: 'asc'
                                })
                              else
                                setOrderBy({
                                  name: 'item_model',
                                  value: 'desc'
                                })
                            } else {
                              setOrderBy({
                                name: 'item_model',
                                value: 'asc'
                              })
                            }
                          }}
                        >
                          {t('Item Model')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '132px', width: '132px' }}>
                        {t('item_id')}
                      </TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '110px', width: '110px' }}>
                        {t('unit')}
                      </TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '110px', width: '110px' }}>
                        <TableSortLabel
                          active={orderBy.name === 'quantity'}
                          direction={orderBy.name === 'quantity' ? orderBy.value : 'asc'}
                          onClick={() => {
                            if (orderBy.name === 'quantity') {
                              if (orderBy.value === 'desc')
                                setOrderBy({
                                  name: 'quantity',
                                  value: 'asc'
                                })
                              else
                                setOrderBy({
                                  name: 'quantity',
                                  value: 'desc'
                                })
                            } else {
                              setOrderBy({
                                name: 'quantity',
                                value: 'asc'
                              })
                            }
                          }}
                        >
                          {t('quantity')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '143px', width: '143px' }}>
                        <TableSortLabel
                          active={orderBy.name === 'net_unit_price'}
                          direction={orderBy.name === 'net_unit_price' ? orderBy.value : 'asc'}
                          onClick={() => {
                            if (orderBy.name === 'net_unit_price') {
                              if (orderBy.value === 'desc')
                                setOrderBy({
                                  name: 'net_unit_price',
                                  value: 'asc'
                                })
                              else
                                setOrderBy({
                                  name: 'net_unit_price',
                                  value: 'desc'
                                })
                            } else {
                              setOrderBy({
                                name: 'net_unit_price',
                                value: 'asc'
                              })
                            }
                          }}
                        >
                          {t('net unit price')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '142px', width: '142px' }}>
                        <TableSortLabel
                          active={orderBy.name === 'current_total_price'}
                          direction={orderBy.name === 'current_total_price' ? orderBy.value : 'asc'}
                          onClick={() => {
                            if (orderBy.name === 'current_total_price') {
                              if (orderBy.value === 'desc')
                                setOrderBy({
                                  name: 'current_total_price',
                                  value: 'asc'
                                })
                              else
                                setOrderBy({
                                  name: 'current_total_price',
                                  value: 'desc'
                                })
                            } else {
                              setOrderBy({
                                name: 'current_total_price',
                                value: 'asc'
                              })
                            }
                          }}
                        >
                          {t('current total price')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '124px', width: '124px' }}>
                        <TableSortLabel
                          active={orderBy.name === 'inventory_time'}
                          direction={orderBy.name === 'inventory_time' ? orderBy.value : 'asc'}
                          onClick={() => {
                            if (orderBy.name === 'inventory_time') {
                              if (orderBy.value === 'desc')
                                setOrderBy({
                                  name: 'inventory_time',
                                  value: 'asc'
                                })
                              else
                                setOrderBy({
                                  name: 'inventory_time',
                                  value: 'desc'
                                })
                            } else {
                              setOrderBy({
                                name: 'inventory_time',
                                value: 'asc'
                              })
                            }
                          }}
                        >
                          {t('inventory date')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '120px', width: '120px' }}>
                        <TableSortLabel
                          active={orderBy.name === 'quantity_available'}
                          direction={orderBy.name === 'quantity_available' ? orderBy.value : 'asc'}
                          onClick={() => {
                            if (orderBy.name === 'quantity_available') {
                              if (orderBy.value === 'desc')
                                setOrderBy({
                                  name: 'quantity_available',
                                  value: 'asc'
                                })
                              else
                                setOrderBy({
                                  name: 'quantity_available',
                                  value: 'desc'
                                })
                            } else {
                              setOrderBy({
                                name: 'quantity_available',
                                value: 'asc'
                              })
                            }
                          }}
                        >
                          {t('quantity available')}
                        </TableSortLabel>
                      </TableCell>
                      {/* <TableCell colSpan={3} style={{ minWidth: '150px', width: '150px' }}>{t('storage location')}</TableCell> */}
                      <TableCell style={{ minWidth: '190px', width: '190px' }}>
                        <TableSortLabel
                          active={orderBy.name === 'storage_location'}
                          direction={orderBy.name === 'storage_location' ? orderBy.value : 'asc'}
                          onClick={() => {
                            if (orderBy.name === 'storage_location') {
                              if (orderBy.value === 'desc')
                                setOrderBy({
                                  name: 'storage_location',
                                  value: 'asc'
                                })
                              else
                                setOrderBy({
                                  name: 'storage_location',
                                  value: 'desc'
                                })
                            } else {
                              setOrderBy({
                                name: 'storage_location',
                                value: 'asc'
                              })
                            }
                          }}
                        >
                          {t('storage location')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '70px', width: '70px' }} >{t('notes')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getSortedData().map((item, idx) => {
                      if (idx >= (page + 1) * rowsPerPage || idx < page * rowsPerPage)
                        return null;
                      return <TableRow key={idx}>
                        <TableCell>{getColumnValue(item, 'row_num')}</TableCell>
                        <TableCell style={{ paddingRight: 40 }}>{getColumnValue(item, 'item_name')}</TableCell>
                        <TableCell>{getColumnValue(item, 'item_model')}</TableCell>
                        <TableCell>{getColumnValue(item, 'item_id')}</TableCell>
                        <TableCell style={{ paddingRight: 40 }}>{getColumnValue(item, 'unit')}</TableCell>
                        <TableCell style={{ paddingRight: 40 }}>{getColumnValue(item, 'quantity')}</TableCell>
                        <TableCell style={{ paddingRight: 40 }}>{getColumnValue(item, 'net_unit_price')}</TableCell>
                        <TableCell style={{ paddingRight: 40 }}>{getColumnValue(item, 'current_total_price')}</TableCell>
                        <TableCell style={{ paddingRight: 40 }}>{getColumnValue(item, 'inventory_time')}</TableCell>
                        <TableCell style={{ paddingRight: 40 }}>{getColumnValue(item, 'quantity_available')}</TableCell>
                        <TableCell style={{ paddingRight: 40 }}>{getColumnValue(item, 'storage_location')}</TableCell>
                        {/* <TableCell>{getStorageValue(item.storage_location, 0)}</TableCell>
                        <TableCell>{getStorageValue(item.storage_location, 1)}</TableCell>
                        <TableCell>{getStorageValue(item.storage_location, 2)}</TableCell> */}
                        <TableCell>{getColumnValue(item, 'notes')}</TableCell>
                      </TableRow>
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Grid>

      </Grid>
      {loading && <FullLoadingSpinner />}
    </Box>
  )
}

export default InventorySheetDetail;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  makeStyles,
  Grid,
  Box,
  Breadcrumbs,
  Link,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import FullLoadingSpinner from '../../../components/FullLoadingSpinner';
import { getApplicationAPI } from '../../../apis';
import { routes } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .page-title-wrapper': {
      justifyContent: 'center',
    },
    '& .darin-table-panel': {
      '& .MuiTypography-h6': {
        marginLeft: 'auto',
        marginBottom: 8,
      }
    }
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textAlign: 'left'
  },
  managerTable: {
    marginTop: 22
  }
}))

const ApplicationDetail = () => {

  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { form_id } = useParams();
  const [applicationData, setApplicationData] = useState([]);
  const [loading, setLoading] = useState(true);

  let componentMounted = true;

  useEffect(() => {
    if (form_id) {
      getApplicationAPI({ formId: form_id }).then(res => {
        if (componentMounted) {
          setApplicationData([...res]);
          setLoading(false);
        }
      }).catch(err => {
        if (componentMounted) setLoading(false);
        console.log('Application Detail Page');
        console.log(err);
      })
    }
    return () => {
      componentMounted = false;
    }
  }, [form_id])

  const exportCSV = () => {

    let recordBody = '';
    applicationData.forEach(item => {
      recordBody += `<tr>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.row_num}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 180px">${item.item_name}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.item_model}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.item_id}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.item_count}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">0</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">0</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 180x">${item.item_usage}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.item_notes}</td>
      </tr>`
    });

    const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
    const worksheet = 'Sheet1'
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:x="urn:schemas-microsoft-com:office:excel"
    xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
    <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
    <x:Name>${worksheet}</x:Name>
    <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
    </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
    </head><body>
    <body link="#0563C1" vlink="#954F72">
      <table style="font-family: SimSun">
        <thead>
          <tr>
            <th colspan="9" style="font-size: 18pt; height: 25pt; text-align: center;  vertical-align: middle; border: 0.5pt solid black;">
              ${t('Application Detail Title')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="9" style="font-size: 14pt;  text-align: right; height: 18pt; vertical-align: middle; border: 0.5pt solid black;">
              ${t('form_id')}:${form_id}
            </td>
          </tr>
          <tr>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('No.')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Item Name')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Item Model')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Item ID')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Count')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Net Price')} (${t('Wen')})</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Total Price')} (${t('Wen')})</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('usage')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Notes')}</td>
          </tr>
          ${recordBody}
          <tr rowspan="2" style="height: 40pt">
            <td colspan="9" style="font-size: 14pt; vertical-align: top; text-align: left;">
              &nbsp;&nbsp;&nbsp;${i18n.language === 'zh' ? '填写说明：出库物资应明确用途，如XX#风机' : 'Instructions: please clarify the usage, such as XX# fan'}
            </td>
          </tr>          
          <tr>
            <td style="font-size: 14pt; vertical-align: middle; text-align: left;">${t('Leader Name')}</td>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: left;"></td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: left;">${t('Employee Name')}</td>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: left;"></td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: left;">${t('Admin Name')}</td>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: left;"></td>
          </tr>
          <tr>
            <td style="font-size: 14pt; vertical-align: middle; text-align: left;">${t('Date')}</td>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: left;"></td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: left;">${t('Date')}</td>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: left;"></td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: left;">${t('Date')}</td>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: left;"></td>
          </tr>
        </tbody>
      </table>
    </body></html>
    `;

    const link = document.createElement("a");
    link.href = uri + base64(template);
    link.target = "_blank";
    link.download = `出库单_编号${form_id}.xls`;
    link.click();
  }

  return (
    <Box className={clsx(classes.root, "main-page-content")}>
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Breadcrumbs className="darin-breadcrumbs">
            <Link onClick={() => { history.push(routes.APPLICATIONS); }} color="inherit">{t('Application')}</Link>
            <Typography>{t('Application FormId')}: {form_id}</Typography>
          </Breadcrumbs>
          <Tooltip title={t('download')}>
            <IconButton onClick={exportCSV}>
              <GetAppOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Box className="page-title-wrapper">
            <Typography variant="h3" className="page-title" onClick={() => history.push(routes.APPLICATIONS)}>
              {t('Application Detail Title')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="darin-table-panel">
            <Box className='table-action-wrapper'>
              <Typography variant="h6">{t('form_id')}: {form_id}</Typography>
            </Box>
            <TableContainer className="darin-table-container">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: 'left' }}>{t('No.')}</TableCell>
                    <TableCell>{t('Item Name')}</TableCell>
                    <TableCell>{t('Item Model')}</TableCell>
                    <TableCell>{t('Item ID')}</TableCell>
                    <TableCell>{t('Count')}</TableCell>
                    <TableCell>{t('Net Price')}{i18n.language === 'zh' ? `(${t('Wen')})` : ''}</TableCell>
                    <TableCell>{t('Total Price')}{i18n.language === 'zh' ? `(${t('Wen')})` : ''}</TableCell>
                    <TableCell>{t('usage')}</TableCell>
                    <TableCell>{t('Notes')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applicationData.map((item, idx) => {
                    return <TableRow key={idx}>
                      <TableCell style={{ textAlign: 'left' }}>{item.row_num}</TableCell>
                      <TableCell>{item.item_name}</TableCell>
                      <TableCell>{item.item_model}</TableCell>
                      <TableCell>{item.item_id}</TableCell>
                      <TableCell>{item.item_count}</TableCell>
                      <TableCell>{item.net_unit_price}</TableCell>
                      <TableCell>{item.total_price}</TableCell>
                      <TableCell>{item.item_usage}</TableCell>
                      <TableCell>{item.item_notes}</TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              variant="h6"
              style={{ marginTop: 8, textAlign: 'left', paddingLeft: '20px', fontSize: '0.875rem' }}
            >
              {i18n.language === 'zh' ? '填写说明：出库物资应明确用途，如XX#风机' : 'Instructions: please clarify the usage, such as XX# fan'}
            </Typography>
          </Box>
          <Box className={clsx('darin-table-panel', classes.managerTable)}>
            <TableContainer className='darin-table-container'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{
                      textAlign: 'left',
                      width: i18n.language === 'zh' ? 100 : 160,
                      borderBottom: 'none',
                      fontWeight: 600,
                      fontSize: '0.9rem'
                    }}>
                      {t('Leader Name')}:
                    </TableCell>
                    <TableCell style={{
                      textAlign: 'left',
                      width: 200,
                      borderBottom: 'none',
                      fontSize: '0.9rem'
                    }}>
                    </TableCell>
                    <TableCell style={{
                      textAlign: 'left',
                      width: i18n.language === 'zh' ? 100 : 160,
                      borderBottom: 'none',
                      fontWeight: 600,
                      fontSize: '0.9rem'
                    }}>
                      {t('Employee Name')}:
                    </TableCell>
                    <TableCell style={{
                      textAlign: 'left',
                      width: 200,
                      borderBottom: 'none',
                      fontSize: '0.9rem'
                    }}>
                    </TableCell>
                    <TableCell style={{
                      textAlign: 'left',
                      width: i18n.language === 'zh' ? 100 : 160,
                      borderBottom: 'none',
                      fontWeight: 600,
                      fontSize: '0.9rem'
                    }}>
                      {t('Admin Name')}:
                    </TableCell>
                    <TableCell style={{
                      textAlign: 'left',
                      width: 200,
                      borderBottom: 'none',
                      fontSize: '0.9rem'
                    }}>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{
                      textAlign: 'left',
                      width: i18n.language === 'zh' ? 100 : 160,
                      borderBottom: 'none',
                      fontWeight: 600,
                      fontSize: '0.9rem'
                    }}>
                      {t('Date')}:
                    </TableCell>
                    <TableCell style={{
                      textAlign: 'left',
                      width: 200,
                      borderBottom: 'none',
                      fontSize: '0.9rem'
                    }}>
                    </TableCell>
                    <TableCell style={{
                      textAlign: 'left',
                      width: i18n.language === 'zh' ? 100 : 160,
                      borderBottom: 'none',
                      fontWeight: 600,
                      fontSize: '0.9rem'
                    }}>
                      {t('Date')}:
                    </TableCell>
                    <TableCell style={{
                      textAlign: 'left',
                      width: 200,
                      borderBottom: 'none',
                      fontSize: '0.9rem'
                    }}>
                    </TableCell>
                    <TableCell style={{
                      textAlign: 'left',
                      width: i18n.language === 'zh' ? 100 : 160,
                      borderBottom: 'none',
                      fontWeight: 600,
                      fontSize: '0.9rem'
                    }}>
                      {t('Date')}:
                    </TableCell>
                    <TableCell style={{
                      textAlign: 'left',
                      width: 200,
                      borderBottom: 'none',
                      fontSize: '0.9rem'
                    }}>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
      {loading && <FullLoadingSpinner />}
    </Box>)
}

export default ApplicationDetail;
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	makeStyles,
	withStyles,
	Grid,
	TextField,
	Button,
	Dialog,
	Typography,
	IconButton,
	Divider,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { USER_ROLE } from '../../constants';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 0
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	form: {
		marginTop: theme.spacing(2),
		'& .MuiFormControl-root': {
			width: '100%'
		}
	},
	formFooter: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(1),
		'& .MuiButtonBase-root': {
			marginLeft: theme.spacing(1)
		}
	}
}));

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {

	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);


const AccountModal = ({ isShow, accountInfo, createAccount, hideModal }) => {
	const { t } = useTranslation();

	const classes = useStyles();
	const [account, setAccount] = useState({
		user_id: -1,
		first_name: '',
		last_name: '',
		user_name: '',
		group_name: 'admin'
	})

	useEffect(() => {
		if (accountInfo && accountInfo.user_id !== -1) {
			setAccount({
				...accountInfo
			})
		}
	}, [accountInfo])

	const onSubmit = async (event) => {
		event.preventDefault();
		createAccount({
			user_id: account.user_id,
			first_name: account.first_name,
			last_name: account.last_name,
			user_name: account.user_name,
			manager_name: `${account.first_name} ${account.last_name}`,
			group_name: account.group_name,
			status: 'active',
		})
		hideModal();
	}

	return (
		<>
			<Dialog
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={isShow}
				onClose={hideModal}
			>
				<DialogTitle onClose={hideModal}>
					{!account || account.user_id === -1 ? t('create account') : t('update account')}
				</DialogTitle>
				<DialogContent dividers>
					<form className={classes.form} onSubmit={onSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									id="last-name"
									name="last-name"
									label={t("Last Name")}
									type="text"
									InputLabelProps={{
										shrink: true,
									}}
									variant="outlined"
									value={account.last_name}
									onChange={(event) => {
										setAccount({
											...account,
											last_name: event.target.value
										})
									}}
									required
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="first-name"
									name="first-name"
									label={t("First Name")}
									type="text"
									InputLabelProps={{
										shrink: true,
									}}
									variant="outlined"
									value={account.first_name}
									onChange={(event) => {
										setAccount({
											...account,
											first_name: event.target.value
										})
									}}
									required
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="user-name"
									name="user-name"
									label={t("User Name")}
									type="text"
									InputLabelProps={{
										shrink: true,
									}}
									variant="outlined"
									value={account.user_name}
									onChange={(event) => {
										setAccount({
											...account,
											user_name: event.target.value
										})
									}}
									required
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="group-select"
									select
									label={t("Group")}
									value={account.group_name}
									onChange={(event) => {
										setAccount({
											...account,
											group_name: event.target.value
										})
									}}
									SelectProps={{ native: true }}
									variant="outlined"
								>
									<option key={USER_ROLE.ADMIN} value={USER_ROLE.ADMIN}>{t(USER_ROLE.ADMIN)}</option>
									<option key={USER_ROLE.EMPLOYEE} value={USER_ROLE.EMPLOYEE}>{t(USER_ROLE.EMPLOYEE)}</option>
									<option key={USER_ROLE.UNOFFICIAL} value={USER_ROLE.UNOFFICIAL}>{t(USER_ROLE.UNOFFICIAL)}</option>
								</TextField>
							</Grid>
							<Grid item xs={12}><Divider style={{ marginTop: 16 }} /></Grid>
							<Grid item xs={12} className={classes.formFooter}>
								<Button
									color="primary"
									variant="contained"
									type="submit"
								>
									{account.user_id === -1 ? t('Create') : t('Update')}
								</Button>
								<Button
									color="primary"
									variant="outlined"
									onClick={() => {
										hideModal();
									}}
								>
									{t('Cancel')}
								</Button>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default AccountModal;
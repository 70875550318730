import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
	makeStyles,
	Box,
	Button,
	MenuItem,
	Paper,
	Divider
} from '@material-ui/core';
import PersonSvg from '../../assets/images/person.svg';
import { routes } from '../../constants';
import { authState } from '../../atoms';
import { useEventListener } from '../../hooks';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	userButton: {
		borderRadius: 0,
		height: '100%',
		'& .MuiButton-label': {
			display: 'flex',
			alignItems: 'center',
			position: 'relative',
		},
	},
	userAvatar: {
		width: 40,
		height: 40,
		borderRadius: 20,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	dropdownWrapper: {
		display: 'none',
		opacity: 0,
		transition: 'opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		width: 200,
		position: 'absolute',
		top: '100%',
		left: '-100px',
		'& .MuiMenuItem-root': {
			justifyContent: 'flex-start',
			height: 55,
			fontStyle: 'normal',
			fontWeight: 600,
			fontSize: 14,
			lineHeight: '24px',
			color: '#333',
			paddingLeft: 20,
			paddingRight: 20,
		}
	},
	dropdownWrapperOpen: {
		display: 'block !important',
		opacity: '1 !important'
	}
}));

const AuthDropdown = () => {

	const { t } = useTranslation();

	const classes = useStyles();
	const history = useHistory();

	const [auth, setAuth] = useRecoilState(authState);
	const [open, setOpen] = useState(false);

	const checkMousePos = (e) => {
		if (!open) return;

		const menu = document.getElementById("authdropdown-paper");
		const menuClientRects = menu.getClientRects();
		if (menuClientRects.length > 0) {
			const menuBoundary = {
				left: menuClientRects[0].x,
				top: menuClientRects[0].y,
				right: menuClientRects[0].x + menuClientRects[0].width,
				bottom: menuClientRects[0].y + menuClientRects[0].height
			};
			if (
				e.clientX >= menuBoundary.left &&
				e.clientX <= menuBoundary.right &&
				e.clientY <= menuBoundary.bottom &&
				e.clientY >= menuBoundary.top
			) {
				return;
			}
		}

		const dropdownBtn = document.getElementById('authdropdown-button');
		const dropdownBtnClientRects = dropdownBtn.getClientRects();
		if (dropdownBtnClientRects.length > 0) {
			const btnBoundary = {
				left: dropdownBtnClientRects[0].x,
				top: dropdownBtnClientRects[0].y,
				right: dropdownBtnClientRects[0].x + dropdownBtnClientRects[0].width,
				bottom: dropdownBtnClientRects[0].y + dropdownBtnClientRects[0].height
			}
			if (
				e.clientX >= btnBoundary.left &&
				e.clientX <= btnBoundary.right &&
				e.clientY <= btnBoundary.bottom &&
				e.clientY >= btnBoundary.top
			) {
				return;
			}
		}
		setOpen(false)
	}

	useEventListener('mousemove', checkMousePos);

	const logout = () => {
		localStorage.removeItem('auth');
		setAuth(null);
		setTimeout(() => {
			history.push(routes.LOGIN);
		}, 100);
	};

	return (<Box className={classes.root}>
		<Button id="authdropdown-button"
			className={classes.userButton}
			onMouseOver={() => { setOpen(true) }}
		>
			<Box className={classes.userAvatar}>
				<img
					src={auth.profileImg ? auth.profileImg : PersonSvg}
					style={{ width: auth.profileImg ? '100%' : '60%' }}
				/>
			</Box>
		</Button>
		<Paper
			id="authdropdown-paper"
			className={[classes.dropdownWrapper, open ? classes.dropdownWrapperOpen : ''].join(' ')}
		>
			<MenuItem>
				{auth.user_name}<br />
				{t(auth.role)}
			</MenuItem>
			<Divider />
			<MenuItem onClick={() => {
				setOpen(false);
				history.push(routes.MYACCOUNT)
			}}>
				{t("My Account")}
			</MenuItem>
			<MenuItem onClick={() => { setOpen(false); logout(); }}>
				{t("Sign out")}
			</MenuItem>
		</Paper>
	</Box>)
}

export default AuthDropdown;
import axiosInstance from '../lib/axios';

export const loginAPI = ({ user_name, password }) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance
        .post(
          `/login`,
          {
            account: [
              {
                user_name: user_name,
                passwd: password
              }
            ]
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { routes } from '../constants';

const PublicRoute = ({ component: Component, restricted = false, ...rest }) => {

  const localAuth = localStorage.getItem('auth');

  const render = props => {
    if (localAuth && restricted) {
      return <Redirect to={routes.HOME} />;
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={render} />;
};
export default PublicRoute;

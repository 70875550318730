import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
	makeStyles,
	Box,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemIcon
} from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { routes, USER_ROLE } from '../../constants';
import { authState } from '../../atoms';
import { useEventListener } from '../../hooks';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	dropdownWrapper: {
		display: 'none',
		opacity: 0,
		width: 165,
		position: 'absolute',
		top: '100%',
		right: 0,
		backgroundColor: 'white',
		boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
		borderRadius: 4,
		transition: 'opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

		'& .MuiListItem-root': {
			color: '#333',
			height: 55,
			fontSize: 14,
			fontStyle: 'normal',
			fontWeight: 600,
			cursor: 'pointer',
			'& .MuiListItemIcon-root': {
				minWidth: 30
			},
			'& .MuiListItemText-root': {
				'& .MuiTypography-body1': {
					color: '#333',
					fontSize: 14,
					fontStyle: 'normal',
					fontWeight: 600,
				}
			},
		},
		'& .parent-menu-item': {
			position: 'relative',
			overflow: 'visible',
			'& .sub-menu': {
				display: 'none',
				opacity: 0,
				transition: 'opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
				position: 'absolute',
				right: '100%',
				top: 0,
				backgroundColor: 'white',
				boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
				borderRadius: 4,
			},
			'&:hover': {
				'& .sub-menu': {
					display: 'block',
					opacity: 1,
				}
			}
		}
	},
	dropdownWrapperOpen: {
		display: 'block !important',
		opacity: '1 !important'
	},
	iconButton: {
		borderRadius: 0,
		height: '100%',
		'& .MuiSvgIcon-root': {
			width: 30,
			height: 30,
		},
	},
}));

const SettingsDropdown = () => {
	const { t, i18n } = useTranslation();

	const classes = useStyles();
	const history = useHistory();

	const auth = useRecoilValue(authState);

	const [open, setOpen] = useState(null);

	const checkMousePos = (e) => {
		if (!open) return;

		const menu = document.getElementById("setting-dropdown-button");
		const menuClientRects = menu.getClientRects();
		if (menuClientRects.length > 0) {
			const menuBoundary = {
				left: menuClientRects[0].x,
				top: menuClientRects[0].y,
				right: menuClientRects[0].x + menuClientRects[0].width,
				bottom: menuClientRects[0].y + menuClientRects[0].height
			};
			if (
				e.clientX >= menuBoundary.left &&
				e.clientX <= menuBoundary.right &&
				e.clientY <= menuBoundary.bottom &&
				e.clientY >= menuBoundary.top
			) {
				return;
			}
		}

		const dropdownBtn = document.getElementById('setting-dropdown-menu');
		const dropdownBtnClientRects = dropdownBtn.getClientRects();
		if (dropdownBtnClientRects.length > 0) {
			const btnBoundary = {
				left: dropdownBtnClientRects[0].x,
				top: dropdownBtnClientRects[0].y,
				right: dropdownBtnClientRects[0].x + dropdownBtnClientRects[0].width,
				bottom: dropdownBtnClientRects[0].y + dropdownBtnClientRects[0].height
			}
			if (
				e.clientX >= btnBoundary.left &&
				e.clientX <= btnBoundary.right &&
				e.clientY <= btnBoundary.bottom &&
				e.clientY >= btnBoundary.top
			) {
				return;
			}

			const subMenus = dropdownBtn.getElementsByClassName('sub-menu');
			for (let i = 0; i < subMenus.length; i++) {
				const subMenuClientRects = subMenus[i].getClientRects();
				if (subMenuClientRects.length > 0) {
					const subMenuBoundary = {
						left: subMenuClientRects[0].x,
						top: subMenuClientRects[0].y,
						right: subMenuClientRects[0].x + subMenuClientRects[0].width,
						bottom: subMenuClientRects[0].y + subMenuClientRects[0].height
					}
					if (
						e.clientX >= subMenuBoundary.left &&
						e.clientX <= subMenuBoundary.right &&
						e.clientY <= subMenuBoundary.bottom &&
						e.clientY >= subMenuBoundary.top
					) {
						return;
					}
				}
			}
		}


		setOpen(false)
	}
	useEventListener('mousemove', checkMousePos);

	return (<Box className={classes.root} onMouseOver={() => { setOpen(true) }}
	>
		<IconButton
			id="setting-dropdown-button"
			className={classes.iconButton}
		>
			<SettingsOutlinedIcon color="primary" />
		</IconButton>
		<List
			id="setting-dropdown-menu"
			className={[classes.dropdownWrapper, open ? classes.dropdownWrapperOpen : ''].join(' ')}
		>
			{auth && auth.role === USER_ROLE.LEADER && <ListItem button onClick={() => {
				history.push(routes.ACCOUNTS)
			}}>
				{t('Manage Accounts')}
			</ListItem>}
			<ListItem className="parent-menu-item">
				<ListItemText>{t('Language')}</ListItemText>
				<ListItemIcon>
					<ArrowRightIcon />
				</ListItemIcon>
				<List className='sub-menu'>
					<ListItem button onClick={() => {
						setOpen(false);
						i18n.changeLanguage('en');
					}}>
						English
					</ListItem>
					<ListItem button onClick={() => {
						setOpen(false);
						i18n.changeLanguage('zh');
					}}>
						Chinese
					</ListItem>
				</List>
			</ListItem>
		</List>
	</Box>)
}

export default SettingsDropdown;
export const routes = {
  HOME: "/",
  LOGIN: "/signin",
  SIGNUP: "/signup",
  ALERTS: "/alerts",
  MONITOR: "/monitor",
  SEARCH: "/search",
  ANALYZE: "/analyze",
  SETTING: "/setting",
  ACCOUNTS: '/accounts',
  NOTIFICATION: '/notifications',
  APPLICATIONS: '/applications',
  APPLICATION_DETAIL: '/application/:form_id',
  CLAIMSHEETS: '/claim-sheets',
  CLAIMSHEET_MONTH: '/claim-sheet/:month/:title',
  CLAIMSHEET_EDIT: '/claim-sheet/edit/:month/:title',
  INVENTORY: '/inventory',
  STOCKIN_SHEETS: '/inventory/stockin-sheets',
  STOCKIN: '/inventory/stockin/',
  STOCKIN_DETAIL: '/inventory/stockin/:stock_in_form_id',
  STOCKIN_EDIT: '/inventory/stockin/edit/:stock_in_form_id',
  CHECKINVENTORY: '/inventory/check',
  CHECKINVENTORY_EDIT: '/inventory/check/edit',
  INVENTORYSHEET: '/inventory/inventory-sheets',
  INVENTORYSHEET_DETAIL: '/inventory/inventory-sheets/:month/:title',
  INVENTORYSHEET_EDIT: '/inventory/inventory-sheets/edit/:month/:title',
  VERIFICATION: '/verification',
  VERIFICATION_DETAIL: '/verification/detail/:verificate_form_id',
  VERIFICATION_CREATE: '/verification/create',
  VERIFICATION_EDIT: '/verification/edit/:verificate_form_id',
  MYACCOUNT: '/myaccount',
  BORROWS: '/borrows',
  BORROW_CREATE: '/borrow/create',
  BORROW_DETAIL: '/borrow/detail/:borrow_form_id',
  BORROW_EDIT: '/borrow/edit/:borrow_form_id'
}
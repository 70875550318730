import React from 'react';

import { Box, Typography } from '@material-ui/core';

const Inventory = () => {
  return (<Box className="main-page-content">
    <Typography variant="h3">Inventory Page</Typography>
  </Box>)
}

export default Inventory;
import axiosInstance from '../lib/axios';

export const getQuestionAPI = ({ userType, userId, question }) => {
	return new Promise((resolve, reject) => {
		try {
			axiosInstance.post(`/${userType}?userid=${userId}`, question)
				.then(res => {
					resolve(res)
				}).catch(err => {
					reject(err)
				})
		} catch (err) {
			reject(err)
		}
	})
}
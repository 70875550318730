import axiosInstance from '../lib/axios';

export const getAllAccountsAPI = (status = "") => {
  return new Promise(async (resolve, reject) => {
    try {
      let apiURL = '/get_all_accounts';
      if (status) {
        apiURL = apiURL + '?status=' + status;
        const response = await axiosInstance.get(apiURL);
        resolve(response.data);
      } else {
        const responseActive = await axiosInstance.get('/get_all_accounts?status=active');
        const responseDeactive = await axiosInstance.get('/get_all_accounts?status=deactived')
        resolve([...responseActive.data.data, ...responseDeactive.data.data])
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const createAccountsAPI = (accounts) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post('/create_account', accounts)
        .then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        })
    } catch (err) {
      reject(err);
    }
  })
}

export const updateLeaderAccountsAPI = (accounts) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post('/leader_update_account', accounts)
        .then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        })
    } catch (err) {
      reject(err);
    }
  })
}

export const deleteAccountAPI = (accountIds) => {
  return new Promise((resolve, reject) => {
    try {
      const axiosArr = [];
      accountIds.forEach(item => {
        axiosArr.push(axiosInstance.post(`/delete_account?id=${item}`))
      })
      Promise.all(axiosArr).then(responses => {
        resolve(true)
      }).catch(err => {
        reject(false)
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const getUserInfoAPI = (userId) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.get(`get_user_info?user_id=${userId}`).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const updateAccountAPI = (accountInfo) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post(`/update_account`, accountInfo).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const updateUserPasswordAPI = (accountInfo) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post(`/authenticate`, accountInfo).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    } catch (err) {
      reject(err)
    }
  })
}
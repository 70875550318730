import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button as CustomButton } from '../components/Button';
import FullLoadingSpinner from '../components/FullLoadingSpinner';

import { authState } from '../atoms';
import { loginAPI } from '../apis';
import { routes } from '../constants';
import LogoPng from '../assets/images/logo.png';
import LeftArrorSvg from '../assets/images/left-arrow.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '100vh',
    position: 'relative',
  },
  loginWrapper: {
    maxWidth: 560,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoImg: {
    margin: '0 0 63.21px 0',
    position: 'absolute',
    left: 50,
    top: 20,
    width: 180,
    '@media (max-width: 767px)': {
      left: 5,
      top: 5,
    },
  }, 
  title: {
    margin: '0 0 68px 0',
  },
  loginTextField: {
    width: '100%',
    '& .MuiFormLabel-root': {
      display: 'none',
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      '&.Mui-focused': {
        borderColor: '#3C54B9',
      },
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E6EDF6',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '28px',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3C54B9',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
  loginButton: {
    color: '#25374D',
    backgroundColor: '#D0DFF0',
    width: '100%',
    marginTop: '12px',
    height: 64,
    borderRadius: 32,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    textTransform: 'none',
    '& img': {
      width: 20,
      height: 18,
      marginLeft: 16,
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();

  const { t } = useTranslation();

  const setAuth = useSetRecoilState(authState);
  const [loading, setLoading] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    user_name: {
      value: '',
      errorMsg: '',
    },
    password: {
      value: '',
      errorMsg: '',
    },
  });

  const [loginError, setLoginError] = useState('');

  const login = async () => {
    setSubmitted(true);

    // alert(formData.user_name.value);
    // alert(formData.password.value);

    if (formData.user_name.errorMsg.length > 0 && formData.password.errorMsg > 0)
      return;

    try {
      setLoading(true);
      const res = await loginAPI({
        user_name: formData.user_name.value,
        password: formData.password.value,
      });

      //console.log(res);
      // alert(res.id); 

      if (isNaN(res.id)) {
        alert("用户名与密码不符。请再次输入:)");
        setLoading(false);
        return;
      }
      setLoginError("");
      const authInfo = {
        id: res.id,
        user_name: formData.user_name.value,
        role: res.group,
      };

      localStorage.setItem('auth', JSON.stringify(authInfo));
      setAuth(authInfo);
      history.push(routes.HOME);

    } catch (err) {
      setLoginError(`Username or Password is wrong.`)
      console.log('Login Error');
    }

    setLoading(false);
  }

  const onKeyUp = (event) => {
    if (event.key === 'Enter') {
      login();
    }
  }

  const onLoginClick = async (event) => {
    event.preventDefault();
    login();
  };

  return (
    <Box className={classes.root}>
      <img className={classes.logoImg} src={LogoPng} alt='logo-text' />
      <Box className={classes.loginWrapper}>
        <Typography variant='h1' className={classes.title} color="primary">
          {t("Let's Get You Started")}
        </Typography>
        <form noValidate autoComplete='off'>
          <Grid container spacing={3}>
            {loginError && <Grid item xs={12}>
              <Alert severity="error">{loginError}</Alert>
            </Grid>}

            <Grid item xs={12}>
              <TextField
                className={classes.loginTextField}
                label=''
                type='text'
                placeholder={t('username')}
                value={formData.user_name.value}
                onKeyUp={onKeyUp}
                onChange={(event) => {
                  setLoginError("");
                  setFormData({
                    ...formData,
                    user_name: {
                      value: event.target.value,
                      errorMsg:
                        event.target.value.length === 0
                          ? 'Username is required.'
                          : '',
                    },
                  });
                }}
                variant='outlined'
                error={submitted && !!formData.user_name.errorMsg}
                helperText={submitted && formData.user_name.errorMsg}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.loginTextField}
                label=''
                type='password'
                placeholder={t('password')}
                value={formData.password.value}
                onKeyUp={onKeyUp}
                onChange={(event) => {
                  setLoginError("");
                  setFormData({
                    ...formData,
                    password: {
                      value: event.target.value,
                      errorMsg:
                        event.target.value.length > 1
                          ? ''
                          : 'Password is required.',
                    },
                  });
                }}
                variant='outlined'
                error={submitted && !!formData.password.errorMsg}
                helperText={submitted && formData.password.errorMsg}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomButton
                onClick={onLoginClick}
                className={classes.loginButton}
              >
                {t('login')}
                <img src={LeftArrorSvg} alt='left arrow' />
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </Box>
      {loading && <FullLoadingSpinner />}
    </Box>
  );
};

export default Login;

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms';
import clsx from 'clsx';
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Breadcrumbs,
  Link
} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import FullLoadingSpinner from '../../components/FullLoadingSpinner';
import moment from 'moment';
import {
  getVerificationFormAPI,
} from '../../apis';
import { dateToYYYYMMDD } from '../../utils'
import { USER_ROLE, routes } from '../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .record-table-container': {
      marginTop: 60
    }
  },
  verficationTableContainer: {
    margin: '5px 0 0 0',
  },
  verficationCommentsTBContainer: {
    marginTop: 60,
    position: 'relative',
    overflow: 'visible',
  },
  signinTBContainer: {
    width: '100%',
    marginTop: 60,
    position: 'relative',
    overflow: 'visible',
  },
  formGroup: {
    display: 'flex',
    width: '100%',
    '& .MuiTypography-subtitle1': {
      margin: '0 0 0 0',
      padding: '5px 0 0 0',
      textAlign: 'left'
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  tableCheck: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  tableActions: {
    right: '40px',
    display: 'flex',
    alighItems: 'center',
    position: 'absolute',
    left: 0,
    bottom: '100%',
  },
  snackbar: {
    left: 250
  }
}));

const VerificationDetail = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { verificate_form_id } = useParams();
  const history = useHistory()
  const auth = useRecoilValue(authState);

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    verificate_form_id: '',
    data: {
      contract_name: "",
      contract_id: "",
      supplier_name: "",
      order_id: "",
      onsite_contact: "",
      supplier_contact: "",
      contract_date: null,
      expected_delivery_date: null,
      row_data: [],
      verification_comments: [
        { quantity_verified: 1, notes: null },
        { document_verified: 1, notes: null },
        { appearance_verified: 1, notes: null },
        { performance_verified: 1, notes: null },
        { on_time: 1, notes: null },
        { representative_present: 1, notes: null },
        { check_verified: 1, notes: null }
      ],
      leader_name: "",
      verifier_name: "",
      admin_verification_date: null,
    }
  })

  let componentMounted = true;
  useEffect(() => {
    if (!auth) return;
    if (verificate_form_id) {
      getVerificationFormAPI(verificate_form_id).then(res => {
        if (componentMounted) {
          let returnData = res.data;
          returnData.data.contract_date = res.data.data.contract_date ? moment(res.data.data.contract_date) : '';
          returnData.data.expected_delivery_date = res.data.data.expected_delivery_date ? moment(res.data.data.expected_delivery_date) : '';
          returnData.data.admin_verification_date = res.data.data.admin_verification_date ? moment(res.data.data.admin_verification_date) : '';
          setFormData({
            ...returnData,
          })
          setLoading(false);
        }
      }).catch(err => {
        if (componentMounted) {
          setLoading(false);
          setFormData({
            ...formData,
            verificate_form_id: verificate_form_id
          })
        }
      })
    }
    return () => { componentMounted = false; }
  }, [auth])

  const formatVerificationFormData = () => {
    return {
      ...formData,
      data: {
        ...formData.data,
        // contract_date: formData.data.contract_date ? formData.data.contract_date : null,
        //contract_date: formData.data.contract_date ? dateToYYYYMMDD(formData.data.contract_date, i18n.language) : null,
        contract_date: formData.data.contract_date ? dateToYYYYMMDD(formData.data.contract_date) : null,
        //expected_delivery_date: formData.data.expected_delivery_date ? dateToYYYYMMDD(formData.data.expected_delivery_date, i18n.language) : null,
        expected_delivery_date: formData.data.expected_delivery_date ? dateToYYYYMMDD(formData.data.expected_delivery_date) : null,
        admin_verification_date: formData.data.admin_verification_date ? dateToYYYYMMDD(formData.data.admin_verification_date, i18n.language) : null,
      },
    }
  }

  const exportCSV = () => {
    const exportData = formatVerificationFormData();
    let recordBody = '';
    exportData.data.row_data.forEach(item => {
      recordBody += `<tr>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.row_num}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 180px">${item.item_name}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.item_model}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.quantity}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 120px">${item.unit}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 180x">${item.item_id}</td>
      </tr>`
    });

    const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
    const worksheet = 'Sheet1'
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:x="urn:schemas-microsoft-com:office:excel"
    xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
    <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
    <x:Name>${worksheet}</x:Name>
    <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
    </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
    </head><body>
    <body link="#0563C1" vlink="#954F72">
      <table style="font-family: SimSun">
        <thead>
          <tr>
            <th colspan="6" style="font-size: 18pt; height: 25pt; text-align: center;  vertical-align: middle; border: 0.5pt solid black;">
              ${t('Verification Sheet')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="6" style="font-size: 14pt;  text-align: right; height: 18pt; vertical-align: middle; border: 0.5pt solid black;">
              ${t('FormID')}:${formData.verificate_form_id}
            </td>
          </tr>
          <tr>
            <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
              ${t('contract name and id')}
            </td>
            <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
              ${t('supplier name')}
            </td>            
          </tr>
          <tr>
            <td colspan="3" style="height: 30pt; font-size: 14pt; vertical-align: middle; border: 0.5pt solid black;">            
              ${t('Contract Name')}:  ${exportData.data.contract_name} <br/>
              ${t('Contract ID')}:  ${exportData.data.contract_id}
            </td>
            <td colspan="3" style="height: 30pt; font-size: 14pt; vertical-align: middle; border: 0.5pt solid black; text-align: center">
              ${exportData.data.supplier_name}
            </td>            
          </tr>
          <tr>
            <td style="width: 30pt; font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Order id')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; width: 150pt;">${t('on-site contact/phone')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; width: 150pt;">${t('supplier contact/phone')}</td>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; width: 120pt">${t('contract date')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; width: 120pt">${t('expected delivery date')}</td>
          </tr>          
          <tr>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
              ${exportData.data.order_id}
            </td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
              ${exportData.data.onsite_contact}
            </td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
              ${exportData.data.supplier_contact}
            </td>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
              ${exportData.data.contract_date || ''}
            </td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">
              ${exportData.data.expected_delivery_date || ''}
            </td>
          </tr>
          <tr>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('No.')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('Name')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('item_model')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('quantity')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('unit')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('item_id')}</td>
          </tr>
          ${recordBody}          
          <tr>
            <td colspan="6" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black; border-top: 1pt solid black;">${t('Verification Comments')}</td>
          </tr>
          <tr>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('project')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('result')}</td>
            <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('notes')}</td>
          </tr>
          <tr>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('quantity verified')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[0].quantity_verified === 1 ? t('yes') : t('no')}</td>
            <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[0].notes || ''}</td>
          </tr>
          <tr>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('document verified')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[1].document_verified === 1 ? t('yes') : t('no')}</td>
            <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[1].notes || ''}</td>
          </tr>
          <tr>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('appearance verified')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[2].appearance_verified === 1 ? t('yes') : t('no')}</td>
            <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[2].notes || ''}</td>
          </tr>
          <tr>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('performance verified')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[3].performance_verified === 1 ? t('yes') : t('no')}</td>
            <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[3].notes || ''}</td>
          </tr>
          <tr>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('on time')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[4].on_time === 1 ? t('yes') : t('no')}</td>
            <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[4].notes || ''}</td>
          </tr>
          <tr>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('representative present')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[5].representative_present === 1 ? t('yes') : t('no')}</td>
            <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[5].notes || ''}</td>
          </tr>
          <tr>
            <td colspan="2" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('check verified')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[6].check_verified === 1 ? t('yes') : t('no')}</td>
            <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${exportData.data.verification_comments[6].notes || ''}</td>
          </tr>
          <tr>
            <td colspan="6"></td>
          </tr>
          <tr>
            <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: left; border: none;">${t('accepter name')}: ${exportData.data.leader_name}</td>            
            <td colspan="3" style="font-size: 14pt; vertical-align: middle; text-align: left; border: none;">${t('acceptance time')}: ${exportData.data.admin_verification_date || ''}</td>
          </tr>
          <tr>
            <td colspan="6" style="font-size: 14pt; vertical-align: middle; text-align: left; border: none;">${t('accepter')}: ${exportData.data.verifier_name}</td>
          </tr>
        </tbody>
      </table>
    </body></html>
    `;

    const link = document.createElement("a");
    link.href = uri + base64(template);
    link.target = "_blank";
    link.download = `验收单_编号${formData.verificate_form_id}.xls`;
    link.click();
  }

  return (
    <Box className={clsx(classes.root, 'main-page-content')}>
      <Box xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Breadcrumbs className="darin-breadcrumbs">
          <Link onClick={() => { history.push(routes.VERIFICATION); }} color="inherit">{t('Verification Sheets')}</Link>
          <Typography>{t('FormID')}: {formData.verificate_form_id}</Typography>
        </Breadcrumbs>
        {(auth?.role === USER_ROLE.ADMIN) && <>
          <Tooltip title={t('Edit')}>
            <IconButton
              onClick={() => {
                history.push(`/verification/edit/${verificate_form_id}`)
              }}
            >
              <CreateOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('submit')}>
            <IconButton>
              <SendOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('download')}>
            <IconButton
              onClick={exportCSV}
            >
              <GetAppOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
        </>}
      </Box>

      <Box>
        <Typography variant="h3">{t('Verification Sheet')}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" style={{ textAlign: 'right', marginBottom: '8px' }}>
          {t('FormID')}: &nbsp;{formData.verificate_form_id}
        </Typography>
      </Box>

      <Box className={clsx(classes.verficationTableContainer, "darin-table-panel")}>
        <TableContainer className='darin-table-container'>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={3}
                  style={{ border: 'none', fontWeight: 600, fontSize: '1.1rem', paddingBottom: 0, height: 32 }}
                >
                  {t('contract name and id')}
                </TableCell>
                <TableCell
                  colSpan={3}
                  style={{ border: 'none', fontWeight: 600, fontSize: '1.1rem', paddingBottom: 0, height: 32 }}
                >
                  {t('supplier name')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} style={{ border: 'none' }} >
                  <Box className={classes.formGroup}>
                    <Typography variant="subtitle1" style={{ width: 180, fontSize: '0.9rem', fontWeight: 600 }}>
                      {t('Contract Name')}:
                    </Typography>
                    <Typography variant="subtitle1">{formData.data.contract_name}</Typography>
                  </Box>
                  <Box className={classes.formGroup}>
                    <Typography variant="subtitle1" style={{ width: 180, fontSize: '0.9rem', fontWeight: 600 }}>{t('Contract ID')}:</Typography>
                    <Typography variant="subtitle1" >{formData.data.contract_id}</Typography>
                  </Box>
                </TableCell>
                <TableCell colSpan={3} style={{ border: 'none' }} >
                  <Box className={classes.formGroup} style={{ justifyContent: 'center' }}>
                    <Typography variant="subtitle1" >{formData.data.supplier_name}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    width: i18n.language === 'zh' ? 70 : 98,
                    border: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem',
                    paddingTop: 16,
                    paddingBottom: 0,
                    height: 32
                  }}
                >
                  {t('Order id')}
                </TableCell>
                <TableCell
                  style={{
                    border: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem',
                    paddingTop: 16,
                    paddingBottom: 0,
                    height: 32
                  }}
                >
                  {t('on-site contact/phone')}
                </TableCell>
                <TableCell
                  style={{
                    border: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem',
                    paddingTop: 16,
                    paddingBottom: 0,
                    height: 32
                  }}
                >
                  {t('supplier contact/phone')}
                </TableCell>
                <TableCell
                  style={{
                    border: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem',
                    paddingTop: 16,
                    paddingBottom: 0,
                    height: 32
                  }}
                >
                  {t('contract date')}
                </TableCell>
                <TableCell
                  style={{
                    border: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem',
                    paddingTop: 16,
                    paddingBottom: 0,
                    height: 32
                  }}
                >
                  {t('expected delivery date')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: 'none', textAlign: 'center' }}>
                  <Typography variant="subtitle1" >{formData.data.order_id}</Typography>
                </TableCell>
                <TableCell style={{ border: 'none' }}>
                  <Typography variant="subtitle1" >{formData.data.onsite_contact}</Typography>
                </TableCell>
                <TableCell style={{ border: 'none' }}>
                  <Typography variant="subtitle1" >{formData.data.supplier_contact}</Typography>
                </TableCell>
                <TableCell style={{ border: 'none' }}>
                  <Typography variant="subtitle1" >{formatVerificationFormData().data.contract_date}</Typography>
                </TableCell>
                <TableCell style={{ border: 'none', }}>
                  <Typography variant="subtitle1" >{formatVerificationFormData().data.expected_delivery_date}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box className="darin-table-panel record-table-container">
        <TableContainer className="darin-table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'left', width: i18n.language === 'zh' ? 70 : 35 }}
                >
                  {t('No.')}
                </TableCell>
                <TableCell>{t('Name')}</TableCell>
                <TableCell>{t('item_model')}</TableCell>
                <TableCell>{t('quantity')}</TableCell>
                <TableCell>{t('unit')}</TableCell>
                <TableCell style={{ textAlign: 'right' }}>{t('item_id')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.data.row_data.length === 0 && <TableRow><TableCell colSpan={6}><span style={{ color: 'grey' }}>{t("There's no record")}</span></TableCell></TableRow>}
              {
                formData.data.row_data.length > 0 && <>
                  {formData.data.row_data.map((itemRow, idx) => {
                    return <TableRow key={idx}>
                      <TableCell style={{ textAlign: 'left' }}>{itemRow.row_num}</TableCell>
                      <TableCell>{itemRow.item_name}</TableCell>
                      <TableCell>{itemRow.item_model}</TableCell>
                      <TableCell>{itemRow.quantity}</TableCell>
                      <TableCell>{itemRow.unit}</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>{itemRow.item_id}</TableCell>
                    </TableRow>
                  })}</>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box className="darin-table-panel record-table-container">
        <TableContainer className="darin-table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center', fontSize: '1.1rem' }}>{t('Verification Comments')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: '33.33%' }}>{t('project')}</TableCell>
                <TableCell style={{ width: '33.33%' }}>{t('result')}</TableCell>
                <TableCell style={{ width: '33.33%' }}>{t('notes')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t('quantity verified')}</TableCell>
                <TableCell>
                  {formData.data.verification_comments[0].quantity_verified ? t('Yes') : t('No')}
                </TableCell>
                <TableCell>
                  {formData.data.verification_comments[0].notes}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('document verified')}</TableCell>
                <TableCell>
                  {formData.data.verification_comments[1].document_verified ? t('Yes') : t('No')}
                </TableCell>
                <TableCell>
                  {formData.data.verification_comments[1].notes}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('appearance verified')}</TableCell>
                <TableCell>
                  {formData.data.verification_comments[2].appearance_verified ? t('Yes') : t('No')}
                </TableCell>
                <TableCell>
                  {formData.data.verification_comments[2].notes}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('performance verified')}</TableCell>
                <TableCell>
                  {formData.data.verification_comments[3].performance_verified ? t('Yes') : t('No')}
                </TableCell>
                <TableCell>
                  {formData.data.verification_comments[3].notes}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('on time')}</TableCell>
                <TableCell>
                  {formData.data.verification_comments[4].on_time ? t('Yes') : t('No')}
                </TableCell>
                <TableCell>
                  {formData.data.verification_comments[4].notes}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('representative present')}</TableCell>
                <TableCell>
                  {formData.data.verification_comments[5].representative_present ? t('Yes') : t('No')}
                </TableCell>
                <TableCell>
                  {formData.data.verification_comments[5].notes}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('check verified')}</TableCell>
                <TableCell>
                  {formData.data.verification_comments[6].check_verified ? t('Yes') : t('No')}
                </TableCell>
                <TableCell>
                  {formData.data.verification_comments[6].notes}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box className={clsx(classes.signinTBContainer, "darin-table-panel")}>
        <TableContainer className='darin-table-container'>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    textAlign: 'left',
                    width: '200px',
                    borderBottom: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem'
                  }}
                >
                  {t('accepter name')}:
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'left',
                    borderBottom: 'none'
                  }}
                >
                  {formData.data.leader_name}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'right',
                    borderBottom: 'none',
                    fontWeight: 600,
                    fontSize: '0.9rem'
                  }}
                >
                  {t('acceptance time')}:
                </TableCell>
                <TableCell style={{ textAlign: 'left', borderBottom: 'none' }}>
                  {formatVerificationFormData().data.admin_verification_date}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    textAlign: 'left',
                    fontWeight: 600,
                    fontSize: '0.9rem'
                  }}
                >
                  {t('accepter')}:
                </TableCell>
                <TableCell style={{ textAlign: 'left' }}>
                  {formData.data.verifier_name}
                </TableCell>
                <TableCell colspan={2} ></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {loading && <FullLoadingSpinner />}
    </Box >)
}

export default VerificationDetail;
import axiosInstance from '../lib/axios';

export const showTotalInventoryAPI = () => {
  return new Promise((resolve, reject) => {
    try {
      let apiURL = '/show_inventory_sheet_cur';
      axiosInstance.get(apiURL, {}).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const showAllInventorySheetAPI = () => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.get('/show_all_inventory_sheet').then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const showInventorySheetAPI = (month) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.get(`/show_inventory_sheet?yearmonth=${month}`).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const updateInventorySheetAPI = (updateData) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post('/update_inventory_sheet', { ...updateData }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const updateCheckInventorySheetAPI = (updateData) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post('/update_inventory_sheet_cur', { ...updateData }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    } catch (err) {
      reject(err)
    }
  })
}


export const submitInventorySheetAPI = (inventorySheetID) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post(`/submit_table?table_name=Inventory&yearmonth=${inventorySheetID}`).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}
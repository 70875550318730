import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authState } from '../../../atoms';
import clsx from 'clsx';
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ConfirmModal from '../../../components/ConfirmModal';
import FullLoadingSpinner from '../../../components/FullLoadingSpinner';
import { getShowAllStockInFormsAPI, deleteStockInFormAPI } from '../../../apis';
import { routes } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .page-title-wrapper': {
      marginBottom: 0,
    }
  },
}));

const StockInSheets = () => {

  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const auth = useRecoilValue(authState);

  const [loading, setLoading] = useState(true);
  const [stockInFormList, setStockInFormList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedStockIns, setSelectedStockIns] = useState([])

  let componentMounted = true;

  useEffect(() => {
    if (auth && auth.id) {
      getShowAllStockInFormsAPI().then(res => {
        if (componentMounted) {
          // const sortedArr = res.data.data.sort((a, b) => {
          //   if (a.admin_verification_date === null) return 1;
          //   if (b.admin_verification_date === null) return -1;
          //   const aDateValue = moment(a.admin_verification_date).valueOf();
          //   const bDateValue = moment(b.admin_verification_date).valueOf();

          //   if (aDateValue > bDateValue) {
          //     return -1;
          //   }
          //   if (aDateValue < bDateValue) {
          //     return 1;
          //   }
          //   return 0;
          // });
          setStockInFormList([...res.data.data]);
          setLoading(false);
        }
      }).catch(err => {
        if (componentMounted) {
          setStockInFormList([]);
          setLoading(false);
        }
        console.log("Get show all stock in forms api error:")
        console.log(err);
      })
    }
    return () => {
      componentMounted = false;
    }
  }, [auth]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteStockIn = () => {
    const selectedIDs = selectedStockIns.map(item => item.stock_form_id);
    setShowDeleteModal(false);
    setLoading(true);
    deleteStockInFormAPI(selectedIDs).then(res => {
      setStockInFormList([
        ...stockInFormList.filter(item => selectedIDs.indexOf(item.stock_form_id) < 0)
      ])
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    })
  }

  const [orderBy, setOrderBy] = useState({
    name: '',
    value: 'asc'
  })

  const getSortedData = () => {
    if (!orderBy.name) return stockInFormList;

    if (orderBy.name === 'admin_verification_date' && orderBy.value) {
      return stockInFormList.sort((a, b) => {
        const orderValue = orderBy.value === 'desc' ? -1 : 1;
        if (!a.admin_verification_date) return -1 * orderValue;
        if (!b.admin_verification_date) return 1 * orderValue;

        const aDateValue = new Date(a.admin_verification_date).valueOf();
        const bDateValue = new Date(b.admin_verification_date).valueOf();

        if (bDateValue > aDateValue) return -1 * orderValue;
        else if (bDateValue < aDateValue) return 1 * orderValue;
        else return 0;
      })
    } else if (orderBy.name === 'stock_form_id' && orderBy.value) {
      return stockInFormList.sort((a, b) => {
        const orderValue = orderBy.value === 'desc' ? -1 : 1;
        if (b.stock_form_id > a.stock_form_id) return -1 * orderValue;
        else if (b.stock_form_id < a.stock_form_id) return 1 * orderValue;
        else return 0;
      })
    }
    else return stockInFormList;
  }

  const onSelectAllClick = (event) => {
    if (event.target.checked)
      setSelectedStockIns([...stockInFormList]);
    else setSelectedStockIns([])
  }

  return (
    <Box className={clsx(classes.root, "main-page-content")}>
      <Box className="page-title-wrapper">
        <Typography variant="h3" className="page-title">{t('StockIn Sheets')}</Typography>
        <IconButton
          style={{ marginLeft: 'auto' }}
          onClick={() => { history.push(routes.STOCKIN) }}>
          <AddIcon color="primary" />
        </IconButton>
        <IconButton
          onClick={() => {
            setShowDeleteModal(true);
          }}
          disabled={selectedStockIns.length === 0}
        >
          <DeleteForeverOutlinedIcon color="error" />
        </IconButton>
      </Box>
      <Box className="darin-table-panel">
        <Box className="table-action-wrapper">
          <TablePagination
            className="table-pagination-wrapper"
            rowsPerPageOptions={[5, 10, 25]}
            count={stockInFormList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            component="div"
          />
        </Box>

        <TableContainer className="darin-table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ paddingLeft: 0, width: 40, textAlign: 'left' }}>
                  <Checkbox
                    className={classes.tableCheck}
                    color="primary"
                    checked={selectedStockIns.length === stockInFormList.length}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </TableCell>
                <TableCell style={{ textAlign: 'center', width: i18n.language === 'zh' ? '65px' : '111px', minWidth: i18n.language === 'zh' ? '65px' : '111px' }}>{t('Status')}</TableCell>
                <TableCell
                  style={{
                    width: i18n.language === 'zh' ? '120px' : '155px',
                    minWidth: i18n.language === 'zh' ? '120px' : '155px',
                    maxWidth: i18n.language === 'zh' ? '120px' : '155px',
                    textAlign: 'center',
                  }}
                  sortDirection={orderBy.name === 'stock_form_id' ? orderBy.value : false}
                >
                  <TableSortLabel
                    active={orderBy.name === 'stock_form_id'}
                    direction={orderBy.name === 'stock_form_id' ? orderBy.value : 'asc'}
                    onClick={() => {
                      if (orderBy.name === 'stock_form_id') {
                        if (orderBy.value === 'desc')
                          setOrderBy({
                            name: 'stock_form_id',
                            value: 'asc'
                          })
                        else
                          setOrderBy({
                            name: 'stock_form_id',
                            value: 'desc'
                          })
                      } else {
                        setOrderBy({
                          name: 'stock_form_id',
                          value: 'asc'
                        })
                      }
                    }}
                  >
                    {t('Stock FormID')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ textAlign: 'center', width: 200 }}>{t('Contract Name')}</TableCell>
                <TableCell style={{ textAlign: 'center', width: 200 }}>{t('Contract ID')}</TableCell>
                <TableCell style={{ textAlign: 'center', width: 200 }}>{t('Supplier Name')}</TableCell>
                <TableCell
                  sortDirection={orderBy.name === 'admin_verification_date' ? orderBy.value : false}
                  style={{ width: i18n.language === 'zh' ? '140px' : '180px', minWidth: i18n.language === 'zh' ? '140px' : '180px', textAlign: 'right' }}
                >
                  <TableSortLabel
                    active={orderBy.name === 'admin_verification_date'}
                    direction={orderBy.name === 'admin_verification_date' ? orderBy.value : 'asc'}
                    onClick={() => {
                      if (orderBy.name === 'admin_verification_date') {
                        if (orderBy.value === 'desc')
                          setOrderBy({
                            name: 'admin_verification_date',
                            value: 'asc'
                          })
                        else
                          setOrderBy({
                            name: 'admin_verification_date',
                            value: 'desc'
                          })
                      } else {
                        setOrderBy({
                          name: 'admin_verification_date',
                          value: 'asc'
                        })
                      }
                    }}
                  >
                    {t('Verification Date')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getSortedData().map((item, idx) => {
                if (idx >= (page + 1) * rowsPerPage || idx < page * rowsPerPage)
                  return null;
                return <TableRow key={idx} hover style={{ cursor: 'pointer' }}>
                  <TableCell style={{ paddingLeft: 0, width: 40, textAlign: 'center' }}>
                    <Checkbox
                      className={classes.tableCheck}
                      checked={selectedStockIns.map(item => item.stock_form_id).indexOf(item.stock_form_id) >= 0}
                      onChange={(event) => {
                        if (event.target.checked)
                          setSelectedStockIns([...selectedStockIns, item]);
                        else
                          setSelectedStockIns([...selectedStockIns.filter(itemSelected => itemSelected.stock_form_id !== item.stock_form_id)])
                      }}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', color: item.submit ? 'grey' : '#dd4b39' }}
                    onClick={() => {
                      history.push(`/inventory/stockin/${item.stock_form_id}`);
                    }}
                  >
                    {item.submit ? t('Submission') : t('Draft')}
                  </TableCell>

                  <TableCell
                    style={{ textAlign: 'center', paddingRight: 40 }}
                    onClick={() => {
                      history.push(`/inventory/stockin/${item.stock_form_id}`);
                    }}
                  >
                    {item.stock_form_id}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center' }}
                    onClick={() => {
                      history.push(`/inventory/stockin/${item.stock_form_id}`);
                    }}
                  >
                    {item.contract_name}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center' }}
                    onClick={() => {
                      history.push(`/inventory/stockin/${item.stock_form_id}`);
                    }}
                  >{item.contract_id}</TableCell>
                  <TableCell
                    style={{ textAlign: 'center' }}
                    onClick={() => {
                      history.push(`/inventory/stockin/${item.stock_form_id}`);
                    }}
                  >
                    {item.supplier_name}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'right', paddingRight: 40 }}
                    onClick={() => {
                      history.push(`/inventory/stockin/${item.stock_form_id}`);
                    }}
                  >
                    {item.admin_verification_date}
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {showDeleteModal && <ConfirmModal isShow={showDeleteModal} hideModal={() => {
        setShowDeleteModal(false)
      }}
        onOk={deleteStockIn}
        title={t('Please confirm')}
        description={t('Are you going to delete it?')}
      />}
      {loading && <FullLoadingSpinner />}
    </Box >)
}

export default StockInSheets;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Tooltip,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination
} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import FullLoadingSpinner from '../../../components/FullLoadingSpinner';
import { showClaimSheetAPI, submitClaimSheetAPI } from '../../../apis';
import { YYYYMMDDtoFormat, getColumnValue } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .page-title-wrapper': {
      marginBottom: 0,
    }
  },
  userInfoTableContainer: {
    marginTop: 60
  }
}))

const ClaimSheetDetail = () => {

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { month, title } = useParams();

  const [claimSheets, setClaimSheets] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);

  let componentMounted = true;

  useEffect(() => {
    if (month) {
      setLoading(true);
      showClaimSheetAPI(month).then(res => {
        if (componentMounted) {
          setLoading(false);
          setClaimSheets([...res.data.data]);
          setSubmitted(res.data.submit);
        }
      }).catch(err => {
        if (componentMounted) {
          setLoading(false);
          setClaimSheets([]);
        }
      })
    }
  }, [month])

  const renderTitle = () => {
    try {
      const firstText = title.substr(0, title.indexOf(' '));
      const secondText = title.substr(title.indexOf(' ') + 1, title.length);
      return `${firstText}${YYYYMMDDtoFormat(month)}${secondText}`;
    } catch (err) {
      return ''
    }
  }

  const exportCSV = () => {
    let recordBody = '';
    claimSheets.forEach(item => {
      recordBody += `<tr>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 70px">${getColumnValue(item, 'row_num')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 192px">${getColumnValue(item, 'contract_name')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'contract_id')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'item_name')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 110px">${getColumnValue(item, 'item_model')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'item_id')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 70px">${getColumnValue(item, 'unit')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 70x">${getColumnValue(item, 'quantity')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 80px">${getColumnValue(item, 'net_unit_price')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 114px">${getColumnValue(item, 'inventory_time')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'consumption_quantity')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'consumption_amount')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 114px">${getColumnValue(item, 'out_time')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 106px">${getColumnValue(item, 'app_num')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 92px">${getColumnValue(item, 'quantity_availabl')}</td>
        <td style="border: 0.5pt solid black; font-size: 14pt; text-align: center; width: 70px">${getColumnValue(item, 'notes')}</td>
      </tr>`
    });

    const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
    const worksheet = 'Sheet1'
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:x="urn:schemas-microsoft-com:office:excel"
    xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
    <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
    <x:Name>${worksheet}</x:Name>
    <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
    </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
    </head><body>
    <body link="#0563C1" vlink="#954F72">
      <table style="font-family: SimSun">
        <thead>
          <tr>
            <th colspan="16" style="font-size: 18pt; height: 25pt; text-align: center;  vertical-align: middle; border: 0.5pt solid black;">
              ${renderTitle()}
            </th>
          </tr>
        </thead>
        <tbody>          
          <tr>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('num')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('contract name')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('contract id')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('Item Name')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('claim item model')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('item_id')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('unit')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('quantity')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('net unit price')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('inventory date')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('consumption quantity')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('consumption amount')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('out time')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('app num')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('quantity available')}</td>
            <td style="font-size: 14pt; vertical-align: middle; text-align: center; border: 0.5pt solid black;">${t('notes')}</td>
          </tr>
          ${recordBody}                            
          <tr>
            <td colspan="11" style="font-size: 14pt; vertical-align: middle; text-align: left;">${t('total amount')}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="4" style="font-size: 14pt; vertical-align: middle; text-align: left;">${t('accountant name')}:</td>
            <td colspan="4" style="font-size: 14pt; vertical-align: middle; text-align: left;"></td>
            <td colspan="4" style="font-size: 14pt; vertical-align: middle; text-align: left;">${t('Leader Name')}:</td>
            <td colspan="4" style="font-size: 14pt; vertical-align: middle; text-align: left;"></td>
          </tr>
          <tr>
            <td colspan="4"></td>
            <td colspan="4"></td>
            <td colspan="4" style="font-size: 14pt; vertical-align: middle; text-align: left;">${t('Admin Name')}:</td>
            <td colspan="4" style="font-size: 14pt; vertical-align: middle; text-align: left;"></td>
          </tr>          
        </tbody>
      </table>
    </body></html>
    `;

    const link = document.createElement("a");
    link.href = uri + base64(template);
    link.target = "_blank";
    link.download = `${t('Claim Sheets')}_${renderTitle()}.xls`;
    link.click();
  }

  const submitClaimSheet = () => {
    setLoading(true);
    submitClaimSheetAPI(month).then(res => {
      setSubmitted(true);
      setLoading(false);
    }).catch(err => {
      console.log(err);
      setLoading(false);
    })
  }

  return (
    <Box className={clsx(classes.root, 'main-page-content')}>
      <Grid container>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Breadcrumbs className="darin-breadcrumbs">
            <Link
              onClick={() => { setClaimSheets([]); history.push(`/claim-sheets`); }}
              color="inherit"
            >
              {t('Claim Sheets')}
            </Link>
            <Typography>{t('time')}: {YYYYMMDDtoFormat(month, i18n.language)}</Typography>
          </Breadcrumbs>
          <Tooltip title={t('Edit')}>
            <IconButton
              onClick={() => {
                history.push(`/claim-sheet/edit/${month}/${title}`)
              }}
              disabled={submitted}
            >
              <CreateOutlinedIcon color={submitted ? '' : 'primary'} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('submit')}>
            <IconButton
              onClick={submitClaimSheet}
              disabled={submitted}
            >
              <SendOutlinedIcon color={submitted ? '' : 'primary'} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('download')}>
            <IconButton
              onClick={exportCSV}
            >
              <GetAppOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} style={{ marginBottom: 32 }}>
            <Typography variant="h3">{renderTitle()}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box className="darin-table-panel">
            <Box className="darin-table-container-wrapper">
              <TableContainer className="darin-table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: 'left', minWidth: '70px', width: '70px' }} >{t('num')}</TableCell>
                      <TableCell style={{ minWidth: '192px', width: '192px' }} >{t('contract name')}</TableCell>
                      <TableCell style={{ minWidth: '92px', width: '92px' }} >{t('contract id')}</TableCell>
                      <TableCell style={{ minWidth: '92px', width: '92px' }} >{t('Item Name')}</TableCell>
                      <TableCell style={{ minWidth: '110px', width: '110px' }} >{t('claim item model')}</TableCell>
                      <TableCell style={{ minWidth: '92px', width: '92px', maxWidth: '92px' }} >{t('item_id')}</TableCell>
                      <TableCell style={{ minWidth: '70px', width: '70px' }} >{t('unit')}</TableCell>
                      <TableCell style={{ minWidth: '70px', width: '70px' }} >{t('quantity')}</TableCell>
                      <TableCell style={{ minWidth: '103px', width: '103px' }} >{t('net unit price')}</TableCell>
                      <TableCell style={{ minWidth: '114px', width: '114px' }} >{t('inventory date')}</TableCell>
                      <TableCell style={{ minWidth: '92px', width: '92px' }} >{t('consumption quantity')}</TableCell>
                      <TableCell style={{ minWidth: '118px', width: '118px' }} >{t('consumption amount')}</TableCell>
                      <TableCell style={{ minWidth: '114px', width: '114px' }} >{t('out time')}</TableCell>
                      <TableCell style={{ minWidth: '106px', width: '106px' }} >{t('app num')}</TableCell>
                      <TableCell style={{ minWidth: '92px', width: '92px' }} >{t('quantity available')}</TableCell>
                      <TableCell style={{ minWidth: '70px', width: '70px' }} >{t('notes')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {claimSheets.map((item, idx) => {
                      return <TableRow key={idx}>
                        <TableCell>{getColumnValue(item, 'row_num')}</TableCell>
                        <TableCell>{getColumnValue(item, 'contract_name')}</TableCell>
                        <TableCell>{getColumnValue(item, 'contract_id')}</TableCell>
                        <TableCell>{getColumnValue(item, 'item_name')}</TableCell>
                        <TableCell>{getColumnValue(item, 'item_model')}</TableCell>
                        <TableCell style={{ maxWidth: '92px' }}>{getColumnValue(item, 'item_id')}</TableCell>
                        <TableCell>{getColumnValue(item, 'unit')}</TableCell>
                        <TableCell>{getColumnValue(item, 'quantity')}</TableCell>
                        <TableCell>{getColumnValue(item, 'net_unit_price')}</TableCell>
                        <TableCell>{getColumnValue(item, 'inventory_time')}</TableCell>
                        <TableCell>{getColumnValue(item, 'consumption_quantity')}</TableCell>
                        <TableCell>{getColumnValue(item, 'consumption_amount')}</TableCell>
                        <TableCell>{getColumnValue(item, 'out_time')}</TableCell>
                        <TableCell>{getColumnValue(item, 'app_num')}</TableCell>
                        <TableCell>{getColumnValue(item, 'quantity_availabl')}</TableCell>
                        <TableCell>{getColumnValue(item, 'notes')}</TableCell>
                      </TableRow>
                    })}
                    <TableRow>
                      <TableCell colSpan={11}>
                        {`${t('total amount')}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box className={clsx("darin-table-panel", classes.userInfoTableContainer)}>
            <TableContainer className="darin-table-container">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{
                      borderBottom: 'none', textAlign: 'left', fontSize: '0.9rem',
                      fontWeight: 600
                    }}>{t('accountant name')}:</TableCell>
                    <TableCell style={{
                      borderBottom: 'none', textAlign: 'left', fontSize: '0.9rem',
                      fontWeight: 600
                    }}></TableCell>
                    <TableCell style={{
                      borderBottom: 'none', textAlign: 'left', fontSize: '0.9rem',
                      fontWeight: 600
                    }}>{t('Leader Name')}:</TableCell>
                    <TableCell style={{
                      borderBottom: 'none', textAlign: 'left', fontSize: '0.9rem',
                      fontWeight: 600
                    }}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{
                      borderBottom: 'none', textAlign: 'left', fontSize: '0.9rem',
                      fontWeight: 600
                    }}>{t('Admin Name')}:</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

      </Grid>
      {loading && <FullLoadingSpinner />}
    </Box>
  )
}

export default ClaimSheetDetail;
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import FullLoadingSpinner from '../../../components/FullLoadingSpinner';
import { showAllInventorySheetAPI } from '../../../apis';
import { YYYYMMDDtoFormat } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .page-title-wrapper': {
      marginBottom: 0,
    }
  },
  userInfoTableContainer: {
    marginTop: 60
  }
}))

const InventorySheet = () => {

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [inventorySheets, setInventorySheets] = useState([]);
  const [loading, setLoading] = useState(true);

  let componentMounted = true;
  useEffect(() => {
    showAllInventorySheetAPI().then(res => {
      if (componentMounted) {
        setLoading(false);
        setInventorySheets([...res.data.data]);
      }
    }).catch(err => {
      if (componentMounted) {
        setLoading(false);
        setInventorySheets([]);
      }
    })
    return () => { componentMounted = false }
  }, [])

  return (
    <Box className={clsx(classes.root, 'main-page-content')}>
      <Grid container spacing={0}>

        <Grid item xs={12}>
          <Box className="page-title-wrapper" style={{ marginBottom: 32 }}>
            <Typography variant="h3" className="page-title">
              {t('Inventory Sheets')}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className="darin-table-panel">
            <TableContainer className="darin-table-container">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('title-text')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventorySheets.map((item, idx) => {
                    return <TableRow key={idx}>
                      <TableCell>
                        <Link
                          onClick={() => { history.push(`/inventory/inventory-sheets/${item.show_month}/${item.title}`) }}
                          style={{ cursor: 'pointer' }}
                        >
                          {`${item.show_month} - ${item.title}`}
                        </Link>
                      </TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
      {loading && <FullLoadingSpinner />}
    </Box>
  )
}

export default InventorySheet;
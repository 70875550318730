import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms';
import clsx from 'clsx';
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  IconButton,
  Checkbox
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ConfirmModal from '../../components/ConfirmModal';
import FullLoadingSpinner from '../../components/FullLoadingSpinner';
import { getAllVerificationFormsAPI, deleteVerificationFormAPI } from '../../apis';
import { routes, USER_ROLE } from '../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .page-title-wrapper': {
      marginBottom: 0,
    }
  },
}));

const Verification = () => {

  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const auth = useRecoilValue(authState);

  const [loading, setLoading] = useState(true);
  const [verificationList, setVerificationList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedVerifications, setSelectedVerifications] = useState([])

  let componentMounted = true;

  useEffect(() => {
    if (auth && auth.id) {
      getAllVerificationFormsAPI().then(res => {
        if (componentMounted) {
          // const sortedArr = res.data.sort((a, b) => {
          //   if (a.admin_verification_date === null) return 1;
          //   if (b.admin_verification_date === null) return -1;
          //   const aDateValue = moment(a.admin_verification_date).valueOf();
          //   const bDateValue = moment(b.admin_verification_date).valueOf();

          //   if (aDateValue > bDateValue) {
          //     return -1;
          //   }
          //   if (aDateValue < bDateValue) {
          //     return 1;
          //   }
          //   return 0;
          // });
          setVerificationList([...res.data]);
          setLoading(false);
        }
      }).catch(err => {
        if (componentMounted) {
          setVerificationList([]);
          setLoading(false);
        }
        console.log("Get show all verification in forms api error:")
        console.log(err);
      })
    }
    return () => {
      componentMounted = false;
    }
  }, [auth]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteVerification = () => {
    setLoading(true);
    setShowDeleteModal(false);
    const selectedIDs = selectedVerifications.map(item => item.verificate_form_id);
    deleteVerificationFormAPI(selectedIDs).then(res => {
      setVerificationList([
        ...verificationList.filter(item => selectedIDs.indexOf(item.verificate_form_id) < 0)
      ])
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    })
  }

  const [orderBy, setOrderBy] = useState({
    name: '',
    value: 'asc'
  })

  const getSortedData = () => {
    if (!orderBy.name) return verificationList;

    if (orderBy.name === 'admin_verification_date' && orderBy.value) {
      return verificationList.sort((a, b) => {
        const orderValue = orderBy.value === 'desc' ? -1 : 1;
        if (!a.admin_verification_date) return -1 * orderValue;
        if (!b.admin_verification_date) return 1 * orderValue;

        const aDateValue = new Date(a.admin_verification_date).valueOf();
        const bDateValue = new Date(b.admin_verification_date).valueOf();

        if (bDateValue > aDateValue) return -1 * orderValue;
        else if (bDateValue < aDateValue) return 1 * orderValue;
        else return 0;
      })
    } else if (orderBy.name === 'verificate_form_id' && orderBy.value) {
      return verificationList.sort((a, b) => {
        const orderValue = orderBy.value === 'desc' ? -1 : 1;
        if (b.verificate_form_id > a.verificate_form_id) return -1 * orderValue;
        else if (b.verificate_form_id < a.verificate_form_id) return 1 * orderValue;
        else return 0;
      })
    }
    else return verificationList;
  }

  const onSelectAllClick = (event) => {
    if (event.target.checked)
      setSelectedVerifications([...verificationList]);
    else setSelectedVerifications([])
  }

  const getFixedTableWidth = () => {
    // if (i18n.language === 'zh')
    //   return `calc(33.33% - 102px)`;
    // else return `calc(33.33% - 176px)`;
    return 200
  }

  return (
    <Box className={clsx(classes.root, "main-page-content")}>
      <Box className="page-title-wrapper">
        <Typography variant="h3" className="page-title">{t('Verification Sheets')}</Typography>
        {(auth?.role === USER_ROLE.ADMIN) && <>
          <IconButton
            style={{ marginLeft: 'auto' }}
            onClick={() => { history.push(routes.VERIFICATION_CREATE) }}>
            <AddIcon color="primary" />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <DeleteForeverOutlinedIcon color="error" />
          </IconButton>
        </>
        }
      </Box>

      <Box className="darin-table-panel">
        <Box className="table-action-wrapper">
          <TablePagination
            className="table-pagination-wrapper"
            rowsPerPageOptions={[5, 10, 25]}
            count={verificationList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            component="div"
          />
        </Box>
        <TableContainer className="darin-table-container">
          <Table>
            <TableHead>
              <TableRow>
                {(auth?.role === USER_ROLE.ADMIN) && <TableCell style={{ paddingLeft: 0, width: 40, textAlign: 'left' }}>
                  <Checkbox
                    className={classes.tableCheck}
                    color="primary"
                    checked={selectedVerifications.length === verificationList.length}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </TableCell>}
                <TableCell
                  style={{ width: i18n.language === 'zh' ? 132 : 198, textAlign: 'center' }}
                  sortDirection={orderBy.name === 'verificate_form_id' ? orderBy.value : false}
                >
                  <TableSortLabel
                    active={orderBy.name === 'verificate_form_id'}
                    direction={orderBy.name === 'verificate_form_id' ? orderBy.value : 'asc'}
                    onClick={() => {
                      if (orderBy.name === 'verificate_form_id') {
                        if (orderBy.value === 'desc')
                          setOrderBy({
                            name: 'verificate_form_id',
                            value: 'asc'
                          })
                        else
                          setOrderBy({
                            name: 'verificate_form_id',
                            value: 'desc'
                          })
                      } else {
                        setOrderBy({
                          name: 'verificate_form_id',
                          value: 'asc'
                        })
                      }
                    }}
                  >
                    {t('Verification FormID')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ textAlign: 'center', width: getFixedTableWidth() }}>{t('Contract Name')}</TableCell>
                <TableCell style={{ textAlign: 'center', width: getFixedTableWidth() }}>{t('Contract ID')}</TableCell>
                <TableCell style={{ textAlign: 'center', width: getFixedTableWidth() }}>{t('Supplier Name')}</TableCell>
                <TableCell
                  sortDirection={orderBy.name === 'admin_verification_date' ? orderBy.value : false}
                  style={{ width: i18n.language === 'zh' ? '140px' : '180px', textAlign: 'right' }}
                >
                  <TableSortLabel
                    active={orderBy.name === 'admin_verification_date'}
                    direction={orderBy.name === 'admin_verification_date' ? orderBy.value : 'asc'}
                    onClick={() => {
                      if (orderBy.name === 'admin_verification_date') {
                        if (orderBy.value === 'desc')
                          setOrderBy({
                            name: 'admin_verification_date',
                            value: 'asc'
                          })
                        else
                          setOrderBy({
                            name: 'admin_verification_date',
                            value: 'desc'
                          })
                      } else {
                        setOrderBy({
                          name: 'admin_verification_date',
                          value: 'asc'
                        })
                      }
                    }}
                  >
                    {t('Verification Date')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getSortedData().map((item, idx) => {
                if (idx >= (page + 1) * rowsPerPage || idx < page * rowsPerPage)
                  return null;
                return <TableRow key={idx} hover style={{ cursor: 'pointer' }}>
                  {(auth?.role === USER_ROLE.ADMIN || auth?.role === USER_ROLE.EMPLOYEE) &&
                    <TableCell style={{ paddingLeft: 0, width: 40, textAlign: 'center' }}>
                      <Checkbox
                        className={classes.tableCheck}
                        checked={selectedVerifications.map(item => item.verificate_form_id).indexOf(item.verificate_form_id) >= 0}
                        onChange={(event) => {
                          if (event.target.checked)
                            setSelectedVerifications([...selectedVerifications, item]);
                          else
                            setSelectedVerifications([...selectedVerifications.filter(itemSelected => itemSelected.verificate_form_id !== item.verificate_form_id)])
                        }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color="primary"
                      />
                    </TableCell>}
                  <TableCell
                    style={{ textAlign: 'center', width: i18n.language === 'zh' ? 132 : 198, paddingRight: 40 }}
                    onClick={() => { history.push(`/verification/detail/${item.verificate_form_id}`); }}
                  >
                    {item.verificate_form_id}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: getFixedTableWidth() }}
                    onClick={() => { history.push(`/verification/detail/${item.verificate_form_id}`); }}
                  >
                    {item.contract_name}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: getFixedTableWidth() }}
                    onClick={() => { history.push(`/verification/detail/${item.verificate_form_id}`) }}
                  >
                    {item.contract_id}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: getFixedTableWidth() }}
                    onClick={() => { history.push(`/verification/detail/${item.verificate_form_id}`) }}
                  >
                    {item.supplier_name}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'right', paddingRight: '40px', width: i18n.language === 'zh' ? '140px' : '180px' }}
                    onClick={() => { history.push(`/verification/detail/${item.verificate_form_id}`) }}
                  >
                    {item.admin_verification_date}
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {
        showDeleteModal && <ConfirmModal isShow={showDeleteModal} hideModal={() => {
          setShowDeleteModal(false)
        }}
          onOk={deleteVerification}
          title={t('Please confirm')}
          description={t('Are you going to delete it?')}
        />
      }
      {loading && <FullLoadingSpinner />}
    </Box >)
}

export default Verification;
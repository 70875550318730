import axiosInstance from '../lib/axios';

export const showAllClaiimSheetAPI = () => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.get('/show_all_claim_sheet').then(res => {
        resolve(res)
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const showClaimSheetAPI = (month) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.get(`/show_claim_sheet?month=${month}`).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const updateClaimSheetAPI = (updateData) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post(`/update_claim_sheet`, { ...updateData }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    } catch (err) {
      reject(err);
    }
  })
}

export const submitClaimSheetAPI = (claimsheetId) => {
  return new Promise((resolve, reject) => {
    try {
      axiosInstance.post(`/submit_table?table_name=claim_sheet&form_id=${claimsheetId}`).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    } catch (err) {
      reject(err);
    }
  })
}
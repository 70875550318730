import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useRecoilValue } from 'recoil';
import { authState } from '../../../atoms';
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination
} from '@material-ui/core';
import FullLoadingSpinner from '../../../components/FullLoadingSpinner';
import { showAppsAPI } from '../../../apis';
import { APPLICATION_STATUS_VALUE, USER_ROLE } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .page-title-wrapper': {
      marginBottom: 0,
    }
  }
}))

const Applications = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const auth = useRecoilValue(authState);

  const [appList, setAppList] = useState([]);
  const [orderBy, setOrderBy] = useState({
    name: 'CreatedOn',
    value: 'asc'
  })
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(true);

  let componentMounted = true;

  useEffect(() => {
    if (auth) {
      showAppsAPI({ userRole: auth.role, user_id: auth.role === USER_ROLE.EMPLOYEE ? auth.id : -1 }).then(res => {
        if (componentMounted) {
          setAppList([...res]);
          setLoading(false);
        }
      }).catch(err => {
        if (componentMounted) {
          setAppList([]);
          setLoading(false);
        }
      })
    }
    return () => {
      componentMounted = false;
    }
  }, [auth])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getSortedData = () => {
    if (!orderBy.name) return appList;
    if (orderBy.name === 'form_id' && orderBy.value) {
      return appList.sort((a, b) => {
        const orderValue = orderBy.value === 'desc' ? -1 : 1;

        if (b.form_id > a.form_id) return -1 * orderValue;
        else if (b.form_id < a.form_id) return 1 * orderValue;
        else return 0;
      })
    } else if (orderBy.name === 'created_on' && orderBy.value) {
      return appList.sort((a, b) => {
        const orderValue = orderBy.value === 'desc' ? -1 : 1;
        if (!a.created_on) return -1 * orderValue;
        if (!b.created_on) return 1 * orderValue;

        const aDateValue = new Date(a.created_on).valueOf();
        const bDateValue = new Date(b.created_on).valueOf();

        if (bDateValue > aDateValue) return -1 * orderValue;
        else if (bDateValue < aDateValue) return 1 * orderValue;
        else return 0;
      })
    } else if (orderBy.name === 'state' && orderBy.value) {
      return appList.sort((a, b) => {
        const orderValue = orderBy.value === 'desc' ? -1 : 1;

        if (APPLICATION_STATUS_VALUE[b.state] > APPLICATION_STATUS_VALUE[a.state]) return -1 * orderValue;
        else if (APPLICATION_STATUS_VALUE[b.state] < APPLICATION_STATUS_VALUE[a.state]) return 1 * orderValue;
        else return 0;
      })
    }
    else return appList;
  }

  return (
    <Box className={clsx(classes.root, 'main-page-content')}>
      <Box className="page-title-wrapper">
        <Typography variant="h3" className="page-title">
          {t('Applications')}
        </Typography>
      </Box>
      <Box className="darin-table-panel">
        <Box className="table-action-wrapper">
          <TablePagination
            className="table-pagination-wrapper"
            rowsPerPageOptions={[5, 10, 25]}
            count={appList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            component="div"
          />
        </Box>
        <Box className="darin-table-panel">
          <TableContainer className="darin-table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sortDirection={orderBy.name === 'form_id' ? orderBy.value : false}
                    style={{ textAlign: 'left' }}
                  >
                    <TableSortLabel
                      active={orderBy.name === 'form_id'}
                      direction={orderBy.name === 'form_id' ? orderBy.value : 'asc'}
                      onClick={() => {
                        if (orderBy.name === 'form_id') {
                          if (orderBy.value === 'desc')
                            setOrderBy({
                              name: 'form_id',
                              value: 'asc'
                            })
                          else
                            setOrderBy({
                              name: 'form_id',
                              value: 'desc'
                            })
                        } else {
                          setOrderBy({
                            name: 'form_id',
                            value: 'asc'
                          })
                        }
                      }}
                    >
                      {t('Application FormId')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>{t('User Name')}</TableCell>
                  <TableCell>{t('Item Names')}</TableCell>
                  <TableCell
                    sortDirection={orderBy.name === 'created_on' ? orderBy.value : false}
                  >
                    <TableSortLabel
                      active={orderBy.name === 'created_on'}
                      direction={orderBy.name === 'created_on' ? orderBy.value : 'asc'}
                      onClick={() => {
                        if (orderBy.name === 'created_on') {
                          if (orderBy.value === 'desc')
                            setOrderBy({
                              name: 'created_on',
                              value: 'asc'
                            })
                          else
                            setOrderBy({
                              name: 'created_on',
                              value: 'desc'
                            })
                        } else {
                          setOrderBy({
                            name: 'created_on',
                            value: 'asc'
                          })
                        }
                      }}
                    >
                      {t('CreatedOn')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={orderBy.name === 'state' ? orderBy.value : false}
                    style={{ textAlign: 'right' }}
                  >
                    <TableSortLabel
                      active={orderBy.name === 'state'}
                      direction={orderBy.name === 'state' ? orderBy.value : 'asc'}
                      onClick={() => {
                        if (orderBy.name === 'state') {
                          if (orderBy.value === 'desc')
                            setOrderBy({
                              name: 'state',
                              value: 'asc'
                            })
                          else
                            setOrderBy({
                              name: 'state',
                              value: 'desc'
                            })
                        } else {
                          setOrderBy({
                            name: 'state',
                            value: 'asc'
                          })
                        }
                      }}
                    >
                      {t('Status')}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getSortedData().map((item, idx) => {
                  if (idx >= (page + 1) * rowsPerPage || idx < page * rowsPerPage)
                    return null;
                  return <TableRow
                    key={idx}
                    hover
                    style={{ cursor: 'pointer' }}
                    onClick={() => { history.push(`/application/${item.form_id}`); }}
                  >
                    <TableCell style={{ textAlign: 'left' }}>{item.form_id}</TableCell>
                    <TableCell>{`${item.last_name} ${item.first_name}`}</TableCell>
                    <TableCell>{
                      typeof (item.item_name) === 'object' ? item.item_name.join(", ") : item.item_name
                    }</TableCell>
                    <TableCell>
                      {item.created_on}
                    </TableCell>
                    <TableCell style={{ textAlign: 'right', paddingRight: 44 }}>
                      {item.state}
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      {loading && <FullLoadingSpinner />}
    </Box>
  );
};

export default Applications;

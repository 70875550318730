import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import Swal from 'sweetalert2'
import { makeStyles, Box, Typography, Grid, TextField, Button } from '@material-ui/core';
import FullLoadingSpinner from '../../components/FullLoadingSpinner';
import { authState } from '../../atoms';
import { getUserInfoAPI, updateAccountAPI, updateUserPasswordAPI } from '../../apis'

const useStyles = makeStyles((theme) => ({
  root: {
  },
}))

const MyAccount = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [auth, setAuth] = useRecoilState(authState);
  const [loading, setLoading] = useState(false);

  const [userFormData, setUserFormData] = useState({
    id: -1,
    first_name: '',
    last_name: '',
    user_name: '',
    phone: '',
    email: ''
  })

  const [passwordFormData, setPasswordFormData] = useState({
    old: '',
    new: ''
  })
  const [passwordSubmitted, setPasswordSubmitted] = useState(false)

  let componentMounted = true;

  useEffect(() => {
    if (auth) {
      getUserInfoAPI(auth.id).then(res => {
        if (componentMounted) {
          setUserFormData({
            ...res.data.data,
            id: auth.id
          })
          setLoading(false);
        }
      }).catch(err => {
        console.log(err);
        if (componentMounted) {
          setLoading(false);
        }
      })
    }
    return () => { componentMounted = false; }
  }, [auth])

  const updateAccount = () => {
    setLoading(true);
    updateAccountAPI({
      account: [
        {
          id: auth.id,
          first_name: userFormData.first_name,
          last_name: userFormData.last_name,
          user_name: userFormData.user_name,
          phone: userFormData.phone,
          email: userFormData.email,
        }
      ]
    }).then(res => {
      setLoading(false)
      setAuth({
        ...auth,
        first_name: userFormData.first_name,
        last_name: userFormData.last_name,
        user_name: userFormData.user_name,
        phone: userFormData.phone,
        email: userFormData.email,
      })
      Swal.fire({
        title: t('Success'),
        text: t('Your account has been updated'),
        icon: 'success',
        confirmButtonText: t('Ok')
      })
    }).catch(err => {
      console.log(err);
      setLoading(false)
      Swal.fire({
        title: `${t('Error')}!`,
        text: t('Account update has been failed'),
        icon: 'error',
        confirmButtonText: t('Ok')
      })
    })
  }

  const updatePassword = () => {
    setPasswordSubmitted(true);
    if (passwordFormData.old.length === 0 || passwordFormData.new.length === 0)
      return;

    setLoading(true);
    updateUserPasswordAPI({
      account: [{
        id: auth.id,
        old_pw: passwordFormData.old,
        new_pw: passwordFormData.new
      }]
    }).then(res => {
      setLoading(false);
      if (res.data.status === 'success') {
        Swal.fire({
          title: t('Success'),
          text: t('Your password has been changed'),
          icon: 'success',
          confirmButtonText: t('Ok')
        })
      } else if (res.data.reason_displayed) {
        Swal.fire({
          title: `${t('Error')}!`,
          text: res.data.reason_displayed,
          icon: 'error',
          confirmButtonText: t('Ok')
        })
      }
    }).catch(err => {
      setLoading(false);
      console.log(err)
      Swal.fire({
        title: `${t('Error')}!`,
        text: t("Change password has been failed"),
        icon: 'error',
        confirmButtonText: t('Ok')
      })
    })
  }

  return (<Box className="main-page-content">
    <Box className="page-title-wrapper">
      <Typography variant="h3" className="page-title">{t('My Account Page')}</Typography>
    </Box>
    <Grid className="darin-panel-one has-header small-margin-bottom" container spacing={1}>
      <Box className="darin-panel-one-header">
        {t('Personal Info')}
      </Box>
      <Grid item xs={12} sm={6}>
        <Box className="darin-form-group">
          <Typography className="form-label" variant="subtitle1" style={{ width: 140 }}>{t('Last Name')}:</Typography>
          <TextField
            label=""
            value={userFormData.last_name}
            onChange={(e) => {
              setUserFormData({
                ...userFormData,
                last_name: e.target.value
              })
            }}
            size="small"
            variant="outlined"
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className="darin-form-group">
          <Typography className="form-label" variant="subtitle1" style={{ width: 140 }}>{t('First Name')}:</Typography>
          <TextField
            label=""
            value={userFormData.first_name}
            onChange={(e) => {
              setUserFormData({
                ...userFormData,
                first_name: e.target.value
              })
            }}
            size="small"
            variant="outlined"
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className="darin-form-group">
          <Typography className="form-label" variant="subtitle1" style={{ width: 140 }}>{t('User Name')}:</Typography>
          <TextField
            label=""
            value={userFormData.user_name}
            onChange={(e) => {
              setUserFormData({
                ...userFormData,
                user_name: e.target.value
              })
            }}
            size="small"
            variant="outlined"
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className="darin-form-group">
          <Typography className="form-label" variant="subtitle1" style={{ width: 140 }}>{t('Email')}:</Typography>
          <TextField
            label=""
            type="email"
            value={userFormData.email}
            onChange={(e) => {
              setUserFormData({
                ...userFormData,
                email: e.target.value
              })
            }}
            size="small"
            variant="outlined"
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className="darin-form-group">
          <Typography className="form-label" variant="subtitle1" style={{ width: 140 }}>{t('Phone')}:</Typography>
          <TextField
            label=""
            value={userFormData.phone}
            onChange={(e) => {
              setUserFormData({
                ...userFormData,
                phone: e.target.value
              })
            }}
            size="small"
            variant="outlined"
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
        <Button className="chat-btn-primary" variant="outlined" onClick={updateAccount}>{t('Save')}</Button>
      </Grid>
    </Grid>

    <Grid className="darin-panel-one has-header small-margin-bottom" container spacing={1}>
      <Box className="darin-panel-one-header">
        {t('Authentication')}
      </Box>
      <Grid item xs={12} sm={6}>
        <Box className="darin-form-group">
          <Typography className="form-label" variant="subtitle1" style={{ width: 140 }}>{t('Old password')}:</Typography>
          <TextField
            label=""
            type="password"
            value={passwordFormData.old}
            onChange={(e) => {
              setPasswordFormData({
                ...passwordFormData,
                old: e.target.value
              })
            }}
            size="small"
            variant="outlined"
            error={passwordSubmitted && passwordFormData.old.length === 0}
            helperText={passwordSubmitted && passwordFormData.old.length === 0 ? t('Old password required') : ''}
            inputProps={{
              autocomplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className="darin-form-group">
          <Typography
            className="form-label"
            variant="subtitle1"
            style={{ width: 140 }}
          >
            {t('New password')}:
          </Typography>
          <TextField
            label=""
            type="password"
            value={passwordFormData.new}
            onChange={(e) => {
              setPasswordFormData({
                ...passwordFormData,
                new: e.target.value
              })
            }}
            size="small"
            variant="outlined"
            error={passwordSubmitted && passwordFormData.new.length === 0}
            helperText={passwordSubmitted && passwordFormData.new.length === 0 ? t('New password required') : ''}
          />
        </Box>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button className="chat-btn-primary" variant="outlined" onClick={updatePassword}>{t('Change Password')}</Button>
      </Grid>
    </Grid>
    {loading && <FullLoadingSpinner />}
  </Box >)
}

export default MyAccount;
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  withStyles,
  Grid,
  TextField,
  Button,
  Dialog,
  Typography,
  IconButton,
  Divider,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  form: {
    marginTop: theme.spacing(2),
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  formFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
    '& .MuiButtonBase-root': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {

  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const RecordModal = ({ isShow, recordInfo, addRecord, hideModal }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const [record, setRecord] = useState({
    row_num: -1,
    item_name: '',
    item_model: '',
    price: '',
    quantity: '',
    total_price: '',
    item_id: '',
    notes: ''
  })
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (recordInfo && recordInfo.row_num !== -1)
      setRecord({ ...recordInfo })
  }, [recordInfo])

  const onSubmit = async (event) => {
    event.preventDefault();
    addRecord(record)
  }

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isShow}
      onClose={hideModal}
    >
      <DialogTitle onClose={hideModal}>
        {!record || record.row_num === -1 ? t('Add Record') : t('Edit Record')}
      </DialogTitle>
      <DialogContent dividers>
        <form className={classes.form} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="name"
                name="Name"
                label={t("Name")}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={record.item_name}
                onChange={(event) => {
                  setRecord({
                    ...record,
                    item_name: event.target.value
                  })
                }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="model"
                name="model"
                label={t("Model")}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={record.item_model}
                onChange={(event) => {
                  setRecord({
                    ...record,
                    item_model: event.target.value
                  })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="price"
                name="price"
                label={t("Price")}
                type="number"
                inputProps={{ min: 0, step: 0.01 }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={record.price.toString()}
                onChange={(event) => {
                  let input = event.target.value;
                  if (!input || (input[input.length - 1].match('[0-9]') && input[0].match('[0-9]'))) {
                    setRecord({
                      ...record,
                      price: Number(input.replace(/^0+/, ''))
                    })
                  } else {
                    setRecord({
                      ...record,
                      price: Number(input.replace(/^0+/, ''))
                    })
                  }
                }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="quantity"
                name="quantity"
                label={t("Quantity")}
                type="number"
                inputProps={{ min: 0 }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={record.quantity}
                onChange={(event) => {
                  let input = event.target.value;
                  if (!input || (input[input.length - 1].match('[0-9]') && input[0].match('[0-9]')))
                    setRecord({
                      ...record,
                      quantity: Number(input)
                    })
                }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="total_price"
                name="total_price"
                label={t("Total Price")}
                type="number"
                inputProps={{ min: 0, step: 0.01 }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={record.total_price}
                onChange={(event) => {
                  let input = event.target.value;
                  if (!input || (input[input.length - 1].match('[0-9]') && input[0].match('[0-9]')))
                    setRecord({
                      ...record,
                      total_price: Number(input)
                    })
                }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="item_id"
                name="item_id"
                label={t("Item Id")}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={record.item_id}
                onChange={(event) => {
                  setRecord({
                    ...record,
                    item_id: event.target.value
                  })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="notes"
                name="notes"
                label={t("Notes")}
                type="textarea"
                multiline
                rowsMax={4}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={record.notes}
                onChange={(event) => {
                  setRecord({
                    ...record,
                    notes: event.target.value
                  })
                }}
              />
            </Grid>
            <Grid item xs={12}><Divider style={{ marginTop: 16 }} /></Grid>
            <Grid item xs={12} className={classes.formFooter}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                {record.row_num === -1 ? t('Create') : t('Update')}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  hideModal();
                }}
              >
                {t('Cancel')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default RecordModal;
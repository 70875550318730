import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Tooltip,
  IconButton,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import SaveIcon from '@material-ui/icons/Save';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import FullLoadingSpinner from '../../../components/FullLoadingSpinner';
import { showTotalInventoryAPI, updateCheckInventorySheetAPI } from '../../../apis';
import { getColumnValue } from '../../../utils'
import { routes } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .page-title-wrapper': {
      marginBottom: 0,
    }
  },
}))

const CheckInventoryEdit = () => {

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const history = useHistory();

  const [inventorySheets, setInventorySheets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  let componentMounted = true;

  useEffect(() => {
    setLoading(true);
    showTotalInventoryAPI().then(res => {
      if (componentMounted) {
        setLoading(false);
        setInventorySheets([...res.data.data]);
      }
    }).catch(err => {
      if (componentMounted) {
        setLoading(false);
        setInventorySheets([]);
      }
    })
  }, [])

  const goBack = () => {
    Swal.fire({
      title: t('Do you want to save the changes?'),
      showCancelButton: true,
      cancelButtonText: t('No'),
      showConfirmButton: true,
      confirmButtonText: t('Yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        updateCheckInventorySheetAPI({
          data:
            [
              ...inventorySheets.map(item => {
                return {
                  ...item,
                  row_num: item.row_num,
                  notes: item.notes,
                  storage_location: item.storage_location
                }
              })
            ]
        }
        ).then(res => {
          setLoading(false);
          history.push(routes.CHECKINVENTORY)
        }).catch(err => {
          setLoading(false);
          console.log(err)
        })
      } else {
        history.push(routes.CHECKINVENTORY)
      }
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box className={clsx(classes.root, 'main-page-content')}>
      <Grid container>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Breadcrumbs className="darin-breadcrumbs">
            <Link
              onClick={() => { history.push(routes.CHECKINVENTORY); }}
              color="inherit"
            >
              {t('Check Inventory')}
            </Link>
            <Link
              onClick={goBack}
              color="inherit"
            >
              {t('Edit')}
            </Link>
          </Breadcrumbs>
          <Tooltip title={t('Save')}>
            <IconButton
              onClick={() => {
                setLoading(true)
                updateCheckInventorySheetAPI({
                  data:
                    [
                      ...inventorySheets.map(item => {
                        return {
                          ...item,
                          row_num: item.row_num,
                          notes: item.notes,
                          storage_location: item.storage_location
                        }
                      })
                    ]
                }

                ).then(res => {
                  setLoading(false);
                  history.push(routes.CHECKINVENTORY)
                }).catch(err => {
                  setLoading(false);
                  console.log(err)
                })
              }}
            >
              <SaveIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('submit')}>
            <IconButton disabled><SendOutlinedIcon /></IconButton>
          </Tooltip>
          <Tooltip title={t('download')}>
            <IconButton disabled><GetAppOutlinedIcon /></IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} style={{ marginBottom: 32 }}>
            <Typography variant="h3">{t('Check Inventory')}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box className="darin-table-panel">
            <Box className="table-action-wrapper">
              <TablePagination
                className="table-pagination-wrapper"
                rowsPerPageOptions={[5, 10, 25]}
                count={inventorySheets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={t('Rows per page')}
                component="div"
              />
            </Box>
            <Box className="darin-table-container-wrapper">
              <TableContainer className="darin-table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell rowSpan={2} style={{ textAlign: 'left', minWidth: '70px', width: '70px' }} >{t('num')}</TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '92px', width: '92px' }} >{t('Item Name')}</TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '70px', width: '70px' }} >{t('Item Model')}</TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '92px', width: '92px' }} >{t('item_id')}</TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '70px', width: '70px' }} >{t('unit')}</TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '70px', width: '70px' }} >{t('quantity')}</TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '103px', width: '103px' }} >{t('net unit price')}</TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '92px', width: '92px' }} >{t('current total price')}</TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '114px', width: '114px' }} >{t('inventory date')}</TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '70px', width: '70px' }} >{t('quantity available')}</TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '300px', width: '300px' }}>{t('storage location')}</TableCell>
                      <TableCell rowSpan={2} style={{ minWidth: '200px', width: '200px' }} >{t('notes')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inventorySheets.map((item, idx) => {
                      if (idx >= (page + 1) * rowsPerPage || idx < page * rowsPerPage)
                        return null;
                      return <TableRow key={idx}>
                        <TableCell>{getColumnValue(item, 'row_num')}</TableCell>
                        <TableCell>{getColumnValue(item, 'item_name')}</TableCell>
                        <TableCell>{getColumnValue(item, 'item_model')}</TableCell>
                        <TableCell>{getColumnValue(item, 'item_id')}</TableCell>
                        <TableCell>{getColumnValue(item, 'unit')}</TableCell>
                        <TableCell>{getColumnValue(item, 'quantity')}</TableCell>
                        <TableCell>{getColumnValue(item, 'net_unit_price')}</TableCell>
                        <TableCell>{getColumnValue(item, 'current_total_price')}</TableCell>
                        <TableCell>{getColumnValue(item, 'inventory_time')}</TableCell>
                        <TableCell>{getColumnValue(item, 'quantity_available')}</TableCell>
                        <TableCell>
                          <TextField
                            value={getColumnValue(item, 'storage_location')}
                            onChange={event => {
                              setInventorySheets([
                                ...inventorySheets.map((itemOne, idxOne) => {
                                  if (idx === idxOne)
                                    return {
                                      ...itemOne,
                                      storage_location: event.target.value
                                    }
                                  else return itemOne
                                })
                              ])
                            }}
                            type="text"
                            size="small"
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            size="small"
                            value={item.notes}
                            onChange={(e) => {
                              setInventorySheets([
                                ...inventorySheets.map((itemOne, idxOne) => {
                                  if (idx === idxOne)
                                    return { ...itemOne, notes: e.target.vaue }
                                  else return itemOne;
                                })
                              ])
                            }}
                            variant="outlined"
                          />
                        </TableCell>
                      </TableRow>
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {loading && <FullLoadingSpinner />}
    </Box >
  )
}

export default CheckInventoryEdit;